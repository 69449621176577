import { getAppSettings } from "../api/Settings";
import { getCacheValue, setCacheValue } from "../cache/CacheHandler";

const SETTINGS_CACHE_KEY = 'settings_cache_key';

export async function getSettings() {
    const cachedData = getCacheValue(SETTINGS_CACHE_KEY);
    if (cachedData) return cachedData;

    const settings = await getAppSettings();
    setCacheValue(SETTINGS_CACHE_KEY, settings, 60);

    return settings;
}

export async function getDefaultsUrlsForAllCategory() {
    const settings = await getSettings();
    const defaultUrls = {};
    for (const product in settings.products) {
      if (settings.products[product].default_url) {
        defaultUrls[product] = settings.products[product].default_url;
      }
    }

    return defaultUrls;
}

export async function getDefaultsUrlsForCategory(category) {
  return (await getDefaultsUrlsForAllCategory())[category];
}

export async function getTitlesForAllCategory() {
  const settings = await getSettings();
  const titles = {};
  for (const product in settings.products) {
    if (settings.products[product].title) {
      titles[product] = settings.products[product].title;
    }
  }

  return titles;
}

export async function getAllCatogeries() {
    const settings = await getSettings();
    return Object.keys(settings.products);
}

export async function getAllCatogeriesOfRequests() {
  const settings = await getSettings();
  return Object.keys(settings.requests);
}

export async function getAllCatogeryProperites() {
    const settings = await getSettings();
    return settings.products;
}

export async function getAllCatogeryProperitesOfRequests() {
  const settings = await getSettings();
  return settings.requests;
}

export async function getAllPackagePlans() {
  const settings = await getSettings();
  return settings.plans;
}

export async function getPackagePlanType(planType) {
  const settings = await getSettings(); 
  return settings.plans[planType];
}