export function xhrhandleResponse(xhr) {
    const responseData = xhr.response;
    const contentType = xhr.getResponseHeader("content-type");
    
    if (contentType === "application/json") {
        return JSON.parse(responseData);
    }

    return {
        "error" : {
            'description' : 'Something went wrong! It has been reported to our admins.',
        }
    }
}