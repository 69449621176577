// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awesome-slider {
  width: 344px;
  height: 345px;
  z-index: 0;
  border-radius: 24px;
  margin-bottom: 66px;
  background-color: white;
}

.slider-image {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 344px;
  max-height: 345px;
  /* object-fit: cover; */
  border-radius: 24px;
}
.image-containers {
  width: 344px;
  height: 345px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.awssld__wrapper {
  border-radius: 24px;
}

.awesome-slider.awssld__content.awssld--active {
  transition: background-color 0.3s ease;
  border-radius: 24px;
}

@media (max-width: 768px) {
  .awesome-slider {
    width: 200px;
    height: 200.581px;
    flex-shrink: 0;
  }
  .slider-image {
    max-width: 200px;
    object-fit: cover;
  }
  .image-containers {
    width: 200px;
    height: 200.581px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styling/SliderImage.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,cAAc;EAChB;EACA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,iBAAiB;EACnB;AACF","sourcesContent":[".awesome-slider {\n  width: 344px;\n  height: 345px;\n  z-index: 0;\n  border-radius: 24px;\n  margin-bottom: 66px;\n  background-color: white;\n}\n\n.slider-image {\n  width: fit-content;\n  height: fit-content;\n  max-width: 344px;\n  max-height: 345px;\n  /* object-fit: cover; */\n  border-radius: 24px;\n}\n.image-containers {\n  width: 344px;\n  height: 345px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n}\n\n.awssld__wrapper {\n  border-radius: 24px;\n}\n\n.awesome-slider.awssld__content.awssld--active {\n  transition: background-color 0.3s ease;\n  border-radius: 24px;\n}\n\n@media (max-width: 768px) {\n  .awesome-slider {\n    width: 200px;\n    height: 200.581px;\n    flex-shrink: 0;\n  }\n  .slider-image {\n    max-width: 200px;\n    object-fit: cover;\n  }\n  .image-containers {\n    width: 200px;\n    height: 200.581px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
