import Modal from "react-modal";
import { useState, useEffect } from "react";
import "./item/ProductDetailedView";
import SliderImage from "./SliderImage";
import { useParams } from "react-router-dom";
import { BackPress } from "./BackPress";
import { getbuyerRequestItem } from "../api/sellerApis";
import { isNumber } from "../utils/numberHelper";
import { SellerProfile } from "../components/buyer/SellerProfile";
import LoadingSpinner from "../components/LoadingSpinner";
import Header from "./Header";
import Footer from "./Footer";

export default function CustomProductDetails({ backHandler = null, headingText = "" }) {
  const { id } = useParams();
  const [item, setItem] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBuyerMerchant, setCurrentBuyerMerchant] = useState({});

  const goBack = () => {
    if (backHandler != null) return backHandler();
    window.history.back();
  };

  useEffect(() => {
    async function fetchAndStoreCategories() {
      const items = await getbuyerRequestItem(id);
      setItem(items);
      setIsLoading(false);
    }

    fetchAndStoreCategories();
  }, []);

  function handleContactBuyer() {
    setCurrentBuyerMerchant(item.merchant);
    setIsModalOpen(true);
  }

  function handleBuyerProfileClose() {
    setIsModalOpen(false);
  }

  return (
    <div className="product-page">
      <Header />
      {isLoading ? (
        <div style={{ width: "100%" }}>
          <div style={{ paddingTop: "10%", paddingBottom: "10%" }}>
            {" "}
            <LoadingSpinner />{" "}
          </div>
        </div>
      ) : (
        <div className="product-detail-container">
          <div className="product-detail-container-header">
            <div className="product-detail-back">
              <BackPress onClick={goBack} />
            </div>
            <div className="product-detail-container-header-title">
              {headingText !== "" ? headingText : item.catogery_type}
            </div>
          </div>

          <div className="product-detail-container-body">
            <div>
              <div className="product-detail-container-main">
                <div className="product-detail-container-main-left">
                  <div className="product-images-container">
                    {<SliderImage data={item} />}
                  </div>
                </div>
                <div className="product-features-container">
                  <div className="product-feature-header">
                    <div className="product-feature-header-title">
                      {item.catogery_type}
                    </div>
                  </div>
                  <div
                    className={
                      !Array.isArray(item.parameters) ||
                        item.parameters.length === 0
                        ? item.quantity == null && item.price == null
                          ? "product-feature-description product-feature-description-extra-long"
                          : "product-feature-description product-feature-description-long"
                        : "product-feature-description"
                    }
                  >
                    {item.product_description}
                  </div>
                  {Array.isArray(item.parameters) &&
                    item.parameters.length > 0 ? (
                    <div
                      className={
                        item.quantity == null && item.price == null
                          ? "product-parameters product-parameters"
                          : "product-parameters"
                      }
                    >
                      {item.parameters.map((parameter, index) => (
                        <div className="product-feature-parameter">
                          {parameter.name}: {parameter.value} {parameter.um}
                        </div>
                      ))}
                    </div>
                  ) : null}

                  <div className="product-feature-trade">
                    {isNumber(item.quantity) ? (
                      <div className="product-feature-quantity-container">
                        <div className="product-feature-quantity">{item.quantity != null ? item.quantity + ' ' + item.quantity_um : ''}
                        </div>
                      </div>
                    ) : null}
                    {isNumber(item.price) ? (
                      <div className="product-feature-price-container">
                        <div className="product-feature-price">
                          {item.price != null ? "Rs " + item.price : ""}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <h5 className="requirement-text">
                    Seller is notified about your requirement
                  </h5>
                </div>
              </div>
              <div className="contact-button-container">
                <button className="contact-button" onClick={handleContactBuyer}>
                  <span>Contact</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        contentLabel="Popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}
      >
        <SellerProfile
          handleBackPress={handleBuyerProfileClose}
          merchant={currentBuyerMerchant}
        />
      </Modal>
      <Footer />
    </div>
  );
}
