// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root {
    overflow-y: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.logged-in-container {
    overflow-Y: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.children-content {
    flex-grow: 1;
}

.content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/styling/LoggedInDefaultLayout.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".root {\n    overflow-y: auto;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.logged-in-container {\n    overflow-Y: auto;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.children-content {\n    flex-grow: 1;\n}\n\n.content-wrapper {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
