import { handleResponse } from "../api/responseHandler";
import { BASE_URL } from "../config";
import { getSessionId } from "../session/SessionHandler";
import { handleResponseError } from "./handleError";

export async function getImageUrl(image, purpose) {
  const formData = new FormData();
  formData.append('image_file', image);
  formData.append('purpose', purpose);

  const response = await fetch(BASE_URL+'api/v1/merchant/images', {
    method: 'POST',
    headers: {
      "X-USER-SESSION-ID": getSessionId(),
    },
    body: formData,
  });

  const data = await handleResponse(response);
  if (data.error) {
    handleResponseError(data.error);
    return null; 
  }

  return data.image_url;
}
  
