// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardsContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center; 
    width: 100%;
    flex-wrap: wrap;
}

.cards{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/styling/ItemsCard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".cardsContainer {\n    margin-top: 20px;\n    display: flex;\n    justify-content: center; \n    width: 100%;\n    flex-wrap: wrap;\n}\n\n.cards{\n    margin-top: 20px;\n    display: flex;\n    flex-wrap: wrap;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
