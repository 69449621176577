import { reloadSession } from "../session/SessionHandler";
import { displayToast } from "./toastHelper";

export function handleError(message) {
  alert(message)
}

export function handleResponseError(error) {
  displayToast(error.description);

  if (error.code == "BAD_REQUEST_UNAUTHORIZED" && 
    error.description == 'Your login session expired, please re-login') {
      reloadSession();
  }
}