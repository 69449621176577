// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-view-container {
    width: 96px;
    height: 96px;
    background-color: #D9D9D9;
    position: relative;
    margin-right: 6px;
}

.image-remove-container {
    width: 100%;
    display: flex;
    float: right;
    justify-content: flex-end;
    position: absolute;
}

.image-view-image {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 96px;
    max-width: 96px;
}

.image-file-container {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-icon {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/image/ImageView.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".image-view-container {\n    width: 96px;\n    height: 96px;\n    background-color: #D9D9D9;\n    position: relative;\n    margin-right: 6px;\n}\n\n.image-remove-container {\n    width: 100%;\n    display: flex;\n    float: right;\n    justify-content: flex-end;\n    position: absolute;\n}\n\n.image-view-image {\n    width: fit-content;\n    height: fit-content;\n    max-height: 96px;\n    max-width: 96px;\n}\n\n.image-file-container {\n    width: 96px;\n    height: 96px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.remove-icon {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
