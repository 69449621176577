import React, { useState, useEffect } from "react";
import { BackPress } from "../components/BackPress";
import FillBuyerRequestedItemPage from "./FillBuyerRequestedItemPage";
import { getAllCatogeriesOfRequests} from "../settings/SettingsHandler";
import '../styling/AddProduct.css';

const BuyerCategorySelectPage = ({ handleMakeRequestModalClose, newProduct }) => {
  const [category, setCategory] = useState([]);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    async function fetchAndStoreCategories() {
      const categories = await getAllCatogeriesOfRequests();
      setCategory(categories);
    }

    fetchAndStoreCategories();
  }, []);

  const handleFillProduct = (category) => {
    setSelectedCategory(category);
    setIsAddingProduct(true);
  };

  const handleNewProduct = (newData) => {
    newProduct(newData);
  }

  const goBack = (e) => {
    handleMakeRequestModalClose();
  };

  const hideFillProduct = () => {
    setIsAddingProduct(false);
  };

  const onClose = () => {
    setIsAddingProduct(false);
    handleMakeRequestModalClose();
  };

  return (
    <div>
      {isAddingProduct ? (
        <FillBuyerRequestedItemPage category={selectedCategory} data={{ catogery_type: selectedCategory }} handleBackPress={hideFillProduct} onClose={onClose} updateFormProductData={handleNewProduct} />
      ) : (
        <div className="modalone-background" onClick={goBack} >
          <div className="modal-divstyle" style={{ backgroundColor: 'white', width: '518px', height: '883px' }} onClick={(e) => e.stopPropagation()}>
            <div className="maincontainer-style" style={{ marginBottom: '60px' }}>
              <div className="add-product-header">
                <div className="add-product-back-press">
                  <BackPress onClick={goBack} />
                </ div>
                <div className="add-product-header-text-container">
                  <div className="maintitle-style">Category</div>
                </div>
              </div>
              <div className="textcontainer-style">
                <p className="text-style">
                  Select the Category of Item you want to sell
                </p>
              </div>
            </div>
            <div className="category-list-container">
              {category.map((categoryItem, index) => (
                <button
                  onClick={() => handleFillProduct(categoryItem)}
                  key={index}
                  className="category-button"
                >
                  {categoryItem}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyerCategorySelectPage;