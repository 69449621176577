import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {
  getAllCatogeries,
  getDefaultsUrlsForAllCategory,
  getTitlesForAllCategory,
} from "../settings/SettingsHandler";
import { BuyerNavBar } from "./buyer/BuyerNavBar";
import { useSession } from "../contexts/SessionContext";
import scap from "../images/scap.png";
import Feedback from "../images/Feedback.svg";
import FeedbackPage from "../screens/FeedbackPage";
import "../styling/buyerPageItems.css";
import "../styling/buyerPage.css";

const BuerPageItems = () => {
  const [category, setCategory] = useState([]);
  const [categoryImg, setCategoryImg] = useState({});
  const [categoryTitles, setCategoryTitles] = useState({});
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);

  const navigate = useNavigate();

  const handleCardClick = (categoryItem) => {
    navigate("/categories/" + categoryItem);
  };

  async function getFilters() {
    setCategory(await getAllCatogeries());
    setCategoryImg(await getDefaultsUrlsForAllCategory());
    setCategoryTitles(await getTitlesForAllCategory());
  }

  useEffect(() => {
    getFilters();
  }, []);

  const handleRequestFormSelect = () => {
    setIsRequestFormOpen(!isRequestFormOpen);
    navigate("/buyer-request-form");
  };

  const handleFeedbackClick = () => {
    setIsFeedbackOpen(true);
  };

  function handleFeedbackPageClose() {
    setIsFeedbackOpen(false);
  }

  return (
    <div>
      <BuyerNavBar />
      <div>
        <div className="heroSection">
          <div className="herocontenttContaienr">
            <div className="heroContentt">
              <div>
                <h1 className="heroContentTxt">
                  One stop solution for excess Raw materials
                </h1>
              </div>
              <button
                onClick={handleRequestFormSelect}
                className="request-item-button"
              >
                Request item
              </button>
            </div>
          </div>
        </div>
        <div className="itemsHeader">
          <h1>Categories</h1>
          <h6>Find what you are looking for</h6>
        </div>
        <div className="home-page-feedback-container">
          <button
            className="home-page-feedback-button"
            onClick={handleFeedbackClick}
          >
            <img
              src={Feedback}
              alt="feedback"
              className="home-page-fedback-icon"
            />
            Feedback
          </button>
        </div>
        <div className="buyerCardsContainer">
          <div className="buyerCards">
            {category.map((categoryItem, index) => (
              <div
                className="buyerCard"
                onClick={() => handleCardClick(categoryItem)}
                key={index}
              >
                <div className="buyerCardImageContainer">
                  {categoryImg[categoryItem] ? (
                    <img
                      className="buyerCardImage"
                      src={categoryImg[categoryItem]}
                      alt={categoryItem}
                    />
                  ) : (
                    <img
                      className="buyerCardImage"
                      alt={categoryItem}
                      src={scap}
                    />
                  )}
                  <div className="buyer-card-title">
                    {categoryTitles[categoryItem]
                      ? categoryTitles[categoryItem]
                      : categoryItem}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isFeedbackOpen}
        contentLabel="Popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}
      >
        {" "}
        <FeedbackPage handleFeedbackPageClose={handleFeedbackPageClose} />
      </Modal>
    </div>
  );
};

export default BuerPageItems;
