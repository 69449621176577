import BuyerPage from "./buyerPage";
import SellerPage from "./sellerPage";
import { useSession } from "../contexts/SessionContext";
import { MODE_SELLER } from "../ConstantNew";


export default function Home({ }) {
  const { mode } = useSession();

  return (
    <div>
      {mode !== MODE_SELLER ? (
          <BuyerPage />
        ) : (
          <SellerPage />
        )
      }
    </div>
  );
}
