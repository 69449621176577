import React from "react";
import { useState } from "react";
import "../../styling/SellerBuyerSignUp.css";
import Back from "../../images/backpress.svg";
import Logo from "../../images/Logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL, SIGN_UP } from "../../config";
import { getSessionId, setSessionId } from "../../session/SessionHandler";
import { useSession } from "../../contexts/SessionContext";
import { logout } from "../../api/logout";
import LoadingWhite from "../../images/LoadingWhite.gif";

export default function SellerBuyerSignUp({}) {
  const navigate = useNavigate();
  const { screenSize } = useSession();
  const { updateSessionId } = useSession();

  const [about, setabout] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [contactnumber, setcontactnumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [contactError, setConractError] = useState("");

  const isButtonActive =
    companyLocation.trim() !== "" && contactnumber.trim() !== "";

  const closeModalAndNavigate = (path) => {
    navigate(path);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/\D/g, "").slice(0, 15);
    setcontactnumber(cleanedValue);
    setConractError("");
  };

  const validateContact = () => {
    const contactNumberRegex = /^(?:\+91)?(?:\d{10}|\d{1,4}-\d{1,4}-\d{1,7})$/;
    if (!contactNumberRegex.test(contactnumber)) {
      setConractError("Please enter valid contact number");
      return false; 
    }
    setConractError(""); 
    return true;
  };

  const handleSignUp = async () => {
    if (!validateContact()) {
      return false;
    }
    setIsLoading(true);
    const requestBody = {
      seller_profile_description: about,
      address: companyLocation,
      contact: contactnumber,
    };

    try {
      const response = await fetch(BASE_URL + SIGN_UP, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-USER-SESSION-ID": getSessionId(),
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        closeModalAndNavigate("/verification");
      } else {
        console.error("Sign-up failed");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  async function handleLogout() {
    await logout(getSessionId());

    updateSessionId(null);
    setSessionId(null);

    navigate("/");
  }

  return (
    <div className="signup-detail-entry-container">
      <div className="createAccount-container">
        {screenSize != "small" && (
          <div className="signup-entry-logo-container">
            <img src={Logo} alt="Company logo" />
          </div>
        )}
        <div className="createAccount-content">
          <div className="singup-entry-header">
            <div className="signup-detail-entry-actions">
              <div className="enter-detailsandbackpress" onClick={handleLogout}>
                <img
                  src={Back}
                  alt="Back"
                  className="signup-entry-backButton"
                />
              </div>
              <div className="linkenlogout">
                <div id="logout" onClick={handleLogout}>
                  Logout
                </div>
              </div>
            </div>
            <div className="form-detail-text">Enter the Details</div>
          </div>

          <form>
            <div className="signUpPackContainer">
              <div className="signUpPack">
                <div className="about">
                  <div className="aboutText">About Company </div>
                  <textarea
                    className="aboutInput"
                    type="text"
                    placeholder="Short description about your company"
                    value={about}
                    onChange={(e) => setabout(e.target.value)}
                  />
                </div>

                <div className="company">
                  <div className="locationText">Company Location*</div>
                  <input
                    className="companyInput"
                    type="text"
                    placeholder="Address"
                    value={companyLocation}
                    onChange={(e) => setCompanyLocation(e.target.value)}
                  />
                </div>

                <div className="contact">
                  <div className="contactText">Contact Number*</div>
                  <input
                    className="contactInput"
                    type="number"
                    placeholder="Contact number"
                    value={contactnumber}
                    onChange={handleInputChange}
                  />
                </div>
                {
                  <div className="contact-rror-message-styles">
                    {contactError}
                  </div>
                }

                <div className="verifyybutton-wrapper">
                  <div></div>
                  <button
                    type="button"
                    className={`verifyyButton ${
                      isButtonActive ? "active" : ""
                    }`}
                    disabled={!isButtonActive}
                    onClick={() => {
                      handleSignUp();
                    }}
                  >
                    {isLoading ? (
                      <img
                        src={LoadingWhite}
                        alt="Loading"
                        className="getstarted-loading-sellerbuyer"
                      />
                    ) : (
                      "Verify"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
