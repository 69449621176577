import "../styling/FeedbackSuccessPage.css"

function FeedbackSuccessPage({ handleBackPress, onClose }) {

    const handleContinueButtonClick = async () => {
        onClose();
    };

    const goBack = (e) => {
        handleBackPress();
    };

    return (
        <div className="feedbacksucess-modal-container" onClick={goBack}>
            <div className="feedbacksucess-container" onClick={(e) => e.stopPropagation()}>
                <div className="success-container-text">Thanks for your valuable Feedback!</div>
                <div className="success-container-message">We will definitely improve the experience!</div>
                <div className="feedbacksucess-button-container" onClick={handleContinueButtonClick} >
                    <button className="feedbacksucess-button">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FeedbackSuccessPage;