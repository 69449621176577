import { BASE_URL } from "../config";

const config = {
    APP_SETTINGS: 'api/v1/settings/app',
};
    

export async function getAppSetting(){
    try {
        const response = await fetch(BASE_URL + config.APP_SETTINGS, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "X-USER-SESSION-ID": "6b565053543a95b1319fb309a79fed61"
            },
        });

        const data = await response.json();
        return data;
        
    } catch (error) {
        console.log(error.message);
    }
}
  