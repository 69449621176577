import React from "react";
import { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import { SellerProfile } from "../components/buyer/SellerProfile";
import ProductDetailedView from "../components/item/ProductDetailedView";
import commentIcon from "../images/comment.svg";
import postComment from "../images/post_comment.svg";
import commentsDefaultProfile from "../images/comments_default_profile.svg";
import { displayToast } from "../utils/toastHelper";
import { handleResponseError } from "../utils/handleError";
import { comments, postComments, commentReplies, postReply } from "../api/commentApis";
import { contactSeller, getItemForBuyer, addFavourites, removeFavourites } from "../api/buyerApis";
import "../styling/Product.css";
import "../styling/MySliderComponent.css";
import "react-awesome-slider/dist/styles.css";

function Product({}) {
  const { itemId } = useParams();
  const replyButtonRef = useRef(null);
  const [item, setItem] = useState({});
  const [comment, setComment] = useState("");
  const [replies, setReplies] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentsData, setCommentsData] = useState(null);
  const [postingComment, setPostingComment] = useState("");
  const [loadingReplies, setLoadingReplies] = useState(null);
  const [isLoadingComments, setIsLoadingComments] = useState(true);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [replyingToCommentId, setReplyingToCommentId] = useState(null);
  const [currentSellerMerchant, setCurrentSellerMerchant] = useState({});
  const [replyingToMerchantName, setReplyingToMerchantName] = useState("");
  const [isLoadingMoreComments, setIsLoadingMoreComments] = useState(false);

  async function commentsHandler(page = 1) {
    if (page === 1) {
      setIsLoadingComments(true);
    }
    try {
      const data = await comments({ itemId, page });
      if (data.error) {
        setIsLoadingComments(false);
        handleResponseError(data.error);
      } else {
        setCommentsData((prevComments) =>
          page === 1 ? data.data : [...prevComments, ...data.data]
        );
        setTotalPages(Math.ceil(data.total / 10));
        setIsLoadingComments(false);
        setIsLoadingMoreComments(false);
      }
    } catch (error) {
      setIsLoadingComments(false);
      handleResponseError(error);
    }
  }

  const loadMoreComments = () => {
    if (currentPage < totalPages) {
      setIsLoadingMoreComments(true);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    commentsHandler(currentPage);
  }, [currentPage]);

  async function postCommentHandler() {
    setPostingComment(true);
    try {
      const data = await postComments({ message: comment, itemId });
      if (data.error) {
        handleResponseError(data.error);
      } else {
        setCommentsData((prevComments) => [data, ...prevComments]);
        setComment("");
      }
    } finally {
      setPostingComment(false);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        replyButtonRef.current &&
        !replyButtonRef.current.contains(event.target) &&
        !event.target.classList.contains("comment-input") &&
        !event.target.classList.contains("comment-submit-button") &&
        !event.target.classList.contains("comments-post-icon")
      ) {
        setReplyingToCommentId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [replyButtonRef, comment, replyingToCommentId]);

  const postReplyHandler = async () => {
    setPostingComment(true);
    try {
      const response = await postReply({
        itemId: replyingToCommentId,
        message: comment,
      });

      if (response.error) {
        setPostingComment(false);
        handleResponseError(response.error);
      } else {
        setPostingComment(false);
        setReplies((prevReplies) => ({
          ...prevReplies,
          [replyingToCommentId]: [
            ...(prevReplies[replyingToCommentId] || []),
            response,
          ],
        }));

        setCommentsData((prevComments) =>
          prevComments.map((prevComment) =>
            prevComment.id === replyingToCommentId
              ? {
                  ...prevComment,
                  total_replies: (prevComment.total_replies || 0) + 1,
                }
              : prevComment
          )
        );

        setComment("");
        setReplyingToCommentId(null);
      }
    } catch (error) {
      handleResponseError(error);
    }
  };

  const fetchMerchantReplies = async (commentId) => {
    try {
      setLoadingReplies(commentId);
      const response = await commentReplies({ itemId: commentId });

      if (response.error) {
        handleResponseError(response.error);
      } else {
        setReplies((prevReplies) => ({
          ...prevReplies,
          [commentId]: response.data,
        }));
      }
    } catch (error) {
      handleResponseError(error);
    } finally {
      setLoadingReplies(null);
    }
  };

  const handleReplyButtonClick = (commentId, merchantName) => {
    setReplyingToCommentId(commentId);
    setReplyingToMerchantName(merchantName);
    setTimeout(() => {
      const inputField = document.querySelector(".comment-input");
      if (inputField) inputField.focus();
    }, 100);
  };

  const handleViewRepliesClick = async (commentId) => {
    if (!replies[commentId]) {
      await fetchMerchantReplies(commentId);
    }
  };

  const handleInputChange = (event) => {
    setComment(event.target.value);
  };

  const handleHideReplies = (commentId) => {
    setReplies((prevReplies) => ({
      ...prevReplies,
      [commentId]: null,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const commentText = e.target.value.trim();
      if (commentText !== "" && !postingComment) {
        if (replyingToCommentId) {
          postReplyHandler();
        } else {
          postCommentHandler();
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      const data = await getItemForBuyer(itemId);
      if (data.error) return handleResponseError(data.error);

      setItem(data);
      setIsFavorite(data.is_favorite_for_merchant);
      setIsLoading(false);
    })();
  }, [itemId]);

  async function addFavourite(itemId) {
    const data = await addFavourites(itemId);

    if (data.error) {
      handleResponseError(data.error);
    } else {
      setIsFavorite(true);
      displayToast("item added to favorite", "success");
    }
  }

  async function removeFavourite(itemId) {
    const data = await removeFavourites(itemId);

    if (data.error) {
      handleResponseError(data.error);
    } else {
      setIsFavorite(false);
    }
  }

  function handleSellerProfileClose() {
    setIsModalOpen(false);
  }

  function handleContactSeller() {
    setCurrentSellerMerchant(item.merchant);
    setIsModalOpen(true);

    contactSeller(itemId);
  }

  const formatDate = (date, context) => {
    const currentDate = new Date();
    const inputDate = new Date(date);

    if (inputDate.toDateString() === currentDate.toDateString()) {
      return context === "reply"
        ? inputDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        : "Today";
    }

    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    if (inputDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    }

    return inputDate.toLocaleDateString([], {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  let lastDate = null;

  function SellerButton({ merchant }) {
    function handleSellerProfileLoadInternal() {
      setCurrentSellerMerchant(merchant);
      setIsModalOpen(true);
    }

    return (
      <div
        className="product-seller-button"
        onClick={handleSellerProfileLoadInternal}
      >
        <div className="product-seller-button-text">{merchant.name}</div>
      </div>
    );
  }

  function ActionContainer() {
    return (
      <div className="product-feature-favorite">
        <div
          onClick={() => {
            if (isFavorite) {
              removeFavourite(itemId);
            } else {
              addFavourite(itemId);
            }
          }}
        >
          {!isFavorite ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="35"
              viewBox="0 0 37 35"
              fill={isFavorite ? "orange" : "none"}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.68175 16.7636C-0.285414 10.6219 2.01359 3.60209 8.46142 1.52492C11.8531 0.430423 15.5968 1.07576 18.4164 3.19692C21.0839 1.13442 24.9651 0.437757 28.3531 1.52492C34.8009 3.60209 37.1146 10.6219 35.1493 16.7636C32.0876 26.4986 18.4164 33.9969 18.4164 33.9969C18.4164 33.9969 4.84609 26.6123 1.68175 16.7636Z"
                stroke="#8F8F8F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.75 7.78333C27.7117 8.41766 29.0977 10.1685 29.2645 12.2237"
                stroke="#8F8F8F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="35"
              viewBox="0 0 37 35"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.7375 0.376195C25.8418 0.376195 26.9443 0.531945 27.9925 0.883695C34.4518 2.98369 36.7793 10.0712 34.835 16.2662C33.7325 19.4319 31.93 22.3212 29.5693 24.6819C26.19 27.9544 22.4818 30.8594 18.49 33.3619L18.0525 33.6262L17.5975 33.3444C13.5918 30.8594 9.86253 27.9544 6.45178 24.6644C4.10678 22.3037 2.30253 19.4319 1.18253 16.2662C-0.794971 10.0712 1.53253 2.98369 8.06178 0.846945C8.56928 0.671945 9.09253 0.549445 9.61753 0.481195H9.82753C10.3193 0.409445 10.8075 0.376195 11.2975 0.376195H11.49C12.5925 0.409445 13.66 0.601945 14.6943 0.953695H14.7975C14.8675 0.986945 14.92 1.02369 14.955 1.05694C15.3418 1.18119 15.7075 1.32119 16.0575 1.51369L16.7225 1.81119C16.8832 1.8969 17.0636 2.02785 17.2195 2.14102C17.3182 2.21272 17.4072 2.27729 17.475 2.31869C17.5036 2.33554 17.5326 2.35248 17.5619 2.36956C17.7119 2.45716 17.8682 2.5484 18 2.64944C19.9443 1.16369 22.305 0.358695 24.7375 0.376195ZM29.3925 12.9762C30.11 12.9569 30.7225 12.3812 30.775 11.6444V11.4362C30.8275 8.98444 29.3418 6.76369 27.0825 5.90619C26.365 5.65944 25.5775 6.0462 25.315 6.7812C25.07 7.51619 25.455 8.3212 26.19 8.58194C27.3118 9.00194 28.0625 10.1062 28.0625 11.3294V11.3837C28.0293 11.7844 28.15 12.1712 28.395 12.4687C28.64 12.7662 29.0075 12.9394 29.3925 12.9762Z"
                fill="#FD9340"
              />
            </svg>
          )}
        </div>
      </div>
    );
  }

  function ProductMainAction() {
    return (
      <div className="contact-seller-container">
        <div className="product-seller-company">
          <text className="product-seller-company-title">Seller Company:</text>
          <div className="owner-seller-button-container">
            <SellerButton merchant={item.merchant} />
          </div>
        </div>
        <div className="action-container">
          <div className="comment-section-container">
            <div className="comments-wrapper">
              <button
                className="add-comment-button"
                onClick={(event) => {
                  setIsCommentModalOpen(true);
                  if (event.target.className.includes("add-comment-button")) {
                    setTimeout(() => {
                      const inputField =
                        document.querySelector(".comment-input");
                      if (inputField) inputField.focus();
                    }, 100);
                  }
                }}
              >
                <img src={commentIcon} className="comment-icon" alt="comment" />
                Add a comment...
              </button>
              <button
                className="view-all-comments-button"
                onClick={() => setIsCommentModalOpen(true)}
              >
                Viw all comments
              </button>
            </div>
            <div
              className="product-feature-contact-seller-container"
              onClick={handleContactSeller}
            >
              <text
                className="contact-seller-text10"
                style={{ cursor: "pointer" }}
              >
                Contact Seller
              </text>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        contentLabel="Popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}
      >
        {" "}
        <SellerProfile
          handleBackPress={handleSellerProfileClose}
          merchant={currentSellerMerchant}
        />{" "}
      </Modal>
      <Modal
        isOpen={isCommentModalOpen}
        onRequestClose={() => setIsCommentModalOpen(false)}
        contentLabel="Add Comment"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
          content: {
            top: "50%",
            left: "auto",
            right: "10%",
            bottom: "auto",
            transform: "translate(0%, -50%)",
            width: "70%",
            maxWidth: "650px",
            height: "75%",
            border: "none",
            background: "#F8F8F8",
            padding: "0px",
            borderRadius: "8px",
            boxShadow: "-5px -5px 10px rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <div className="comments-header">
          <hr className="comment-top-bar" />
          Comments
        </div>

        <div className="comments-section">
          {isLoadingComments ? (
            <div style={{ width: "100%" }}>
              <div style={{ paddingTop: "10%", paddingBottom: "10%" }}>
                <LoadingSpinner />
              </div>
            </div>
          ) : (
            commentsData.map((comment, index) => {
              const commentDate = formatDate(comment.created_at, "comment");
              const showDateDivider = commentDate !== lastDate;
              lastDate = commentDate;
              const repliesForComment = replies[comment.id];

              return (
                <React.Fragment key={comment.id}>
                  {showDateDivider && (
                    <div className="date-divider">
                      <h1 className="comment-date">{commentDate}</h1>
                    </div>
                  )}
                  <div className="comment-container">
                    <img
                      src={
                        comment.merchant.seller_profile_image_url
                          ? comment.merchant.seller_profile_image_url
                          : comment.merchant.buyer_profile_image_url
                          ? comment.merchant.buyer_profile_image_url
                          : commentsDefaultProfile
                      }
                      className="comment-profile-icon"
                      alt="comment"
                    />
                    <div className="comment-message-container">
                      <div className="commenter-name-time">
                        <h1 className="commenter-name">
                          {comment.merchant.name}
                        </h1>
                        <h6 className="comment-time">
                          {new Date(comment.created_at).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </h6>
                      </div>
                      <p className="comment">{comment.message}</p>
                      {loadingReplies === comment.id ? (
                        <button className="reply-loading">Loading...</button>
                      ) : (
                        <button
                          onClick={() =>
                            repliesForComment || comment.total_replies === 0
                              ? handleReplyButtonClick(
                                  comment.id,
                                  comment.merchant.name
                                )
                              : handleViewRepliesClick(comment.id)
                          }
                          className="view-replies-button"
                          ref={replyButtonRef}
                        >
                          {repliesForComment || comment.total_replies === 0
                            ? "Reply"
                            : comment.total_replies === 1
                            ? "View Reply"
                            : `View ${comment.total_replies} Replies`}
                        </button>
                      )}

                      {replies[comment.id] && (
                        <div className="replies">
                          {replies[comment.id].map((reply) => (
                            <div key={reply.id} className="reply-wrapper">
                              <img
                                src={
                                  reply?.merchant?.seller_profile_image_url
                                    ? reply?.merchant?.seller_profile_image_url
                                    : reply?.merchant?.buyer_profile_image_url
                                    ? reply?.merchant?.buyer_profile_image_url
                                    : commentsDefaultProfile
                                }
                                alt="User Profile"
                                className="reply-profile-pic"
                              />
                              <div className="comments-reply-container">
                                <div className="reply-name-time">
                                  <div className="reply-username">
                                    {reply?.merchant.name}
                                  </div>
                                  <div className="reply-time">
                                    {formatDate(reply?.created_at, "reply")}
                                  </div>
                                </div>
                                <div className="comment-reply">
                                  {reply?.message}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {replies[comment.id] &&
                        replies[comment.id].length > 0 && (
                          <button
                            className="view-replies-button"
                            onClick={() => handleHideReplies(comment.id)}
                          >
                            Hide
                          </button>
                        )}
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          )}
          {isLoadingMoreComments && (
            <div className="loading-wrapper">
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            </div>
          )}
          {currentPage < totalPages && !isLoadingMoreComments && commentsData!== null &&(
            <button
              onClick={loadMoreComments}
              disabled={isLoadingMoreComments}
              className="load-more-comments-button"
            >
              Load More Comments
            </button>
          )}
        </div>

        {replyingToCommentId && (
          <div className="replieng-highlighter">
            You are replying to {replyingToMerchantName}
          </div>
        )}
        <div
          className="comment-input-container"
          style={{
            borderTop: !replyingToCommentId && "2px solid #B3B1B0",
          }}
        >
          <input
            className="comment-input"
            placeholder="Add a comment..."
            value={comment}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            disabled={postingComment}
          />
          <button
            className="comment-submit-button"
            onClick={() => {
              if (replyingToCommentId) {
                postReplyHandler();
              } else {
                postCommentHandler();
              }
            }}
            disabled={postingComment || comment === ""}
          >
            <img
              className="comments-post-icon"
              src={postComment}
              alt="post comment"
            />
          </button>
        </div>
      </Modal>

      {isLoading ? (
        <div style={{ width: "100%" }}>
          <div style={{ paddingTop: "10%", paddingBottom: "10%" }}>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <ProductDetailedView
          item={item}
          MainActionContainer={ProductMainAction}
          SideActionContainer={ActionContainer}
        />
      )}
    </div>
  );
}

export default Product;
