import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Edit from "../../images/Edit.png";
import Logo from "../../images/Logo.png"
import Note from "../../images/note.svg";
import BACK from "../../images/backpress.svg"
import LoadingWhite from "../../images/LoadingWhite.gif";
import FreetRailPlanImage from "../../images/free_trail_image.png";
import { BASE_URL } from '../../config';
import { useSession } from "../../contexts/SessionContext";
import { getSettings } from '../../settings/SettingsHandler';
import { handleResponseError } from '../../utils/handleError';
import { getSessionId, reloadSession, setSessionId } from "../../session/SessionHandler";
import { logout } from '../../api/logout';
import { fetchSubscription, createSubscription, freeTrail } from '../../api/payment';
import "../../styling/VerificationPage.css";

export default function VerificationPage() {
  const [packages, setPackages] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
  const [loadingStates, setLoadingStates] = React.useState({});
  const { sessionId, updateSessionId, merchantStatus, billingStatus, setBillingStatus, setMerchantStatus } = useSession();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const globalSettings = await getSettings();
        const plansArray = Object.keys(globalSettings.plans).map(planName => ({
          type: planName,
          amount: globalSettings.plans[planName].price.amount,
          currency: globalSettings.plans[planName].price.currency,
          features: globalSettings.plans[planName].plan_description
        }));
        setPackages(plansArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleRefresh = () => {
    const refresh = async () => {
      const data = await reloadSession();
      if (data) {
        if (data.merchantDetails.merchant_status == 'active') {
          updateSessionId(await getSessionId());
          navigate('/');
        } else {
          window.location.reload();
        }
      }
    };
    refresh();
  };

  const handlePackageSelectionHandler = async (selectedPlan) => {
    setLoadingStates(prev => ({ ...prev, [selectedPlan]: true }));
    const payload = {
      "plan_name": selectedPlan,
      "subscription_type": "platform_subscription"
    };
    try {
      const data = await createSubscription(payload);
      setLoadingStates(prev => ({ ...prev, [selectedPlan]: false }));
      openPopup(data.gateway_subscription_url, data.id);
    }
    finally {
      setLoadingStates(prev => ({ ...prev, [selectedPlan]: false }));
    }
  }

  const openPopup = (url, subscriptionId) => {
    const width = 600;
    const height = 540;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    document.body.classList.add('blur-background');

    const popup = window.open(url, 'subscriptionPopup', `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars,status`);
    const checkPopup = setInterval(async function () {
      if (popup.closed) {
        document.body.classList.remove('blur-background');
        clearInterval(checkPopup);
        pollSubscriptionStatus(subscriptionId);
      }
    }, 100);
  };

  const pollSubscriptionStatus = async (subscriptionId) => {
    try {
      const data = await fetchSubscription(subscriptionId);
      if (data.status === 'active') {
        setBillingStatus(data.status);
        setMerchantStatus(data.status);
        return;
      }
    } finally {
    }
  };

  const handleFreeTrailPlan = async () => {
    setIsLoading(true);
    const payload = {
      "plan_name": "starter",
      "subscription_type": "platform_subscription"
    };
    try {
      const response = await freeTrail(payload);

      if (response.error) {
        handleResponseError(response.error);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        await handleRefresh();
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  useState(() => {
    (async () => {
      if (merchantStatus == 'afa_pending') {
        await otpTriggerHandler(sessionId);
      }
    })();
  })

  async function otpTriggerHandler(session_id) {
    try {
      const response = await fetch(
        BASE_URL + "api/v1/authentication/merchant/user/send_register_otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": session_id,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        navigate("/verifyMail", {
          state: {
            otp_id: data.otp_id,
            session_id: session_id,
          },
        });

      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleLogout() {
    await logout(sessionId);

    updateSessionId(null);
    setSessionId(null);

    navigate('/');
  }

  return (
    <div className='root-verification-container'>
      <div className="modal-container">
        <div className="logo-containr">
          <img src={Logo} alt="Company logo" className="logo" />
        </div>
        <div id="modal-content">
          <div className={`refreshContainer ${merchantStatus === 'deactivated' ? 'deactivatedRefreshContainerStyle' : ''}`}>
            <button onClick={handleRefresh} className="refresh">
              Refresh
            </button>
          </div>
          <div>
            <Link onClick={handleLogout}>
              <img src={BACK} alt="Back" className="svgBack" />
              <button className={`logoutButton ${merchantStatus === 'deactivated' ? 'deactivatedLogoutButtonStyle' : ''}`}>Logout</button>
            </Link>
          </div>
          <div className='textContainer'>
            {merchantStatus === 'deactivated' ? (
              <>
                <div className="verification-text-deactive">
                  Your Account is deactivated.
                </div>
              </>
            ) : merchantStatus === 'in_review' && billingStatus === null ? (
              <>
                <div className="free-trail-plan-verification-text">
                  <span class="black-text">One Stop Solution For Your Excess</span>
                  <span class="white-text"> Raw Materials</span>
                </div>
              </>
            ) : (
              <>
                <h2 className="choose-plan-heading">Choose a plan</h2>
              </>
            )}

            {merchantStatus === 'deactivated' ? (
              <>
                <div className="paragraphText-deactivate">
                  <p> Contact admin@bermer.in for assistance. We appreciate your understanding. </p>
                </div>
              </>
            ) : merchantStatus === 'in_review' && billingStatus === null ? (
              <>
                <div className='free-trail-image-styles'>
                  <img src={FreetRailPlanImage} className='free-trail-image' />
                </div>
                <div className='free-trail-plan-body-container'>
                  <h2 className='buy-or-sell-text'>Buy and Sell?</h2>
                  <div className='free-trail-description'>
                    <span >Grow your business with Us </span>
                    <span className='description-text'>Do it togather with Uexcess</span>
                  </div>
                  <button className={`free-trail-plan-button ${isLoding ? "free-trail-plan-button-active" : ""}`} onClick={handleFreeTrailPlan}>
                    {
                      isLoding ? (
                        <img src={LoadingWhite} alt="Loading" className="free-trail-api-loader"/>
                      ) : (
                        " Try Free Trial (60 Days)"
                      )
                    }
                  </button>
                  <div className='free-trail-plan-service'>
                    <img src={Note} alt="Back" className='note-styles' />
                    <span className="icon-content">No credit card, No commitment...click & enjoy our services</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="choose-plan-option-container">
                  {packages && packages.map((data, index) => (
                    <div key={index} className="choose-plan-list-container">
                      <h3 className='package-item-type'>{data.type}</h3>
                      <hr className="type-underline" />
                      <div className='package-amount-styles'>
                        <text >
                          {data.currency} {data.amount}
                        </text>
                      </div>
                      <div className='package-feature-styles'>
                        <ul>
                          {data.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))}
                        </ul></div>
                      <div className='package-button-container-styles'>
                        <button className='package-button-styles' onClick={() => handlePackageSelectionHandler(data.type)}>
                          {
                            loadingStates[data.type] ?
                              (
                                <img src={LoadingWhite} alt="Loading" className="payment-loader-styling" />
                              ) : (
                                <span>Select Plan</span>
                              )
                          }
                        </button>
                      </div>
                    </div>))}
                </div>
              </>
            )}
            {merchantStatus !== 'deactivated' && (
              <div className="editText">
                <div className='edit-container-styles'>
                  <p>Check/edit your details here:</p>
                  <div>
                    <Link to="/edit">
                      <img src={Edit} alt="Edit button" className="edit" />
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
