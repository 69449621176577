import React, { useEffect, useState} from 'react';
import '../styling/Inquiry.css';
import 'react-awesome-slider/dist/styles.css';
import '../styling//MySliderComponent.css';
import { getNotificationDetails } from "../api/sellerApis";
import { handleResponseError } from "../utils/handleError";
import { SellerProfile } from '../components/buyer/SellerProfile';
import Modal from "react-modal";
import LoadingSpinner from "../components/LoadingSpinner";
import ProductDetailedView from '../components/item/ProductDetailedView';

function Inquiry({ id, backPressHandler }) {
  const [notification, setNotification] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  useEffect(() => {
    getProduct(id);
  }, []);
 
  async function getProduct(id) {
    const data = await getNotificationDetails(id);
    if (data.error) return handleResponseError(data.error);
    setNotification(data);
    setIsLoading(false);
    console.log(data);
  }

  const[inquiry,setInquiry]=useState(true);

  const handleProfileClick=()=>{
    setIsProfileOpen(true);
  }

  function handleProfileBackPress() {
    setIsProfileOpen(false);
  }

  function SideActionContainer() {
    return (
      <div></div>
    );
  }

  function MainActionContainer() {
    return (
      <div className='inquiry-main-action-container'>
        <div className='inquiry-description'>{notification.description}</div>
        <div className='buyer-button-container'>
          <div className='product-buyer-button' onClick={handleProfileClick}>
            <div className='product-buyer-button-text'>{notification.buyer_merchant.name}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='inquiry-container-root'>
      <div className='inquiry-container-main'>
      {isProfileOpen ? <SellerProfile handleBackPress={handleProfileBackPress} merchant={notification.buyer_merchant} /> :

        isLoading ? 
          <div style={{ width: '100%' }}>
            <div style={{ paddingTop: '10%', paddingBottom: '10%' }}> <LoadingSpinner/> </div>
          </div> :
          <ProductDetailedView  
            item={notification.product} 
            MainActionContainer={MainActionContainer} 
            SideActionContainer={SideActionContainer}  
            backHandler={backPressHandler} 
            headingText={"Inquiry"}
          />
      }
      </div>
    </div>
  );
}

export default Inquiry;
