import React, { useEffect, useState } from 'react';
import UmDropDown from './UmDropDown';
import '../styling/ProductFillSlot.css'
const ProductFillSlot = ({
  name,
  value = '',
  defaultUm = '',
  dataType,
  supportedUm,
  handleUpdate,
  isOptional = false,
  umName = '',
  error = '',
  clearError,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [umValue, setUmValue] = useState(defaultUm);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (clearError) {
      clearError();
    }
  };

  useEffect(() => {
    handleUpdate(name, inputValue, umValue);
  }, [inputValue, umValue]);

  return (
    <div className="container-productFillslot">
      <div className="text-productFillslot">
        {name}
        {supportedUm == null || supportedUm.length < 2
          ? defaultUm != null && defaultUm !== ''
            ? `(${defaultUm})`
            : null
          : null}
        {!isOptional ? '*' : ''}
      </div>
      <div className='entry-input-input-container'>
        {supportedUm != null && supportedUm.length > 1 ? 
          <div className='entry-input-with-um'> 
            <input
              placeholder=""
              className="dropdown-optional-style-m2"
              type={dataType === 'text' ? 'text' : 'number'}
              value={inputValue.toString()}
              style={{}}
              onChange={handleInputChange} />
            <UmDropDown parameter={{ "options": supportedUm, "default": defaultUm, name: umName }} selected={umValue} setSelected={setUmValue} />
          </div>
          :
          <div>
          <input
            placeholder=""
            className="dropdown-optional-style"
            type={dataType === 'text' ? 'text' : 'number'}
            value={inputValue}
            onChange={handleInputChange}
          />
          </div>
          }
         {error && <div className="descrption-error-message">{error}</div>}
      </div>
    </div>
  );
};

export default ProductFillSlot;
