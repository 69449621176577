import React, { useState, useRef, useEffect } from "react";
import "../styling/DropDownFilter.css";

const DropDownFilter = ({ parameter, addFilterHandler, value=null, clearFilterSignal }) => {

  const [selected, setSelected] = useState(value == null ? '' : value);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownContainerRef = useRef(null);
  const [hovered, setHovered] = useState(null);

  const handleOptionSelect = (option) => {
    if (option === selected) {
      setSelected(null); // Clear the selected value
      addFilterHandler(parameter.name, null);
     
    } else {
      setSelected(option); // Update the selected value
      addFilterHandler(parameter.name, option);
   
    }
  };

  const handleImageClick = () => {
    setDropdownOpen(!dropdownOpen);
  };


  const handleBackspace = (event) => {
    if (event.key === "Backspace" && selected !== null) {
      setSelected(null); // Clear the selected value
      addFilterHandler(parameter.name, null); // Pass parameter name and null to remove the filter
    }
  };

  useEffect(() => {
    setSelected(value == null ? '' : value);
  }, [value]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  const handleClearFilter = () => {
    setSelected('');
    addFilterHandler(parameter.name, '');
  }

  useEffect(()=>{
    if (clearFilterSignal > 0) handleClearFilter();
  }, [clearFilterSignal]);

 return (
  <div className="dropdown-container">
    <div className="dropdown-parameter-title">
      <text className="dropdown-parameter-title-text">{parameter.name}</text>
    </div>

    <div
      className="dropdown-containers10"
      ref={dropdownContainerRef}
      onKeyDown={handleBackspace}
      tabIndex="0"
    >
      <div
        className="dropdown-header10"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <input
          type="text"
          placeholder={selected}
          className="containerdropdownparam-styles10"
          readOnly
          style={{ fontSize: "12px", color: "#b3b1b0",marginBottom:'10%' }}
        />
  
      </div>
      <div
        style={{ marginLeft: "380px", marginTop: "-25px" }}
        onClick={handleImageClick}
      >
      {dropdownOpen && (
  <div className="dropdownparam-options10" >
    {parameter.options.map((option, index) => (
      <div
        key={index} 
        onClick={() => handleOptionSelect(option)}
        onMouseEnter={() => setHovered(option)}
        onMouseLeave={() => setHovered(null)}
      >
       
                <span className="rippledropdown10">
                  <div
                    style={{
                      height: "45px",
                      marginTop: -"11.8px",
                     
                    }}
                  >
                   
                    <div>
                      
                      <div
                        className="squaredrop10"
                        style={{
                          backgroundColor:
                            selected === option ? "#FFA500" : hovered === option ? "#FFA500" : "",
                          marginTop: "9%",
                        }}
                      />
                      <div className="dropdown-text-style10">{option}</div>
                    </div>
                   
                  </div>
                </span>
              </div>
                
            ))}
          </div>
         
        )}
       
      </div>
    </div>
  </div>
);}
export default DropDownFilter;