import React, { useState, useEffect } from "react";
import '../styling/AddProduct.css';
import { getAppSetting } from "../constants/getAppSetting";
import FillProduct from "./FillProduct";
import { BackPress } from "../components/BackPress";
import HelpPage from "./HelpPage";

const AddProduct = ({ handleAddProductClose, newProduct }) => {
  const [category, setCategory] = useState([]);
  const [properties, setProperties] = useState({});
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isHelp, setIsHelp] = useState(false);
  
  useEffect(() => {
    async function fetchAndStoreCategories() {
      const filters = await getAppSetting();
      const categories = Object.keys(filters.products);
      setCategory(categories);
      setProperties(filters.products);
      localStorage.setItem("categories", JSON.stringify(categories));
    }

    fetchAndStoreCategories();
  }, []);

  const handleFillProduct = (category) => {
    setSelectedCategory(category);
    setIsAddingProduct(true);
  };

  const goBack = (e) => {
    handleAddProductClose();
  };

  const hideFillProduct = () => {
    setIsAddingProduct(false);
  };

  const onClose = () => {
    setIsAddingProduct(false);
    handleAddProductClose();
  };

  const handleNewProduct = (newData) => {
    newProduct(newData);
  }

  const hideHelpPage = () => {
    setIsHelp(false);
  };

  const handleHelpClick = () => {
    setIsHelp(true);
    setIsAddingProduct(false);
  };

  return (
    <div>
      {isAddingProduct ? (
        <FillProduct category={selectedCategory} parameters={properties[selectedCategory].add_product.parameters_default} newparematers={properties[selectedCategory].add_product.parameters_fixed} data={{ categoryType: selectedCategory }} handleBackPress={hideFillProduct} onClose={onClose} updateFormData={handleNewProduct} />
      ) : (
        <div>
          {isHelp  ? <HelpPage onClose={hideHelpPage} /> : (
            <div className="modalone-background" onClick={goBack} >
              <div className="modal-divstyle" style={{ backgroundColor: 'white', width: '518px', height: '883px' }} onClick={(e) => e.stopPropagation()}>
                <div className="maincontainer-style" style={{ marginBottom: '60px' }}>
                  <div className="add-product-header">
                    <div className="add-product-back-press">
                      <BackPress onClick={goBack} />
                    </ div>
                    <div className="add-product-header-text-container">
                      <div className="maintitle-style">Category</div>
                      <button className="helpButton" id="hidden" >
                        <span className="helpText" onClick={handleHelpClick}>Help</span>
                      </button>
                    </div>
                  </div>
                  <div className="textcontainer-style">
                    <p className="text-style">
                      Select the Category of Item you want to sell
                    </p>
                  </div>
                </div>
                <div className="category-list-container">
                  {category.map((categoryItem, index) => (
                    <button
                      onClick={() => handleFillProduct(categoryItem)}
                      key={index}
                      className="category-button"
                    >
                      {categoryItem}
                    </button>
                  ))}
                </div>
              </div>
            </div>)}
        </div>
      )}

    </div>
  );
};

export default AddProduct;
