export function pushUrlState(stateString) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('state', stateString);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
}

export function updateUrlQueryParams(searchParams) {
    const urlSearchParams = objectToQueryParams(searchParams);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
}

export function getUrlQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);

    return urlSearchParamsToObject(queryParams);
}

export function getUrlQueryParamsInArray() {
    const queryParams = new URLSearchParams(window.location.search);

    return urlSearchParamsToArray(queryParams);
}

function urlSearchParamsToArray(searchParams) {
    return Object.entries(urlSearchParamsToObject(searchParams)).map(([name, value]) => ({ name, value }))
}

function urlSearchParamsToObject(searchParams) {
    const obj = {};

    for (const [key, value] of searchParams.entries()) {
        obj[key] = value;
    }

    return obj;
}

function objectToQueryParams(obj) {
    const queryParams = [];
  
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  
    return queryParams.join('&');
  }