import { getMerchantDetails } from "../api/getMerchantDetails";
import { getCacheValue, setCacheValue } from "../cache/CacheHandler";

const SESSION_CACHE_KEY = 'session_data';

export function getSessionData() {
    return getCacheValue(SESSION_CACHE_KEY);
}

export async function reloadSession() {
    let data = await getSessionData();

    if (data == null || data.sessionId == null) {
        setCacheValue(SESSION_CACHE_KEY, null, 1200);
        window.location.href = '/';
        return null;
    };

    const response = await getMerchantDetails(data.sessionId)
    if (response.error) {
        data.sessionId = null;
        window.location.href = '/';
    } else {
        data.merchantDetails = response;
    }

    setCacheValue(SESSION_CACHE_KEY, data, 1200);

    return data;
}

export function isUserSessionActive() {
    const data = getSessionData();

    return !(data == null || data.sessionId == null);
}

export function getSessionMerchantDetails() {
    const data = getSessionData();

    if (data != null) return data.merchantDetails;
    return null;
}

export async function setSessionId(sessionId) {
    let data = {};

    data.sessionId = sessionId;

    if (sessionId != null) {
        data.merchantDetails = await getMerchantDetails(data.sessionId);
        if (data.merchantDetails == null) {
            data.sessionId = null;
        }
    }

    return setCacheValue(SESSION_CACHE_KEY, data, 60);
}

export function getSessionId() {
    const data = getSessionData();

    if (data != null) return data.sessionId;
    return null;
}