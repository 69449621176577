import "./ImageLoader.css"
import DeleteIcon from '../../images/logo/delete.png';

export function ImageLoader({imageUploadProgress, cancelHandler}) {
    return <div className="imageLoaderContainer">
        <div className="cancel-load-container">
            <img src={DeleteIcon} alt="cancel" onClick={cancelHandler} className="remove-icon"/>
        </div>
        <div className="image-loading-container">
            <div className="image-upload-progress-bar" style={{
                width: (imageUploadProgress * 92) + 'px',
            }} />
        </div>
    </div>
}