import { BASE_URL, NOTIFICATION_TOKEN } from "../config";
import { handleResponse } from "./responseHandler";
import { getSessionId } from "../session/SessionHandler";

export async function appNotificationToken(payload) {
  const response = await fetch(BASE_URL + NOTIFICATION_TOKEN, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
    body: JSON.stringify(payload),
  });

  return handleResponse(response);
}
