import { useSession } from "../../contexts/SessionContext";
import { calculateDaysAgo } from "../../utils/dateHelper";
import { getReadableIndianNumber } from "../../utils/numberHelper";
import './Item.css';

export default function Item({ item, handleItemClick }) {
    const { screenSize } = useSession();

    const isBottomContainerPresent = item.price != null || item.quantity != null;

    const itemDetailsContainerClass = !isBottomContainerPresent ? "item-details-container-full item-details-container" : "item-details-container";

    const paramsLen = screenSize == 'small' ? 2 : 3;

    return (
        <div key={item.id} className="item-container" onClick={() => handleItemClick(item)}>
            {item.catogery_type && (
                <div className="catogery-name">{item.catogery_type}</div>
            )}
            <div className="cardImageContainer">
                {item.images && (
                    <img className="cardImage" src={JSON.parse(item.images)[0]} alt='image' />
                )}
            </div>
            <div className="image-bottom-container">
                <div className="item-days-container">
                    <div className="item-days-txt">{calculateDaysAgo(item.created_at)}</div>
                </div>
            </div>
            <div className={itemDetailsContainerClass}>
                {item.parameters.length > 0 ?
                    (
                        <div className="parametersContainer">
                            {item.parameters.slice(0, paramsLen).map((parameter) =>
                            (
                                <div key={parameter.id} className="parameters">
                                    {parameter.name} {"  "} {parameter.value}
                                    {parameter.um}
                                </div>
                            ))}
                        </div>
                    ) : (<div className="item-description"> {item.product_description} </ div>)
                }
            </div>
            <div className="item-bottom-container">
                <div className="item-bottom-left"> {item.price != null ? (
                    <span>
                        Rs <br />
                        {getReadableIndianNumber(item.price)}
                    </span>
                ) : (
                    ''
                )}</div>
                <div className="item-bottom-right">
                    {item.quantity != null ? (
                        <span>
                            {item.quantity_um} <br />
                            {getReadableIndianNumber(item.quantity)}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}