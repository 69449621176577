import React, { useState, useRef, useEffect } from 'react';
import '../styling/DropDownOptionSet.css';
import DropDownIconUm from '../images/arrow_down.svg';
import '../styling/UmDropDown.css';

const UmDropDown = ({ parameter, selected, setSelected, value = '' }) => {
  let data = [];
  const [hovered, setHovered] = useState(null);
  parameter.options.forEach((option) => {
    data.push({
      label: option,
      value: option,
    });
  });

  const [showDropdown, setShowDropdown] = useState(false);
  const handleDropDownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  const handleDataSelect = (option) => {
    if (selected === option) {
    } else {
      setSelected(option);
      setShowDropdown(false);
    }
  };

  return (
    <div>
      <div className="paramroot-container" ref={dropdownRef} style={{ position: 'relative' }}>
        <input placeholder={selected} className="containerParam" readOnly onClick={handleDropDownClick} />
        <img
          src={DropDownIconUm}
          onClick={handleDropDownClick}
          className='arrow-down'
        />
        {showDropdown && (
          <div className="dropdownUm-options" style={{ zIndex: 2 }}>
            {data.map((item) => (
              <div
                key={item.label}
                onClick={() => handleDataSelect(item.value)}
                onMouseEnter={() => setHovered(item.value)}
                onMouseLeave={() => setHovered(null)}
                className={item.value == selected ? 'um-drop-option-umDropDown um-drop-option-selected' : 'um-drop-option-umDropDown'}
              >
                <div className='quantity-dropdown-option-container'>
                  <div
                    className="squaredropsecond"
                    style={{
                      backgroundColor: hovered === item.value ? '#FFA500' : 'white', cursor: 'auto'
                    }}
                  />
                  <div className="dropdownUm-text-style"  >{item.label}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UmDropDown;
