import React from "react";
import { useState, useRef } from "react";
import "../../styling/SignUp.css";
import Scrap from "../../images/Scrap.png";
import Logo from "../../images/Logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL, REGISTER } from "../../config";
import { handleResponse } from "../../api/responseHandler";
import { handleResponseError } from "../../utils/handleError";
import { useSession } from "../../contexts/SessionContext";
import { setSessionId } from "../../session/SessionHandler";
import LoadingWhite from "../../images/LoadingWhite.gif";

export default function SignUp({ onCloseWelcomeBack }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const { screenSize } = useSession();
  const { updateSessionId } = useSession();
  const signUpEmailInputRef = useRef(null);
  const signUpPasswordInputRef = useRef(null);
  const getStartedButtonFieldRef = useRef(null);
  const navigate = useNavigate();
  const linkTarget = screenSize === "small" ? "_self" : "_blank";

  const handleCompanyNameKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      signUpEmailInputRef.current.focus();
    }
  };

  const handleSignUpEmailKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      signUpPasswordInputRef.current.focus();
    }
  };

  const handleSignUpPasswordKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getStartedButtonFieldRef.current.click();
    }
  };

  const isButtonActive =
    companyName.trim() !== "" &&
    email.trim() !== "" &&
    password.trim() !== "" &&
    agreedToTerms;

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword.length >= 6) {
      setPasswordLengthError(false);
    }
  };

  const handlePasswordBlur = () => {
    if (password.length < 6) {
      setPasswordLengthError(true);
    } else {
      setPasswordLengthError(false);
    }
  };

  const handleRegistration = async () => {
    setIsLoading(true);

    const registrationData = {
      email: email,
      password: password,
      merchant_name: companyName,
    };

    try {
      const response = await fetch(BASE_URL + REGISTER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registrationData),
      });

      const data = await handleResponse(response);

      if (data.error == null) {
        await updateSessionId(data.session_id);
        await setSessionId(data.session_id);
        await otpTriggerHandler(data.session_id);
      } else {
        handleResponseError(data.error);
      }
    } catch (error) {
      console.error("Error during registration:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const otpTriggerHandler = async (session_id) => {
    try {
      const response = await fetch(
        BASE_URL + "api/v1/authentication/merchant/user/send_register_otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": session_id,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        navigate("/verifyMail", {
          state: {
            otp_id: data.otp_id,
            session_id: session_id,
          },
        });
      } else {
        handleResponseError(data.error);
      }
    } catch (error) {
      console.error("Error during OTP trigger:", error);
    }
  };

  onCloseWelcomeBack();
  const buttonStyle = {
    cursor: isButtonActive ? "pointer" : "default",
    backgroundColor: isButtonActive ? "#FD9340" : "#E5E4E3",
    position: "relative",
  };

  return (
    <div className="signUp-container">
      <div className="signUp-content">
        <div className="signUpImage-container">
          <img src={Scrap} alt="Company logo" className="signUpScrap-image" />
          <img src={Logo} alt="Company logo" className="signUplogo-image" />
        </div>
        <div className="signup-form-container">
          <div className="signUpForm">
            <h2 className="createAccount">Create New Account</h2>
            <form>
              <input
                type="text"
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                onKeyPress={handleCompanyNameKeyPress}
              />
              <input
                type="email"
                placeholder="Email"
                ref={signUpEmailInputRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={handleSignUpEmailKeyPress}
              />
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  ref={signUpPasswordInputRef}
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyPress={handleSignUpPasswordKeyPress}
                  onBlur={handlePasswordBlur}
                  minLength={6}
                />
                {passwordLengthError && (
                  <p id="password-error" className="password-error-styling">
                    Password should be at least 6 characters.
                  </p>
                )}
              </div>
              <div className="Terms-of-Service-text-style">
                <input
                  type="checkbox"
                  id="agreedToTerms"
                  checked={agreedToTerms}
                  onChange={() => setAgreedToTerms(!agreedToTerms)}
                />
                <p>
                  <span>I Agree to the</span>{" "}
                  <a
                    href="/terms-conditions"
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  <span> and </span>
                  <a
                    href="/privacyPolicy"
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
              <div className="getbutton-wrapper">
                <button
                  type="button"
                  ref={getStartedButtonFieldRef}
                  className={`getStartButton ${isButtonActive ? "active" : ""}`}
                  disabled={!isButtonActive || isLoading}
                  onClick={() => {
                    handleRegistration();
                  }}
                  style={buttonStyle}
                >
                  {isLoading ? (
                    <img
                      src={LoadingWhite}
                      alt="Loading"
                      className="getstartedstyling"
                    />
                  ) : (
                    "Get Started"
                  )}
                </button>
              </div>
              <p className="createacc-text">
                Have an account?{" "}
                <Link to="/login" className="createaccount-link">
                  <h2 className="spamtext">Login</h2>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
