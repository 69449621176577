import Notification from "../images/Notification.svg";

export function notificationToast(
  message,
  link,
  entityId,
  type = "error",
  duration = 90000
) {
  const toastMessage = document.createElement("div");
  const icon = document.createElement("span");

  if (type === "error") {
    icon.innerHTML = "❌";
    toastMessage.classList.add("toast-message-error");
  } else {
    const img = document.createElement("img");
    img.src = Notification;
    img.alt = "Success Icon";
    icon.appendChild(img);
    toastMessage.classList.add("notification-success");
  }

  toastMessage.appendChild(icon);

  const messageText = document.createElement("span");
  messageText.innerText = message;
  toastMessage.appendChild(messageText);

  const toastContainer = document.createElement("a");
  toastContainer.href = `${link}`;
  toastContainer.appendChild(toastMessage);
  toastContainer.classList.add("toast-container");

  document.body.appendChild(toastContainer);

  setTimeout(() => {
    document.body.removeChild(toastContainer);
  }, duration);
}
