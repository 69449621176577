const BASE_URL = "https://uexcess-backend-bawzt2unca-el.a.run.app/";
const ACTIVE_PRODUCTS = "api/v1/merchant/seller/items?status=active";
const SOLD_PRODUCTS = "api/v1/merchant/seller/items?status=sold";
const BUYER_PRODUCTS = "api/v1/settings/app";
const DRAFT_PRODUCTS = "api/v1/merchant/seller/items?status=draft";
const FAVORITE_PRODUCTS = "api/v1/merchant/favorites";
const FETCH_NOTIFICATION = "api/v1/merchant/notifications";
const INQUIRY="api/v1/merchant/notifications/";
const PRODUCT_DETAILS="api/v1/merchant/items?";
export const GET_BUYER_ITEM="api/v1/merchant/buyer/items/";
export const GET_BUYER_REQUEST_ITEM = "api/v1/merchant/buyer/request_items/";
export const LOGOUT = "api/v1/authentication/merchant/logout"
export const GET_DETAILS = "api/v1/merchant/get_details"
export  const SELLER_ITEMS= 'api/v1/merchant/seller/items';
export const GET_BUYER_REQUESTS = "api/v1/merchant/seller/";
export const FEEDBACK = 'api/v1/merchant/feedbacks';
export const BUYER_REQUEST_ITEMS = 'api/v1/merchant/buyer/';
export const CREATE_SUBSCRIPTION = "api/v1/merchant/subscription";
export const FREE_TRAIL= "api/v1/merchant/subscription/free_trail";
export { BASE_URL, ACTIVE_PRODUCTS, SOLD_PRODUCTS, BUYER_PRODUCTS, DRAFT_PRODUCTS, FAVORITE_PRODUCTS, FETCH_NOTIFICATION, INQUIRY, PRODUCT_DETAILS };

const UPDATE_DETAILS = "api/v1/merchant";
const FORGOT_PASSWORD = "api/v1/authentication/merchant/user/send_forgot_password_otp";
const UPDATE_PASSWORD = "api/v1/authentication/merchant/user/update_password";
const SIGN_UP = "api/v1/merchant";
const REGISTER = "api/v1/merchant/register";
const REGISTER_OTP = "api/v1/authentication/merchant/user/send_register_otp";
const MERCHANT_LOGIN = "api/v1/authentication/merchant/login";
const APP_SETTINGS = "api/v1/settings/app";
const MERCHANT = "api/v1/merchant";
const COMMENTS = "api/v1/merchant/product_comments";
const COMMENTS_REPLY = "api/v1/merchant/product_comments/reply";


export { UPDATE_DETAILS, FORGOT_PASSWORD, UPDATE_PASSWORD, SIGN_UP, REGISTER, REGISTER_OTP, MERCHANT_LOGIN, APP_SETTINGS, MERCHANT, COMMENTS, COMMENTS_REPLY };

export const NOTIFICATION_TOKEN = "api/v1/merchant/notification_tokens/web";
export const API_KEY = "AIzaSyBxGXDPeOVv5Bu-9pR6Vqb8H6r5AAD6RrA";
export const AUTH_DOMAIN = "notifications-83daf.firebaseapp.com";
export const PROJECT_ID = "notifications-83daf";
export const STORAGE_BUCKET = "notifications-83daf.appspot.com";
export const MESSAGING_SENDER_ID = "939323156113";
export const APP_ID = "1:939323156113:web:86c37b20ef046e47c74130";
export const MEASURMENT_ID = "G-BC8QJVPVDY";
export const VAPID_KEY = "BAQ28ZRbfgixOi6zMf5w0IhJapwtR0H6VcrXiUsEKQIQVy5M4MDeEQSTJabm-aCz6b3qDPKtFwaVRXByTGM0RU4";

