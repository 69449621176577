import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Logo } from '../images/logo/Logo.svg';
import { ReactComponent as LogoSmall } from '../images/logo/logo_small.svg';
import { ReactComponent as Notification } from '../images/notification/notification.svg';
import { ReactComponent as NotificationSmall } from '../images/notification/notification_small.svg';
import { ReactComponent as Favorite } from '../images/header/favorite.svg';
import { ReactComponent as FavoriteSmall } from '../images/header/favorite_small.svg';
import { ReactComponent as Feedback } from "../images/feedback/feedback.svg";
import { ReactComponent as FeedbackSmall } from "../images/feedback/feedback_small.svg";
import { ReactComponent as DefaultMerchantLogo } from '../images/default_merchant_logo.svg';
import '../styling/Header.css';
import ProfileModal from "./header/ProfileModal";
import { useSession } from "../contexts/SessionContext";
import { logout } from '../api/logout';
import { isUserSessionActive, setSessionId } from '../session/SessionHandler';
import { useNavigate } from "react-router-dom";
import { MODE_BUYER, MODE_SELLER } from '../ConstantNew';
import Modal from 'react-modal';
import FeedbackPage from '../screens/FeedbackPage';

function Header({ }) {
  const { sessionId, updateSessionId, mode, setMode, screenSize, merchantLogo, merchantStatus, notificationCount } = useSession();
  const navigate = useNavigate();
  const [contentVisible, setContentVisible] = useState(false);
  const contentRef = useRef(null);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (merchantStatus != '' && merchantStatus != null && merchantStatus != 'active') {
        navigate('/verification');
      }
    })();
  }, [sessionId]);

  const handleModeChange = (newMode) => {
    if (mode != newMode) {
      setMode(newMode);
      navigate('/');
    }
  };

  const closeContent = () => {
    setContentVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        setContentVisible(false);
      } else {
        setContentVisible(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contentVisible]);

  const handleLogout = async () => {
    await logout(sessionId);

    updateSessionId(null);
    setSessionId(null);
  };

  const handleHomeClick = () => {
    navigate('/');
  }

  const handleNotificationClick = () => {
    navigate('/notifications');
  }

  const handleFavoriteClick = () => {
    navigate('/favorites');
  }

  const handleFeedbackClick = () => {
    setIsFeedbackOpen(true);
  }

  function handleFeedbackPageClose() {
    setIsFeedbackOpen(false);
  }

  const handleProfileClick = () => {
    navigate('/profile');
  }

  const renderLogo = () => {
    if (screenSize === 'small') {
      return <LogoSmall />;
    } else if (screenSize === 'medium') {
      return <Logo />;
    } else {
      return <Logo />;
    }
  };

  const renderFeedbackComponent = () => {
    if (screenSize === 'small') {
      return <FeedbackSmall />;
    } else if (screenSize === 'medium') {
      return <Feedback />;
    } else {
      return <Feedback />;
    }
  }

  const renderNotification = () => {
    return (
      <div className='notification-container'>
        {screenSize === 'small' ? <NotificationSmall /> : <Notification />}
        {notificationCount > 0 && <div className='new-notifications-present' />}
      </div>
    );
  };

  const renderFavorite = () => {
    if (screenSize === 'small') {
      return <FavoriteSmall />;
    } else if (screenSize === 'medium') {
      return <Favorite />;
    } else {
      return <Favorite />;
    }
  };

  const renderProfile = () => {
    return (
      <div className='merchant-logo-container'>
        {merchantLogo != null && merchantLogo != '' ?
          <img src={merchantLogo} alt="logo" className='merchantLogo' /> :
          <DefaultMerchantLogo className='merchantLogo' />
        }
      </div>
    );
  };

  const ModeButtons = () => {
    return <div className='mode-container'>
      <button
        style={{ backgroundColor: mode == MODE_BUYER ? '#FD9340' : '#B3B1B0', }}
        className='mode-button'
        id='buyer-button'
        onClick={() => handleModeChange(MODE_BUYER)}
      />

      <button
        style={{ backgroundColor: mode == MODE_SELLER ? '#FD9340' : '#B3B1B0' }}
        className='mode-button'
        id='seller-button'
        onClick={() => handleModeChange(MODE_SELLER)}
      />
    </div>
  }

  return (
    <div>
      <div id='header-container'>
        <div id='logo'>
          <div onClick={handleHomeClick}>
            {renderLogo()}
          </div>
        </div>

        <div id='feedback-hidden'>
          <div onClick={handleFeedbackClick} className='hidden-style'>
            {renderFeedbackComponent()}
          </div>
        </div>

        <div id='action-container'>
          {screenSize != 'small' ? <ModeButtons /> : null}

          <div id='notification'>
            <div onClick={handleNotificationClick}>
              {renderNotification()}
            </div>
          </div>

          <div id='favorite'>
            <div onClick={handleFavoriteClick}>
              {renderFavorite()}
            </div>
          </div>

          <div id='feedback' >
            <div onClick={handleFeedbackClick}>
              {renderFeedbackComponent()}
            </div>
          </div>

          <div id='profile' ref={contentRef}>
            {renderProfile()}
            <ProfileModal isOpen={contentVisible} onClose={closeContent}
              onLogoutClick={handleLogout}
              onProfileClick={handleProfileClick} />
          </div>
        </div>
      </div>

      <div>
        {screenSize == 'small' ?
          <ModeButtons /> : null}
      </div>

      <Modal
        isOpen={isFeedbackOpen}
        contentLabel="Popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}> <FeedbackPage handleFeedbackPageClose={handleFeedbackPageClose} />
      </Modal>
    </div>
  )
}

export default Header;
