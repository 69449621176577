import React from "react";
import { useState, useEffect } from "react";
import "../../styling/Edit.css";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../contexts/SessionContext";
import { BASE_URL, UPDATE_DETAILS } from "../../config";
import { getMerchant } from "../../api/merchantApis";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function Edit() {
  const [companyName, setCompanyName] = useState("");
  const [about, setAbout] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const navigate = useNavigate();
  const { sessionId } = useSession();
  const isButtonActive =
    companyName.trim() !== "";
  const [ isLoading, setIsLoading ] = useState(false);

  async function fetchDetails() {
      const merchantDetails = await getMerchant();
      setEmail(merchantDetails.seller_email);
      setAbout(merchantDetails.seller_profile_description);
      setLocation(merchantDetails.address);
      setCompanyName(merchantDetails.name || "");
      setContactNumber(merchantDetails.contact);
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        await fetchDetails();
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleSave = async () => {
    const requestBody = {
      seller_profile_description: about,
      address: location,
      contact: contactNumber,
    };
    try {
      const response = await fetch(BASE_URL + UPDATE_DETAILS, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-USER-SESSION-ID": sessionId,
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
       navigate("/verification");
      } else {
        console.error("Profile Updation failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="editPageContainer">
      <form className="editForm" onSubmit={handleSubmit}>
        <div> {isLoading ? <LoadingSpinner/> : null } </div>
        <div className="editFormScrollableContent">
        <div>
          <label id="companyName">Company Name</label>
          <input
            type="text"
            placeholder="Company Name"
            id="companyinput"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div class="about-container">
          <label id="about">About Company</label>
          <textarea
            placeholder="About Company"
            id="aboutinput"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          ></textarea>
        </div>
        <div>
          <label id="location">Location</label>
          <textarea
            type="text"
            placeholder="Location"
            id="locationinput"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="contact-no-text-style">
          <label id="contact">Contact No:</label>
          <input
            type="tel"
            placeholder="Contact Number"
            id="contactinput"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
          />
        </div>
        <div className="email-text-style">
          <label id="email">Email:</label>
          <input
            type="email"
            placeholder="Email"
            id="emailinput"
            value={email}
          />
        </div>
        <div className="savebutton-wrapper">
          <button
            className={isButtonActive ? "active" : ""}
            disabled={!isButtonActive}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
        </div>
      </form>
    </div>
    
  );
}