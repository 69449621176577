import "../styling/Filter.css";
import ReactPaginate from 'react-paginate';
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { fetchItemsForBuyer } from "../api/buyerApis";
import { handleResponseError } from "../utils/handleError";
import { useSession } from "../contexts/SessionContext";
import Item from "../components/item/Item";
import LoadingSpinner from "../components/LoadingSpinner";
import Modal from "react-modal";
import FilterTab from "../components/buyer/FilterTab";
import { getUrlQueryParams, getUrlQueryParamsInArray, updateUrlQueryParams } from "../utils/urlHelper";

function Filter({}) {
  const [filters, setFilters] = useState(getUrlQueryParams());
  const [products, setProducts] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(getUrlQueryParamsInArray());

  const { screenSize } = useSession();
  const productsPerPage = screenSize == 'small' ? 12 : 12;
  const [currentPage, setCurrentPage] = useState(getUrlQueryParams().page ? ((parseInt(getUrlQueryParams().page))  > 0 ? parseInt(getUrlQueryParams().page) : 1) : 1);

  const [clearFilterSignal, setClearFilterSignal] = useState(0);
  const { category } = useParams();
  const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [isApplyButtonLoading, setIsApplyButtonLoading] = useState(false);

  const [shouldRunEffect, setShouldRunEffect] = useState(false);

  const handleItemClick = (selectedProduct) => {
    navigate('/categories/' + category + '/' + selectedProduct.id);
  };

  async function fetchProducts(category, filters) {
    try {
          setIsLoading(true);
          setIsApplyButtonLoading(true);
          setProducts([]);
          let searchQuery =
            `catogery_type=${category}&status=active&count=${productsPerPage}&page=` +
            currentPage;
          if (filters.length > 0) {
            for (let i = 0; i < filters.length; i++) {
              const filter = filters[i];
              if (filter.value !== "" && filter.value !== null) {
                if (filter.name != 'page') searchQuery += `&parameters[${i}][name]=${filter.name}&parameters[${i}][value]=${filter.value}`;
              }
            }
          }

          const data = await fetchItemsForBuyer(searchQuery);
          if (data.error != null) {
            return handleResponseError(data.error);
          }
          setProducts(data.items);
          setPageCount(Math.ceil(data.total / productsPerPage))
        } finally{
          setIsLoading(false);
          setIsApplyButtonLoading(false);
        }
  }

  useEffect(() => {
    let urlUpdateQuery = filters;
    urlUpdateQuery.page = currentPage;
    updateUrlQueryParams(urlUpdateQuery);
    
    fetchProducts(category, appliedFilters);
  }, [category, appliedFilters, currentPage]);

  useEffect(() => {
    if (shouldRunEffect) clearFilterHandler();
    else setShouldRunEffect(true);
  }, [category]);

  function applyFilterHandler(newFilters) {
    setCurrentPage(1);
    setIsFilterModalOpen(false);
    setFilters(newFilters);
    setAppliedFilters(Object.entries(newFilters).map(([name, value]) => ({ name, value })));
  }
  
  const handlePageClick = (data) => {
    setCurrentPage(parseInt(data.selected) + 1);
  };

  function clearFilterHandler() {
    setIsFilterModalOpen(false);
    setAppliedFilters({});
    setFilters({});
    setClearFilterSignal(clearFilterSignal + 1);
    setCurrentPage(1);
    updateUrlQueryParams({})
  }

  function handleFilterClick() {
    setIsFilterModalOpen(true);
  }

  return (
    <div className="filter-page-container">
      {screenSize == 'small' ? null : 
        <FilterTab appliedFilters={filters} isApplyButtonLoading={isApplyButtonLoading} category={category} clearFilterHandler={clearFilterHandler} clearFilterSignal={clearFilterSignal} applyFilterHandler={applyFilterHandler} />
      }

      <div className="filter-items-content">
        {screenSize == 'small' ? 
          <div>
            <Modal 
              isOpen={isFilterModalOpen}
              contentLabel="Popup"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  border: "none",
                  background: "none",
                  padding: 0,
                  marginLeft: "9px",
                  marginRight: "12px",
                  marginTop: "30px",
                  marginBottom: "10px",
                  borderRadius: "25px",
                  inset: "0px"
                },
              }}>
              <FilterTab appliedFilters={filters} isApplyButtonLoading={isApplyButtonLoading} setAppliedFilters={setFilters} category={category} clearFilterHandler={clearFilterHandler} clearFilterSignal={clearFilterSignal} applyFilterHandler={applyFilterHandler} />
            </Modal>
            <div className="filter-button-container">
              <div className="filter-button" onClick={handleFilterClick}>Filter</div>
            </div>
          </div> :
        <div className="filter-items-category">
          <text>{category}</text>
          
        </div>}
       
        {isLoading ? 
          <div className="FilterpageLoadingSpinner">
            <div> <LoadingSpinner/> </div>
          </div> :
          <div>
            <div className="filter-result-container" >
              <div className="filter-item-list-container">
              {products.length === 0 ? (
                    <div className="no-items">No items found</div>
                  ) : (
                    products.map((item) => (// Use the renamed itemsData here
                      <Item item={item} handleItemClick={handleItemClick} />
                  ))
                )
              }
              </div>
            </div>
            <div className="filter-pagination">
              <ReactPaginate
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination10"}
                activeClassName={"active"}
                previousClassName={"previous"}
                nextClassName={"next"}
                previousLabel={"<"}
                nextLabel={">"}
                forcePage={currentPage - 1}
                pageRangeDisplayed={3}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Filter;