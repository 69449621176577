import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { BackPress } from "../components/BackPress";
import { ImageView } from "../components/image/ImageView";
import ProductFillSlot from "../components/ProductFillSlot";
import { ImageLoader } from "../components/image/ImageLoader";
import DropDownOptionSet from "../components/DropDownOptionSet";
import LoadingWhite from "../images/LoadingWhite.gif";
import { ReactComponent as GalleryIcon } from "../images/galleryIcon.svg";
import { getDefaultsUrlsForCategory, getAllCatogeryProperitesOfRequests } from "../settings/SettingsHandler";
import { displayToast } from "../utils/toastHelper";
import { parseImagesJson } from "../utils/imageHelper";
import { handleResponseError } from "../utils/handleError";
import { uploadImage } from "../api/imageApis";
import { AddBuyerRequestItem } from "../api/buyerApis";
import { UpdateBuyerRequestItem } from '../api/buyerApis';
import '../styling/FillProduct.css';

const FillBuyerRequestedItemPage = ({ category, data = {}, handleBackPress, isEdit, itemId, updateFormProductData }) => {
  const [formData, setFormData] = useState(data);
  const [settings, setSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [priceError, setPriceError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [parameterError, setParameterError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [imageDisplayError, setImageDisplayError] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [productImages, setProductImages] = useState(data.images ? parseImagesJson(data.images) : []);
  const navigate = useNavigate();

  function handleFormUpdate(parameterKey, value) {
    if (value === "" || value === null) {
      if (formData.hasOwnProperty(parameterKey)) setFormData((prevFormData) => {
        const updatedNewFormData = { ...prevFormData };
        delete updatedNewFormData[parameterKey];
        return updatedNewFormData;
      });
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [parameterKey]: value,
      }));
    }
  }

  function handleFormParameterUpdate(parameterKey, value, um = null) {
    if (value === "" || value === null) {
      if (formData.hasOwnProperty("parameters")) {
        const newFormParameters = { ...formData.parameters };
        delete newFormParameters[parameterKey];
        setFormData((prevFormData) => ({
          ...prevFormData,
          parameters: newFormParameters,
        }));
      }
    } else {
      let newFormParameters = {}
      if (formData.hasOwnProperty("parameters")) newFormParameters = formData.parameters;

      newFormParameters[parameterKey] = {
        name: parameterKey,
        value: value,
        um: um
      };
      handleFormUpdate("parameters", newFormParameters);
    }
  }

  function handlePriceUpdate(parameterKey, value, um = "INR") {
    handleFormUpdate("price", value);
    handleFormUpdate("currency", um);
  }

  function handleQuantityUpdate(parameterKey, value, um = "kgs") {
    handleFormUpdate("quantity", value);
    handleFormUpdate("quantityUm", um);
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const imagePickHandler = async () => {
    if (isImageUploading) {
      displayToast("already another image upload is in progress. Please wait or cancel it.");
      return;
    }

    if (productImages.length === 4) {
      displayToast("you can upload only 4 images for a product.");
    } else {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      fileInput.onchange = async (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
          setIsImageUploading(true);
          const uploadResponse = await uploadImage(selectedImage, "item", setImageUploadProgress);
          setIsImageUploading(false);
          setImageUploadProgress(0);
          if (uploadResponse.error) {
            return handleResponseError(uploadResponse.error);
          }

          const imageUrl = uploadResponse.image_url;
          setProductImages((prevImages) => [...prevImages, imageUrl]);
        }
      };
      fileInput.click();
    }
    setImageDisplayError("");
  };

  const deleteImageHandler = (index) => {
    const updatedImages = productImages.filter((_, i) => i !== index);
    setProductImages(updatedImages);
  };

  useEffect(() => {
    (async () => {
      const globalSettings = await getAllCatogeryProperitesOfRequests();
      setSettings(globalSettings[category].add_request);

      setIsLoading(false);
    })();
  }, []);

  const goBack = () => {
    handleBackPress();
  };

  const previewClickHandler = async () => {
    if (!settings.parameters_fixed.price.is_optional && !formData.price) {
      setPriceError("price input is required"); return;
    }

    if (!settings.parameters_fixed.quantity.is_optional && !formData.quantity) {
      setQuantityError("quantity input is required"); return;
    }

    if (!settings.parameters_fixed.description.is_optional && !formData.description) {
      setDescriptionError("Description is required");
      return;
    }

    if (!settings.parameters_fixed.location.is_optional && !formData.location) {
      setLocationError("Location field is required");
      return;
    }

    if (!settings.parameters_fixed.images.is_optional && productImages.length === 0) {
      setImageDisplayError("images are required"); return;
    }

    let hasFormError = false;
    const parameterErrorsCopy = {};
    settings.parameters_default.forEach((parameter) => {
      if (!parameter.optional) {
        const paramName = parameter.name;
        const paramValue = formData.parameters?.[paramName]?.value;

        if (!paramValue || paramValue.trim() === '') {
          parameterErrorsCopy[paramName] = `${paramName} input is required`;
          hasFormError = true;
        }
      }
    });

    if (hasFormError) {
      setParameterError(parameterErrorsCopy);
      return;
    } else {
      setParameterError({});
    }

    let formatedParameters = [];
    if (formData.parameters != null) {
      const paramererKeys = Object.keys(formData.parameters);
      paramererKeys.forEach((key) => {
        formatedParameters.push(formData.parameters[key]);
      });
    }
    let previewData = JSON.parse(JSON.stringify(formData));
    previewData.parameters = formatedParameters;

    previewData.images = productImages;

    if (!previewData.images || previewData.images.length == 0) {
      previewData.images = await getDefaultsUrlsForCategory(category);
      setProductImages(previewData.images);
    }

    setFormData(previewData);
    let body = {
      catogery_type: formData.catogery_type,
      product_description: formData.description,
      price: formData.price,
      quantity: parseInt(formData.quantity),
      quantity_um: formData.quantityUm,
      images: productImages.length > 0 ? productImages : await getDefaultsUrlsForCategory(category),
      parameters: formatedParameters,
      location: formData.location
    };

    if (isEdit) {
      setIsSubmitButtonLoading(true);
      const updateResponse = await UpdateBuyerRequestItem(itemId, JSON.stringify(body));
      if (updateResponse.error) {
        setIsSubmitButtonLoading(false);
        return handleResponseError(updateResponse.error);
      } else {
        setIsSubmitButtonLoading(false);
        updateFormProductData(updateResponse);
        handleBackPress();
      }
    } else {
      setIsSubmitButtonLoading(true);
      const addProductResponse = await AddBuyerRequestItem(JSON.stringify(body));
      if (addProductResponse.error) {
        setIsSubmitButtonLoading(false);
        return handleResponseError(addProductResponse.error);
      } else {
        setIsSubmitButtonLoading(false);
        updateFormProductData(addProductResponse);
        navigate("/requested-item/" + addProductResponse.id, { state: { category, previewData } });
      }
    }
  };

  const goBackToSeller = () => {
    handleBackPress();
  };

  return (
    <div>
      {isLoading ? <></> :
        <div>
          <div className="modal-background-fillproduct" onClick={goBackToSeller}   >
            <div className="fill-product-container" onClick={(e) => e.stopPropagation()}>
              <div className="fill-product-content-container">
                <div className="fill-product-content-container-data">
                  <div className="fill-product-header">
                    <BackPress onClick={goBack} />
                    <div>
                      <div className="catogery-heading-text">{category}</div>
                    </div>
                  </div>

                  <div className="fill-product-body">
                    <div className="parameters-container">
                      {settings.parameters_default.map((parameter, index) => {
                        const value = formData.parameters != null && formData.parameters[parameter.name] != null ? formData.parameters[parameter.name].value : '';
                        const defaultUm = formData.parameters != null && formData.parameters[parameter.name] != null ? formData.parameters[parameter.name].um : parameter.default_um;
                        return (
                          parameter.input_type !== 'drop_down' ? (
                            <ProductFillSlot
                              defaultUm={defaultUm} dataType={parameter.data_type} value={value} supportedUm={parameter.supported_um} key={index} name={parameter.name} handleUpdate={handleFormParameterUpdate} isOptional={parameter.optional} error={parameterError[parameter.name] || ''} clearError={() => setParameterError((prevErrors) => ({ ...prevErrors, [parameter.name]: '' }))}
                            />
                          ) : (
                            <DropDownOptionSet key={index} value={value} parameter={parameter} handleUpdate={handleFormParameterUpdate} isOptional={parameter.optional} error={parameterError[parameter.name] || ''} clearError={() => setParameterError((prevErrors) => ({ ...prevErrors, [parameter.name]: '' }))} />
                          )
                        );
                      })}

                      {settings.parameters_fixed.price.visible ? <ProductFillSlot defaultUm={"INR"} name={"price"} supportedUm={["INR"]} handleUpdate={handlePriceUpdate} isOptional={settings.parameters_fixed.quantity.is_optional} value={formData.price != null ? formData.price + '' : ''} error={priceError} clearError={() => setPriceError('')} /> : null}
                      {settings.parameters_fixed.quantity.visible ? <ProductFillSlot defaultUm={formData.quantityUm != null ? formData.quantityUm : formData.categoryType === 'Sheet' ? 'kgs' : 'Nos'} name={"quantity"} supportedUm={["kgs", "Nos", "meter"]} handleUpdate={handleQuantityUpdate} isOptional={settings.parameters_fixed.quantity.is_optional} value={formData.quantity != null ? formData.quantity + '' : ''} error={quantityError} clearError={() => setQuantityError('')} /> : null}

                      {settings.parameters_fixed.images.visible ? <div className="image-container-style">
                        <text className="props-text-style" style={{ paddingLeft: '3px' }} >Product images {settings.parameters_fixed.images.is_optional ? null : '*'} </text>
                        <div className="image-handler-container">
                          <div className="selected-images-row" >
                            {productImages.map((image, index) => (
                              <ImageView url={image} removeHandler={() => deleteImageHandler(index)} />
                            ))}
                            {isImageUploading && <ImageLoader imageUploadProgress={imageUploadProgress} />}
                          </div>
                          <div className="gallery-style" onClick={imagePickHandler} style={{ cursor: 'pointer' }}>
                            <GalleryIcon />
                          </div>
                          {imageDisplayError && <div className="descrption-error-message">{imageDisplayError}</div>}
                        </div>
                      </div> : null}

                      {settings.parameters_fixed.description.visible ? (
                        <div className="propdescrption-style">
                          <div className="descrtiption-input-title">
                            <text className="props-text-style">
                              Description{settings.parameters_fixed.description.is_optional ? null : '*'}
                            </text>
                          </div>
                          <div className="description-text-container">
                            <textarea
                              type="text"
                              placeholder=""
                              className="containerdescrption-style"
                              value={formData.description != null ? formData.description : ''}
                              onChange={(event) => {
                                handleFormUpdate('description', event.target.value);
                                setDescriptionError("");
                              }}
                            />
                            {descriptionError && <div className="descrption-error-message">{descriptionError}</div>}
                          </div>
                        </div>
                      ) : null}

                      {settings.parameters_fixed.location.visible ? (
                        <div className="propdescrption-style">
                          <div className="descrtiption-input-title">
                            <text className="props-text-style">
                              Location{settings.parameters_fixed.location.is_optional ? null : '*'}
                            </text>
                          </div>
                          <div className="description-text-container">
                            <textarea
                              type="text"
                              placeholder=""
                              className="containerdescrption-style"
                              value={formData.location != null ? formData.location : ''}
                              onChange={(event) => {
                                handleFormUpdate('location', event.target.value);
                                setLocationError("");
                              }}
                            />
                            {locationError && <div className="descrption-error-message">{locationError}</div>}
                          </div>
                        </div>
                      ) : null}

                      <div className="preview-button-container">
                        <button className="request-button-container" onClick={() => previewClickHandler()}>
                          <text className="button-text-style">
                            {isSubmitButtonLoading ?
                              (
                                <img src={LoadingWhite} alt="Loading" />
                              ) : (
                                "Submit Request"
                              )
                            }
                          </text>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default FillBuyerRequestedItemPage;