import React from 'react';
import '../styling/ProductPreview.css'
import 'react-awesome-slider/dist/styles.css';
import '../styling/MySliderComponent.css';
import { useState } from 'react';
import DeleteHandler from './DeleteHandler';
import { useContext } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';
import { deleteProduct } from '../utils/deleteProduct';
import Modal from "react-modal";
import { AddProduct, UpdateProduct } from "../api/sellerApis";
import { handleResponseError } from "../utils/handleError";
import ProductDetailedView from '../components/item/ProductDetailedView';
import { useSession } from '../contexts/SessionContext';
import LoadingWhite from "../images/LoadingWhite.gif";

function ProductPreview({ images, id, isEdit, isDraft, formData, backHandler, onClose ,updateFormData }) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [isPublish, setIsPublish] = useState(true);
  const [isPublishButtonLoading, setIsPublishButtonLoading] = useState(false);
  const [isSaveDraftLoading, setIsSaveDraftLoading] = useState(false);
  const {screenSize} = useSession();

  const goBack = () => {
    backHandler();
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  async function productDraftHandler() {
    setIsLoading(true);
    setIsSaveDraftLoading(true);

    let body = JSON.stringify({
      catogery_type: formData.categoryType,
      product_description: formData.description,
      price: formData.price,
      save_as_draft: true,
      quantity: parseInt(formData.quantity),
      quantity_um: formData.quantityUm,
      images: images,
      parameters: formData.parameters,
    })

    if(!formData.id){
      const data = await AddProduct(body);
      if (data.error) return handleResponseError(data.error);
      updateFormData(data);
    } else {
      const data = await UpdateProduct(body, id);
      if (data.error) return handleResponseError(data.error);
      updateFormData(data);
    }

    setIsLoading(false);
    setIsSaveDraftLoading(true);
    onClose();
  };

  async function handlePublish() {
    setIsPublishButtonLoading(true);
    setIsLoading(true);
    if (isDraft) {
      await deleteProduct(id);
    }
    let imageArray = [];
    for (const img of images) {
      imageArray.push(img);
    }

    let body = JSON.stringify({
      catogery_type: formData.categoryType,
      product_description: formData.description,
      price: formData.price,
      quantity: parseInt(formData.quantity),
      quantity_um: formData.quantityUm,
      images: images,
      parameters: formData.parameters,
    });

    if (isEdit && !isDraft) {
      const data = await UpdateProduct(body,id);
      if (data.error) {
        setIsPublishButtonLoading(false);
        return handleResponseError(data.error);
      }
      formData = data;
      updateFormData(data);
      setIsLoading(false);
      setIsPublishButtonLoading(false);
    }
    else {
      const data = await AddProduct(body);
      if (data.error) {
        setIsPublishButtonLoading(false);
        return handleResponseError(data.error);
      }
      updateFormData(data);
      setIsLoading(false);
      setIsPublishButtonLoading(false);
    }
    setIsPublish(false);
    onClose();
  }

  function deleteBackHandler() {
    setIsDeleteModalOpen(false);
  }

  function ActionContainer() {
    return (
      <div className='preview-action-container-root'>
        <div className={`preview-action-container ${isPublishButtonLoading ? 'disable' : ''} ${isSaveDraftLoading ? 'disable' : ''}`}>
          <div className='preview-product-edit'  onClick={() => goBack()}>
            {
              screenSize != 'small' ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="36" viewBox="0 0 32 36" fill="none">
                  <path d="M30.083 11.2402L12.6508 33.7834C11.8507 34.8071 10.6718 35.3829 9.40856 35.4042L2.46094 35.4896C2.08198 35.4896 1.76618 35.2336 1.68197 34.8711L0.102967 28.0249C-0.170727 26.7666 0.102967 25.4657 0.902995 24.4633L13.2613 8.46766C13.4719 8.21173 13.8508 8.17121 14.1035 8.36102L19.3036 12.4986C19.6405 12.7758 20.1037 12.9251 20.5879 12.8611C21.6195 12.7332 22.3143 11.7947 22.209 10.7924C22.1458 10.2805 21.8932 9.85395 21.5564 9.53403C21.4511 9.44872 16.5035 5.48182 16.5035 5.48182C16.1877 5.22589 16.1246 4.75668 16.3772 4.4389L18.3352 1.8988C20.1458 -0.425892 23.3038 -0.639167 25.8512 1.38694L28.7777 3.71164C29.9777 4.65004 30.7777 5.88704 31.0514 7.18801C31.3672 8.61909 31.0304 10.0246 30.083 11.2402Z" fill="#FD9340" />
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 32 36" fill="none">
                  <path d="M30.083 11.2402L12.6508 33.7834C11.8507 34.8071 10.6718 35.3829 9.40856 35.4042L2.46094 35.4896C2.08198 35.4896 1.76618 35.2336 1.68197 34.8711L0.102967 28.0249C-0.170727 26.7666 0.102967 25.4657 0.902995 24.4633L13.2613 8.46766C13.4719 8.21173 13.8508 8.17121 14.1035 8.36102L19.3036 12.4986C19.6405 12.7758 20.1037 12.9251 20.5879 12.8611C21.6195 12.7332 22.3143 11.7947 22.209 10.7924C22.1458 10.2805 21.8932 9.85395 21.5564 9.53403C21.4511 9.44872 16.5035 5.48182 16.5035 5.48182C16.1877 5.22589 16.1246 4.75668 16.3772 4.4389L18.3352 1.8988C20.1458 -0.425892 23.3038 -0.639167 25.8512 1.38694L28.7777 3.71164C29.9777 4.65004 30.7777 5.88704 31.0514 7.18801C31.3672 8.61909 31.0304 10.0246 30.083 11.2402Z" fill="#FD9340" />
                </svg>
            }
          </div>
          <div>
            <button className='save-draft-style-m1' onClick={productDraftHandler}>
            {isSaveDraftLoading ? (
                <img src={LoadingWhite} alt="Loading" />
                    ) : (
                <text className='save-draft-text-style-m1' readOnly ></text>
              )}
            </button>
          </div>
          <div className='delete-container-style-one' onClick={handleDeleteClick}>
            {screenSize != 'small' ? 
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="39" viewBox="0 0 35 39" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.0383 6.83056C33.7678 6.83056 34.375 7.43618 34.375 8.2068V8.91929C34.375 9.67116 33.7678 10.2955 33.0383 10.2955H1.96348C1.23224 10.2955 0.625 9.67116 0.625 8.91929V8.2068C0.625 7.43618 1.23224 6.83056 1.96348 6.83056H7.43044C8.54097 6.83056 9.50744 6.0412 9.75727 4.92746L10.0436 3.64872C10.4885 1.90686 11.9528 0.75 13.6286 0.75H21.3714C23.029 0.75 24.5097 1.90686 24.9382 3.55685L25.2446 4.92558C25.4926 6.0412 26.459 6.83056 27.5714 6.83056H33.0383ZM30.2609 32.8763C30.8317 27.557 31.831 14.9196 31.831 14.7921C31.8675 14.4059 31.7416 14.0402 31.4918 13.7459C31.2237 13.4702 30.8846 13.3071 30.5107 13.3071H4.50348C4.12783 13.3071 3.77042 13.4702 3.52242 13.7459C3.27077 14.0402 3.14677 14.4059 3.165 14.7921C3.16835 14.8155 3.20421 15.2607 3.26416 16.0049C3.53047 19.311 4.27219 28.5191 4.75148 32.8763C5.09066 36.0863 7.19684 38.1038 10.2476 38.1769C12.6018 38.2313 15.0271 38.25 17.5071 38.25C19.8431 38.25 22.2155 38.2313 24.6426 38.1769C27.7991 38.1225 29.9035 36.1406 30.2609 32.8763Z" fill="#130F26" />
              </svg> : 
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 35 39" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.0383 6.83056C33.7678 6.83056 34.375 7.43618 34.375 8.2068V8.91929C34.375 9.67116 33.7678 10.2955 33.0383 10.2955H1.96348C1.23224 10.2955 0.625 9.67116 0.625 8.91929V8.2068C0.625 7.43618 1.23224 6.83056 1.96348 6.83056H7.43044C8.54097 6.83056 9.50744 6.0412 9.75727 4.92746L10.0436 3.64872C10.4885 1.90686 11.9528 0.75 13.6286 0.75H21.3714C23.029 0.75 24.5097 1.90686 24.9382 3.55685L25.2446 4.92558C25.4926 6.0412 26.459 6.83056 27.5714 6.83056H33.0383ZM30.2609 32.8763C30.8317 27.557 31.831 14.9196 31.831 14.7921C31.8675 14.4059 31.7416 14.0402 31.4918 13.7459C31.2237 13.4702 30.8846 13.3071 30.5107 13.3071H4.50348C4.12783 13.3071 3.77042 13.4702 3.52242 13.7459C3.27077 14.0402 3.14677 14.4059 3.165 14.7921C3.16835 14.8155 3.20421 15.2607 3.26416 16.0049C3.53047 19.311 4.27219 28.5191 4.75148 32.8763C5.09066 36.0863 7.19684 38.1038 10.2476 38.1769C12.6018 38.2313 15.0271 38.25 17.5071 38.25C19.8431 38.25 22.2155 38.2313 24.6426 38.1769C27.7991 38.1225 29.9035 36.1406 30.2609 32.8763Z" fill="#130F26" />
              </svg>
            }
          </div>
        </div>
      </div>
    );
  }

  function MainActionContainer() {
    return (
      <div className='preview-main-action-container'>
        <button className={`publish-button-style-m1 ${isSaveDraftLoading ? 'disable' : ''}`} onClick={handlePublish}>
          <text className='publish-text-style-m1' >
          {isPublishButtonLoading ?
           (
            <img src={LoadingWhite} alt="Loading" />
                ) : (
            <span>Publish</span>
            )
          }
          </text>
        </button>
      </div>
    );
  }

  return (
    <div className='product-preview-root'>
      <div className='product-preview-container'>
        <Modal
          isOpen={isDeleteModalOpen}
          contentLabel="Popup"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              border: "none",
              background: "none",
              padding: 0,
              margin: 0,
            },
          }}>
          <DeleteHandler id={formData.id} backHandler={deleteBackHandler} onClose={onClose}/>
        </Modal>

        <ProductDetailedView item={{
            catogery_type: formData.categoryType,
            product_description: formData.description,
            price: formData.price,
            save_as_draft: true,
            quantity: parseInt(formData.quantity),
            quantity_um: formData.quantityUm,
            images: images,
            parameters: formData.parameters,
          }} 
          SideActionContainer={ActionContainer} 
          MainActionContainer={MainActionContainer}
          backHandler={backHandler}
        />
      </div>
    </div>
  );
}

export default ProductPreview;