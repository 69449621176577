import { BASE_URL, CREATE_SUBSCRIPTION, FREE_TRAIL } from "../config";
import { getSessionId } from "../session/SessionHandler";
import { handleResponse } from "./responseHandler";

export async function createSubscription(body) {
  const response = await fetch(BASE_URL + CREATE_SUBSCRIPTION, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
    body: JSON.stringify(body)
  });

  return handleResponse(response);
}

export async function fetchSubscription(id) {
  const response = await fetch(BASE_URL + CREATE_SUBSCRIPTION + `/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}

export async function freeTrail(body) {
  const response = await fetch(BASE_URL + FREE_TRAIL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
    body: JSON.stringify(body)
  });

  return handleResponse(response);
}