// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buyerContainer{
    background-color: #F8F8F8;
}

.heroSection{
    height: 400px;
    width: 100%;
    background-size: cover;
    margin: 0;
}

.heroContent{
    height: 50%;
    width: 25%;
    border-radius: 25px;
    background-color: #FD9340;
    margin-left: 3.5%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heroContent h1{
    color: white;
    font-family: 'PoppinsBold';
    padding: 0;
    margin: 0;
    font-size: 40px;
}

.itemsHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.itemsHeader h1{
    padding: 0;
    margin: 0;
    margin-top: 13px;
    color: #FD9340;
    font-family: 'PoppinsBold';
    font-size: 25px;
}

.itemsHeader h6{
    padding: 0;
    margin: 0;
    margin-top: 7px;
    font-family: 'Poppins';
    font-size: 15px;
    padding-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/styling/buyerPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,UAAU;IACV,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,0BAA0B;IAC1B,UAAU;IACV,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,eAAe;IACf,sBAAsB;IACtB,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".buyerContainer{\n    background-color: #F8F8F8;\n}\n\n.heroSection{\n    height: 400px;\n    width: 100%;\n    background-size: cover;\n    margin: 0;\n}\n\n.heroContent{\n    height: 50%;\n    width: 25%;\n    border-radius: 25px;\n    background-color: #FD9340;\n    margin-left: 3.5%;\n    padding: 3%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.heroContent h1{\n    color: white;\n    font-family: 'PoppinsBold';\n    padding: 0;\n    margin: 0;\n    font-size: 40px;\n}\n\n.itemsHeader{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n}\n\n.itemsHeader h1{\n    padding: 0;\n    margin: 0;\n    margin-top: 13px;\n    color: #FD9340;\n    font-family: 'PoppinsBold';\n    font-size: 25px;\n}\n\n.itemsHeader h6{\n    padding: 0;\n    margin: 0;\n    margin-top: 7px;\n    font-family: 'Poppins';\n    font-size: 15px;\n    padding-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
