import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BackPress } from "../components/BackPress";
import BuyerRequests from "../components/BuyerRequests";
import LoadingSpinner from "../components/LoadingSpinner";
import BuyerCategorySelectPage from "./BuyerCategorySelectPage";
import { getAllCatogeriesOfRequests } from "../settings/SettingsHandler";
import { useSession } from "../contexts/SessionContext";
import { handleResponseError } from "../utils/handleError";
import { fetchBuyerRequestItem } from "../api/buyerApis";
import "../styling/BuyerRequestForm.css";

function BuyerRequestForm() {
  const itemsPerPage = 5;
  const navigate = useNavigate();
  const { screenSize } = useSession();
  const [type, setType] = useState("Active");
  const [category, setCategory] = useState([]);
  const [sliderKey, setSliderKey] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSliding, setIsSliding] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [requestedItems, setRequestedItems] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All");
  const [isActiveClicked, setIsActiveClicked] = useState(true);
  const [isClosedClicked, setIsClosedClicked] = useState(false);
  const [isSuccessClicked, setIsSuccessClicked] = useState(false);
  const [isMakeRequsetModalOpen, setIsMakeRequsetModalOpen] = useState(false);

  const handleMakeRequestAction = () => {
    setIsMakeRequsetModalOpen(true);
  };

  function handleMakeRequestModalClose() {
    setIsMakeRequsetModalOpen(false);
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setCurrentPage(0);
  };

  const updateRequestState = (type) => {
    setType(type);
    setActiveCategory("All");
    setCurrentPage(0);
    setSliderKey(sliderKey + 1);
  };

  const handleActiveClick = () => {
    setIsActiveClicked(true);
    setIsSuccessClicked(false);
    setIsClosedClicked(false);
    updateRequestState("Active");
  };

  const handleSuccessClick = () => {
    setIsActiveClicked(false);
    setIsSuccessClicked(true);
    setIsClosedClicked(false);
    updateRequestState("completed");
  };

  const handleCloseClick = () => {
    setIsActiveClicked(false);
    setIsSuccessClicked(false);
    setIsClosedClicked(true);
    updateRequestState("closed");
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const fetchBuyerRequestItems = async (url) => {
    const data = await fetchBuyerRequestItem(url);
    if (data.error) {
      handleResponseError(data.error);
      return null;
    }

    return data;
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const data = await fetchBuyerRequestItems(
          `request_items?&page=${
            currentPage + 1
          }&count=${itemsPerPage}&status=${type}${
            activeCategory !== "All" ? `&catogery_type=${activeCategory}` : ""
          }`
        );
        if (data && Array.isArray(data.items)) {
          setRequestedItems(data.items);
          setPageCount(Math.ceil(data.total / itemsPerPage));
        }
      } catch (error) {
        handleResponseError(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [currentPage, activeCategory, type]);

  useEffect(() => {
    (async () => {
      setCategory(await getAllCatogeriesOfRequests());
    })();
  }, []);

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    touchMove: true,
    touchThreshold: 100,
    speed: 300,
    variableWidth: true,
    beforeChange: () => {
      setIsSliding(true);
    },
    afterChange: () => {
      setIsSliding(false);
    },
  };

  const handleBuyerItemClick = (request) => {
    navigate("/requested-Item/" + request.id, {
      state: { category: request.catogery_type },
    });
  };

  const handleAddNewRequest = (newProduct) => {
    setRequestedItems([newProduct, ...requestedItems]);
  };

  return (
    <div>
      <div className="buyer-requestform-go-backContainer">
        <div className="favorite-back-press">
          <BackPress onClick={handleGoBack} />
        </div>
        <div className="request-header">Request Item</div>
      </div>
      <div className="buyer-request-form-container">
        <div className="main-request-heading">
          <text className="request-heading">Make a custom request</text>
        </div>
        <div className="make-request-button-container">
          <button
            className="make-request-button"
            onClick={handleMakeRequestAction}
          >
            Make request
          </button>
        </div>
      </div>
      <div className={screenSize === "small" ? "" : "horizontal-line"}></div>
      <div className="request-container">
        <div
          className={`active-button ${isActiveClicked ? "active" : ""}`}
          onClick={handleActiveClick}
        >
          Active {screenSize === "small" ? "" : "request"}
          {isActiveClicked && <div className="underline"></div>}
        </div>
        <div
          className={`success-button ${isSuccessClicked ? "active" : ""}`}
          onClick={handleSuccessClick}
        >
          Successful {screenSize === "small" ? "" : "request"}
          {isSuccessClicked && <div className="underline"></div>}
        </div>
        <div
          className={`close-button ${isClosedClicked ? "active" : ""}`}
          onClick={handleCloseClick}
        >
          Closed {screenSize === "small" ? "" : "request"}
          {isClosedClicked && <div className="underline"></div>}
        </div>
      </div>
      <div className="categories-row-container">
        <div className="categories-container">
          {category.length > 0 && (
            <Slider key={sliderKey} {...settings}>
              <div key="all">
                <button
                  onClick={() => handleCategoryClick("All")}
                  className={`category-styling ${
                    activeCategory === "All"
                      ? "active-category-styling"
                      : "other-category-styling"
                  }`}
                  disabled={isSliding}
                >
                  All
                </button>
              </div>
              {category.map((categoryItem, index) => (
                <div key={index}>
                  <button
                    onClick={() => handleCategoryClick(categoryItem)}
                    className={`category-styling ${
                      activeCategory === categoryItem
                        ? "active-category-styling"
                        : "other-category-styling"
                    }`}
                    disabled={isSliding}
                  >
                    {categoryItem}
                  </button>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="request-loader-container">
          <div className="request-content">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        </div>
      ) : requestedItems.length > 0 ? (
        <div>
          <div className="details-container">
            {requestedItems.map((request) => (
              <BuyerRequests
                request={request}
                key={request.id}
                handleBuyerItemClick={handleBuyerItemClick}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="request-loader-container">
          <div className="request-content">
            {activeCategory == "All" ? (
              <div>
                <div className="no-request-container">No {type} Requests. </div>
              </div>
            ) : (
              <div>
                <div className="no-request-container">
                  No {type} {activeCategory} Requests.
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="filter-pagination-for-seller-page">
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination10"}
          activeClassName={"active"}
          previousClassName={"previous"}
          nextClassName={"next"}
          previousLabel={"<"}
          nextLabel={">"}
          forcePage={currentPage}
          pageRangeDisplayed={2}
        />
      </div>

      <Modal
        isOpen={isMakeRequsetModalOpen}
        contentLabel="Popup"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}
      >
        <BuyerCategorySelectPage
          handleMakeRequestModalClose={handleMakeRequestModalClose}
          newProduct={handleAddNewRequest}
        />
      </Modal>
    </div>
  );
}

export default BuyerRequestForm;
