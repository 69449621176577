import {
  BASE_URL,
  PRODUCT_DETAILS,
  GET_BUYER_ITEM,
  FAVORITE_PRODUCTS,
  BUYER_REQUEST_ITEMS,
  APP_SETTINGS,
  GET_BUYER_REQUEST_ITEM,
} from "../config";
import { getSessionId } from "../session/SessionHandler";
import { handleResponse } from "./responseHandler";

export async function fetchItemsForBuyer(searchQuery) {
  const response = await fetch(BASE_URL + PRODUCT_DETAILS + searchQuery, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}

export async function getItemForBuyer(id) {
  const response = await fetch(BASE_URL + GET_BUYER_ITEM + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}
export async function removeFavourites(itemId) {
  const response = await fetch(BASE_URL + FAVORITE_PRODUCTS + `/${itemId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}

export async function addFavourites(itemId) {
  const response = await fetch(BASE_URL + FAVORITE_PRODUCTS + `/${itemId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}

export async function contactSeller(itemId) {
  const response = await fetch(
    BASE_URL + `api/v1/merchant/buyer/items/${itemId}/contact_seller`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-USER-SESSION-ID": getSessionId(),
      },
    }
  );

  return handleResponse(response);
}

export async function fetchBuyerRequestItem(url) {
  const response = await fetch(BASE_URL + BUYER_REQUEST_ITEMS + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}

export async function getAppSetting() {
  const response = await fetch(BASE_URL + APP_SETTINGS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}

export async function getbuyerRequestItem(itemId) {
  const response = await fetch(BASE_URL + GET_BUYER_REQUEST_ITEM + `${itemId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-USER-SESSION-ID": getSessionId(),
      },
    }
  );

  return handleResponse(response);
}

export async function MakeBuyerRequestSuccessfull(requestId) {
  const response = await fetch(BASE_URL + GET_BUYER_REQUEST_ITEM + `${requestId}/mark_success`, {
    method: 'PATCH',
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  })

  return handleResponse(response);
}

export async function MakeBuyerRequestClosed(requestId) {
  const response = await fetch(BASE_URL + GET_BUYER_REQUEST_ITEM + `${requestId}/mark_close`, {
    method: 'PATCH',
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  })

  return handleResponse(response);
}

export async function AddBuyerRequestItem(body) {
  const response = await fetch(BASE_URL + BUYER_REQUEST_ITEMS+'request_items', {
  method: "POST",
  headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
  },
  body: body
  })

  return handleResponse(response);
}

export async function UpdateBuyerRequestItem(itemId, body) {
  const response = await fetch(BASE_URL + GET_BUYER_REQUEST_ITEM + `${itemId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-USER-SESSION-ID": getSessionId(),
      },
      body: body
    })

  return handleResponse(response);
}
