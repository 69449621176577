import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./screens/Home";
import "../src/styling/fonts.css";
import { useState } from "react";
import MainPage from "./screens/user_auth/MainPage";
import WelcomeBack from "./screens/user_auth/WelcomeBack";
import SignUp from "./screens/user_auth/SignUp";
import ForgotPassword from "./screens/user_auth/ForgotPassword";
import ResetPassword from "./screens/user_auth/ResetPassword";
import VerifyMail from "./screens/user_auth/VerifyMail";
import VerifyEmail from "./screens/user_auth/VerifyEmail";
import SellerBuyerSignUp from "./screens/user_auth/SellerBuyerSignUp";
import VerificationPage from "./screens/user_auth/VerificationPage";
import Edit from "./screens/user_auth/Edit";
import { useSession } from "./contexts/SessionContext";
import Filter from "./screens/Filter";
import { LoggedInDefaultLayout } from "./components/layouts/LoggedInDefaultLayout";
import { BuyerLoggedInLayout } from "./components/layouts/BuyerLoggedInLayout";
import { FooterDefaultLayout } from "./components/layouts/FooterDefaultLayout";
import Product from "./screens/Product";
import Favorite from "./screens/favorite";
import NotificationPage from "./screens/notificationPage";
import ProfilePage from "./screens/ProfilePage";
import SellerProductView from "./screens/SellerProductView";
import Draft from "./screens/Draft";
import Inquiry from "./screens/Inquiry";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsandConditions";
import RefundPolicy from "./components/RefundPolicy";
import CustomProductDetails from "./components/CustomProductDetail";
import BuyerRequestForm from "./screens/BuyerRequestForm";
import RequestedItemDetailPage from "./screens/RequestedItemDetailPage";
import { BuyerLoggedInLayoutWithoutNavBar } from "./components/layouts/BuyerLoggedInLayoutWithoutNavBar";
import Notifications from "./services/Notifications";

function App() {
  const [welcomeBackModalIsOpen, setWelcomeBackModalIsOpen] = useState(false);
  const { sessionId, merchantStatus, billingStatus } = useSession();
  const { screenSize } = useSession();

  const closeWelcomeBackModal = () => {
    setWelcomeBackModalIsOpen(false);
  };

  const openWelcomeBackModal = () => {
    setWelcomeBackModalIsOpen(true);
  };

  function getBuyerComponents(Component) {
    return (
      <BuyerLoggedInLayout>
        <Component />
      </BuyerLoggedInLayout>
    );
  }

  function getBuyerComponentsWithoutNavBar(Component) {
    return (
      <BuyerLoggedInLayoutWithoutNavBar>
        <Component />
      </BuyerLoggedInLayoutWithoutNavBar>
    );
  }

  function getLoggedInComponents(Component) {
    return (
      <LoggedInDefaultLayout>
        <Component />
      </LoggedInDefaultLayout>
    );
  }

  function getFooterComponents(Component) {
    return (
      <FooterDefaultLayout>
        <Component />
      </FooterDefaultLayout>
    );
  }

  return (
    <Router>
      <div className="App">
        {sessionId &&
          merchantStatus === "active" &&
          billingStatus === "active" && <Notifications />}

        {welcomeBackModalIsOpen && (
          <WelcomeBack onClose={closeWelcomeBackModal} />
        )}
        <Routes>
          {sessionId &&
          merchantStatus === "active" &&
          billingStatus === "active" ? (
            <>
              <Route path="/" element={getLoggedInComponents(Home)} />
            </>
          ) : (
            <Route
              path="/"
              element={
                <MainPage onCloseWelcomeBack={openWelcomeBackModal}></MainPage>
              }
            />
          )}

          {sessionId &&
          merchantStatus === "active" &&
          billingStatus === "active" ? (
            <>
              <Route path="/" element={<Home />} />
            </>
          ) : (
            <>
              <Route
                path="/signup"
                element={<SignUp onCloseWelcomeBack={closeWelcomeBackModal} />}
              />
              <Route
                path="/login"
                element={
                  <WelcomeBack onCloseWelcomeBack={openWelcomeBackModal} />
                }
              />
              <Route
                path="/verify"
                element={
                  <ResetPassword onCloseWelcomeBack={openWelcomeBackModal} />
                }
              />
              <Route
                path="/verifyEmail"
                element={
                  <VerifyEmail onCloseWelcomeBack={openWelcomeBackModal} />
                }
              />
              <Route
                path="/forgotPw"
                element={
                  <ForgotPassword onCloseWelcomeBack={closeWelcomeBackModal} />
                }
              />
              <Route
                path="/verifyMail"
                element={
                  <VerifyMail onCloseWelcomeBack={openWelcomeBackModal} />
                }
              />
              <Route
                path="/sellerBuyerSignup"
                element={
                  <SellerBuyerSignUp
                    onCloseWelcomeBack={closeWelcomeBackModal}
                  />
                }
              />
              <Route
                path="/verification"
                element={
                  <VerificationPage
                    onCloseWelcomeBack={closeWelcomeBackModal}
                  />
                }
              />
            </>
          )}

          <Route
            path="/edit"
            element={<Edit onCloseWelcomeBack={closeWelcomeBackModal} />}
          />

          <Route
            path="/categories/:category"
            element={getBuyerComponents(Filter)}
          />

          <Route
            path="/categories/:category/:itemId"
            element={getBuyerComponents(Product)}
          />

          <Route
            path="/requested-item/:itemId"
            element={getBuyerComponentsWithoutNavBar(RequestedItemDetailPage)}
          />

          <Route
            path="/seller/items/:itemId"
            element={getLoggedInComponents(SellerProductView)}
          />

          <Route
            path="/notifications"
            element={getLoggedInComponents(NotificationPage)}
          />

          <Route path="/inquiry/:id" element={getLoggedInComponents(Inquiry)} />

          <Route path="/favorites" element={getLoggedInComponents(Favorite)} />

          <Route path="/profile" element={getLoggedInComponents(ProfilePage)} />

          <Route path="/draft" element={getLoggedInComponents(Draft)} />

          <Route path="/privacyPolicy" Component={PrivacyPolicy} />

          <Route path="/terms-conditions" Component={TermsAndConditions} />

          <Route path="/refund-policy" Component={RefundPolicy} />

          <Route path="/buyer-request/:id" Component={CustomProductDetails} />

          <Route path="*" element={<Navigate to="/" />} />

          <Route
            path="/buyer-request-form"
            element={
              screenSize === "small"
                ? getFooterComponents(BuyerRequestForm)
                : getLoggedInComponents(BuyerRequestForm)
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
