import { BASE_URL, COMMENTS, COMMENTS_REPLY } from "../config";
import { getSessionId } from "../session/SessionHandler";
import { handleResponse } from "./responseHandler";

export async function comments({ itemId, page = 1 }) {
  const response = await fetch(
    `${BASE_URL}${COMMENTS}/${itemId}?page=${page}&count=10`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-USER-SESSION-ID": getSessionId(),
      },
    }
  );
  return handleResponse(response);
}

export async function postComments({ message, itemId }) {
  const response = await fetch(BASE_URL + COMMENTS + `/${itemId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
    body: JSON.stringify({ message }),
  });

  return handleResponse(response);
}

export async function commentReplies({ itemId }) {
  const response = await fetch(BASE_URL + COMMENTS_REPLY + `/${itemId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}

export async function postReply({ message, itemId }) {
  const response = await fetch(BASE_URL + COMMENTS_REPLY + `/${itemId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
    body: JSON.stringify({ message }),
  });

  return handleResponse(response);
}
