import { useEffect, useCallback } from "react";
import { notificationToast } from "../utils/NotificationToast";
import { requestPermission, onMessageListener } from "./firebase";

function Notifications() {
  requestPermission();
  const handleNotification = useCallback((payload) => {
    notificationToast(
      payload.notification.body,
      payload.data.link + "/" + payload.data.entity_id,
      payload.data.entity_id,
      "success"
    );
  }, []);

  useEffect(() => {
    onMessageListener(handleNotification);
  }, [handleNotification]);

  return null;
}

export default Notifications;
