import React from 'react';
import { Link } from 'react-router-dom';
import { useSession } from '../../contexts/SessionContext';

import '../../styling/header/ProfileModal.css';

const ProfileModal = ({ isOpen, onProfileClick, onLogoutClick, onClose }) => {
  const { screenSize } = useSession();
  const linkTarget = screenSize === 'small' ? '_self' : '_blank';

  function handleProfileClick() {
    onProfileClick();
    onClose();
  }

  function handleLogoutClick() {
    onLogoutClick();
    onClose();
  }

  return (
    <div
      className={isOpen ? 'profile-container' : 'hidden'}
    >
      <div id='profile-content-container'>
        <div className='profile-content-item' onClick={handleProfileClick}>Profile</div>
        <div className='profile-content-item' onClick={handleLogoutClick}>Logout</div>
        <div className='profile-content-item'>
          <Link to="/privacyPolicy" target={linkTarget} className="link">
            Privacy
          </Link>
        </div>
        <div className='profile-content-item' >
          <Link to="/terms-conditions" target={linkTarget} className="link">
            Terms
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;