import React from 'react';
import '../styling/RefundPolicy.css'
import { useNavigate } from 'react-router-dom';

function RefundPolicy() {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className="refundpolicy-root-container-first">
      <div className="refundpolicy-root-container-second"></div>
      <div className="refundpolicy-root-container-third">
        <div className="mobile-back-button-container">
          <button className="main-button-container" tabIndex="0" type="button" aria-label="back" onClick={handleBackButtonClick}>
            <span className="span-div-style">
              <svg focusable="false" width="20" height="20" viewBox="0 0 24 24" aria-hidden="true" data-testid="ChevronLeftIcon">
                <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
              </svg>
            </span>
            <p className="backpress-text-style">Back</p>
            <span className="span-container-css"></span>
          </button>
        </div>

        <div className="main-root-container-refundpolicypage">
          <div className="root-container-refundpolicypage">
            <div className="compalinace-page">
              <div className="compalinace-content">
                <div className="content-container">
                  <p className="content-head">Refund and Cancellation Policy for Payments</p>
                  <div className="content-seprater"></div>
                  <p className="updated-date">Last updated on May 3rd 2024</p>
                  <p className="content-text">
                    <li className="list-item">
                      Please note that once you have subscribed to our services through the Uexcess website, we do not offer a refund. The subscription fee is non-refundable and non-transferable. 
                    </li>
                  </p>
                  <p className="content-text">
                    <li className="list-item">
                      This policy applies to all online transactions made through our website. By subscribing to our services, you are agreeing to this policy and accept its terms without exception.
                    </li>
                  </p>
                  <p className="content-text">
                    <li className="list-item">
                      We encourage our users to understand this policy thoroughly before making a payment. If you have any questions or concerns about this policy, please contact our customer service team.
                    </li>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundPolicy;
