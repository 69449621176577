import React, { useState, useEffect } from "react";
import "../styling/EntryFilter.css";

export default function EntryFilter({ addFilterHandler, value, parameter, clearFilterSignal }) {
  const [input, setInput] = useState(value == null ? '' : value);
  const [errorMessage, setErrorMessage] = useState("");
  
  const handleClearFilter = () => {
    setInput('');
    addFilterHandler(parameter.name, '');
  }

  useEffect(()=>{
    if (clearFilterSignal > 0) handleClearFilter();
  }, [clearFilterSignal]);

  useEffect(() => {
    setInput(value == null ? '' : value);
    setErrorMessage("");
  }, [value]);

  return (
    <div className="entry-container">
      <div className="entry-header">{parameter.name}{parameter.um ? "(" + parameter.um + ")" : null}</div>
      
      <div className="entry-value-container">
        <div className="value-text">Value</div>
        <div>
          <input
            type="text"
            inputMode={parameter.data_type == "float" ? "numeric" : "text"}
            className="entry-container-input"
            style={{ color: '#B3B1B0', textAlign: 'center' }}
            placeholder=""
            value={input}
            onChange={(event) => {
              const inputValue = event.target.value;
              setInput(inputValue);
              addFilterHandler(parameter.name, inputValue);
            }}
          />
          
          <p className="error-message">
            {errorMessage}
          </p>
        </div>
      </div>
    
    </div>
  );
};
