import "../../styling/SellerProfile.css";
import { useSession } from "../../contexts/SessionContext";
import SellerProfileCompanyLogo from "../../images/logo/companylogo.png";
import { ReactComponent as DefaultMerchantLogo } from "../../images/default_merchant_logo.svg";
import { ReactComponent as DefaultMerchantLogoLarge } from "../../images/default_merchant_logo_large.svg";
import { BackPress } from "../BackPress";

export const SellerProfile = ({ handleBackPress, merchant }) => {
  const { screenSize } = useSession();

  const renderLogo = () => {
    if (screenSize === "small") {
      return <DefaultMerchantLogo />;
    } else if (screenSize === "medium") {
      return <DefaultMerchantLogoLarge />;
    } else {
      return <DefaultMerchantLogoLarge />;
    }
  };

  return (
    <div className="buyer-profile-container" onClick={() => handleBackPress()}>
      <div
        className="buyer-profile-container-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="seller-profile-backpress">
          <BackPress onClick={handleBackPress} />
        </div>
        <div className="seller-background-image-container">
          <div className="buyer-background-image">
            <img
              className="buyer-background-image-logo"
              src={
                merchant.seller_background_image_url || SellerProfileCompanyLogo
              }
              alt="background"
            />
          </div>
          <div className="buyer-logo-image-container">
            {merchant.seller_profile_image_url ? (
              <img
                src={merchant.seller_profile_image_url}
                alt="logo"
                className="buyer-logo-image"
              />
            ) : (
              <div className="buyer-logo-image">{renderLogo()}</div>
            )}
          </div>
        </div>
        <div className="seller-company-detail-container">
          <div className="company-parameter-container">
            <div className="parameter-name-text">Company name</div>
            <div
              className="parameter-value-text"
              id="seller-profile-company-name-container"
            >
              {merchant.name}
            </div>
          </div>

          <div className="company-parameter-container">
            <div className="parameter-name-text">About company</div>
            <div
              className="parameter-value-text"
              id="seller-profile-description-container"
            >
              {merchant.seller_profile_description}
            </div>
          </div>

          <div className="company-parameter-container">
            <div className="parameter-name-text">Location</div>
            <div
              className="parameter-value-text"
              id="seller-profile-addres-container"
            >
              {merchant.address}
            </div>
          </div>

          <div className="company-contact-parameter-container">
            <div className="parameter-name-text parameter-name-contact">
              Contact No:
            </div>
            <div className="parameter-value-text parameter-contact-value-container">
              {merchant.contact}
            </div>
          </div>

          <div className="company-contact-parameter-container">
            <div className="parameter-name-text parameter-name-contact">
              Email:
            </div>
            <div className="parameter-value-text parameter-contact-value-container">
              {merchant.email}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
