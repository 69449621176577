import { getRelativeDate } from "../../utils/dateHelper";
import profile from '../../images/notification/profile .png';
import profileViewed from '../../images/notification/profile_viewed.png';
import { useState } from "react";

export function NotificationItem({notification, handleItemClick}) {
    const [isViewed, setIsViewed] = useState(notification.status == 'viewed');

    function getNotificationIcon() {
        return isViewed ? profileViewed : profile;
    }

    function onClick() {
        setIsViewed(true);
        handleItemClick(notification.id);
    }

    return (
        <div key={notification.id} className="row-container" onClick={onClick}>
            <img src={getNotificationIcon()} alt="notification" className='imgrow'/>
            <div  className="paragraphtextrow">{notification.description}</div>
            <div className='notification-date-container'>
                <div className='notificationdate'>
                    {getRelativeDate(notification.created_at)}
                </div>
            </div>
        </div>
    );
}