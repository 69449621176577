// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/header/profile_modal_background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
    position: absolute;
    top: 80px;
    right: 5%;
    z-index: 1;
    display: block;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 145px;
    height: 220px;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 5px;
}

.hidden {
    display: none;
}

#profile-content-container {
    margin-left: 21px;
    margin-top: 25px;
    /* background-color: red; */
    /* filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 5px;
}

.profile-content-item {
    font-size: 20px;
    font-family: Poppins;
    font-weight: 700;
    color: #B3B1B0;
    padding: 12px;
    cursor: pointer;
}

.link {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 768px) {
    .profile-container {
        top: 65px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styling/header/ProfileModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,UAAU;IACV,cAAc;IACd,yDAAuE;IACvE,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,2BAA2B;IAC3B,0DAA0D;IAC1D,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI;QACI,SAAS;IACb;AACJ","sourcesContent":[".profile-container {\n    position: absolute;\n    top: 80px;\n    right: 5%;\n    z-index: 1;\n    display: block;\n    background-image: url(../../images/header/profile_modal_background.svg);\n    width: 145px;\n    height: 220px;\n    overflow-x: auto;\n    overflow-y: hidden;\n    border-radius: 5px;\n}\n\n.hidden {\n    display: none;\n}\n\n#profile-content-container {\n    margin-left: 21px;\n    margin-top: 25px;\n    /* background-color: red; */\n    /* filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)); */\n    border-radius: 5px;\n}\n\n.profile-content-item {\n    font-size: 20px;\n    font-family: Poppins;\n    font-weight: 700;\n    color: #B3B1B0;\n    padding: 12px;\n    cursor: pointer;\n}\n\n.link {\n    color: inherit;\n    text-decoration: none;\n}\n\n@media (max-width: 768px) {\n    .profile-container {\n        top: 65px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
