import { BASE_URL } from "../config";
import { getSessionId } from "../session/SessionHandler";
import { compressImage } from "../utils/imageHelper";
import { xhrhandleResponse } from "./xhrResponseHandler";

export async function uploadImage(selectedFile, purpose, setProgress=null) {
    const compressedImage = await compressImage(selectedFile);

    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('image_file', compressedImage);
        formData.append('purpose', purpose);

        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (e) => {
            if (setProgress && e.lengthComputable) {
                const progress = (e.loaded / e.total);
                setProgress(progress.toFixed(2));
            }
        };

        xhr.open('POST', BASE_URL+'api/v1/merchant/images', true);

        xhr.setRequestHeader("X-USER-SESSION-ID", getSessionId());

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                resolve(xhrhandleResponse(xhr));
            }
        };

        xhr.send(formData);
    });
}