import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import '../styling/notification.css';
import { fetchNotifications } from '../api/merchantApis';
import { handleResponseError } from '../utils/handleError';
import { BackPress } from '../components/BackPress';
import { NotificationItem } from '../components/notification.js/NotificationItem';
import LoadingSpinner from "../components/LoadingSpinner";
import Inquiry from './Inquiry';
import Modal from "react-modal";

function NotificationPage({ }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedNotificationId, setSelectedNotificationId] = useState(-1);
  const [notifications, setNotifications] = useState([]);
  const notificationsPerPage = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    fetchNotification(currentPage + 1);
  }, [currentPage]);

  async function fetchNotification(page) {
    setIsLoading(true);
    const data = await fetchNotifications(page, notificationsPerPage);
    if (data.error) {
      handleResponseError(data.error);
    } else {
      setNotifications(data.items);
      setPageCount(data.total / notificationsPerPage);
      setIsLoading(false);
    }
  }

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleGoBackToHomePage = () => {
    window.history.back();
  };

  const handleItemClick = (selectedNotification) => {
    setSelectedNotificationId(selectedNotification.id);
  };

  function handleEnquiryBackPress() {
    setSelectedNotificationId(-1);
  }


  return (
    <div>
      <Modal
        isOpen={selectedNotificationId >= 0}
        contentLabel="Popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}> <Inquiry id={selectedNotificationId} backPressHandler={handleEnquiryBackPress} />
      </Modal>
      <div className='notification-root-container'>
        <div className='notification-header'>
          <BackPress onClick={handleGoBackToHomePage} />
          <div>
            <div className="notification-text">Notification</div>
          </div>
        </div>
        {isLoading ?
          <div style={{ width: '100%' }}>
            <div style={{ paddingTop: '10%', paddingBottom: '10%' }}> <LoadingSpinner /> </div>
          </div> :
          <div className='notification-list-container'>
            {notifications.map((notification, index) => (
              <NotificationItem notification={notification} handleItemClick={() => handleItemClick(notification)} key={index} />
            ))}
          </div>}
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination1"}
          activeClassName={"active"}
          previousClassName={"previous"}
          nextClassName={"next"}
          previousLabel={"<"}
          nextLabel={">"}
          forcePage={currentPage}
        />
      </div>
    </div>
  );
}

export default NotificationPage;
