import { BASE_URL, FAVORITE_PRODUCTS, MERCHANT, FETCH_NOTIFICATION } from "../config";
import { getSessionId } from "../session/SessionHandler";
import { handleResponse } from "./responseHandler";

export async function fetchNotifications(page, notificationsPerPage) {
    const response = await fetch(
        `${BASE_URL}${FETCH_NOTIFICATION}?page=${page}&count=${notificationsPerPage}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        },
    }
    );

    return handleResponse(response);
}

export async function getMerchant() {
    const response = await fetch(BASE_URL + MERCHANT, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        }
    });

    return handleResponse(response);
}

export async function fetchFavorites() {
    const response = await fetch(
        BASE_URL + FAVORITE_PRODUCTS,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-USER-SESSION-ID": getSessionId(),
            },
        }
    );

    return handleResponse(response);
}

export async function updateMerchant(data) {
    const response = await fetch(BASE_URL + MERCHANT, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        },
        body: JSON.stringify(data)
    });

    return handleResponse(response);
}