import React from "react";
import "../styling/Footer.css";
import { ReactComponent as Logo } from "../images/logo/Logo.svg";
import { useState, useEffect } from "react";

const ContactInfo = () => {
  return (
    <div className="contact-container">
      <div className="contact-information">
        <div className="headingtext-style-a2"> Contact Information</div>
        <div className="address-container">
          <div className="address-style-a2">
            NO927 13THCROSS, 4TH BLOCK HMT LAYOUT, Vidyaranyapura, Bangalore
            North, Bangalore- 560097, Karnataka
          </div>
        </div>
        <div className="contactcontainer-style-a2">
          <div className="contact-style-a2">Email: admin@uexcess.com</div>
        </div>
        <div className="contactcontainer-style-a2">
          <div className="contact-style-a2">Mobile number: +917975612424</div>
        </div>
      </div>
    </div>
  );
};

const FooteterLogo = () => {
  return (
    <div className="company-trademark">
      <div className="footer-image-container-a2">
        <Logo className="footer-image-a2" />
      </div>
    </div>
  );
};

const Footer = () => {
  const [screenSize, setScreenSize] = useState("medium");

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 768) {
        setScreenSize("small");
      } else if (screenWidth <= 1024) {
        setScreenSize("medium");
      } else {
        setScreenSize("large");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="root-footer-container">
      <div>
        {screenSize === "small" || screenSize === "medium" ? (
          <div className="footer-container">
            <FooteterLogo />
            <ContactInfo />
            <div className="dummy-container"></div>
          </div>
        ) : (
          <div className="footer-container">
            <ContactInfo />
            <FooteterLogo />
          </div>
        )}
      </div>
      <div style={{}}>
        <p className="para-style-a2">
          ©2023 Uexcess E Commerce Pvt ltd. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
