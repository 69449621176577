import React, { useState, useEffect, useCallback } from "react";
import ArrowLeft from "../images/ArrowLeft.png";
import "../styling/Draft.css";
import SellerProductView from "./SellerProductView";
import { useSession } from "../contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { getDraftItemsForSeller } from "../api/sellerApis";
import { handleResponseError } from "../utils/handleError";
import Item from "../components/item/Item";
import LoadingSpinner from "../components/LoadingSpinner";
import { BackPress } from "../components/BackPress";

const Draft = () => {
  const itemsPerRow = 6;
  const rowsPerPage = 2;
  const [draftItems, setDraftItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [displayProductView, setDisplayProductView] = useState(false);
  const { sessionId } = useSession();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchDraftProducts() {
    setIsLoading(true);
    const data = await getDraftItemsForSeller();
    if (data.error) return handleResponseError(data.error);
    setDraftItems(data.items);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDraftProducts();
  }, []);

  const itemsPerPage = itemsPerRow * rowsPerPage;
  const pageCount = Math.ceil(draftItems.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  let itemsToDisplay = draftItems.slice(startIndex, endIndex);

  const availableCards = draftItems.slice(startIndex);
  if (availableCards.length < itemsPerRow) {
    itemsToDisplay = availableCards;
  }

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const handleItemClick = (item) => {
    navigate('/seller/items/' + item.id);
  };

  const renderPagination = () => {
    if (pageCount <= 1) {
      return null;
    }
    const paginationItems = [];
    for (let i = 0; i < pageCount; i++) {
      if (
        i === currentPage ||
        i === currentPage - 1 ||
        i === currentPage + 1 ||
        i === 0 ||
        i === pageCount - 1
      ) {
        paginationItems.push(
          <span
            key={i}
            className={`page-number ${i === currentPage ? "active" : ""}`}
            onClick={() => handlePageClick(i)}
          >
            {i + 1}
          </span>
        );
      } else if (i === 1 || i === pageCount - 2) {
        paginationItems.push(
          <span key={i} className="ellipsis">
            ...
          </span>
        );
      }
    }
    return paginationItems;
  };

  return (
    <div>
      <div className="go-backSection">
        <BackPress onClick={handleGoBack} />
        <h1 className="goBackHeading">Saved Draft</h1>
      </div>
      {isLoading ? 
        <div style={{ width: '100%' }}>
          <div style={{ paddingTop: '5%', paddingBottom: '10%' }}> <LoadingSpinner/> </div>
        </div> :
        <div className="draft-item-result-container">
          <div className="draft-item-list-container">
            {itemsToDisplay.map((item) => (
              <Item item={item} handleItemClick={handleItemClick} />
            ))}
          </div>
        </div>
      }
      <div className="pagination">{renderPagination()}</div>
    </div>
  );
};

export default Draft;
