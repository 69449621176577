import React, { useState, useEffect } from "react";
import { getSettings } from "../../settings/SettingsHandler";
import EntryFilter from "../../components/EntryFilter";
import DropDownFilter from "../../components/DropDownFilter";
import LoadingWhite from "../../images/LoadingWhite.gif";

export default function FilterTab({ appliedFilters, category, clearFilterHandler, applyFilterHandler, clearFilterSignal, isApplyButtonLoading }) {
    const [parameters, setParameters] = useState([]);
    const [newFilters, setNewFilters] = useState(appliedFilters);
    const [applyButtonClicked, setApplyButtonClicked] = useState(false);
    const [clearFilterButtonClicked, setClearFilterButtonClicked] = useState(false);

    async function getFilters() {
        const setting = await getSettings();
        setParameters(setting.products[category].filters);
    }

    function addFilterHandler(parameterName, value) {
        const updatedNewFilters = { ...newFilters };
        if (value === "" || value === null) {
            delete updatedNewFilters[parameterName];
        } else {
            updatedNewFilters[parameterName] = value;
        }
        setNewFilters(updatedNewFilters);
    }

    function handleApplyFilterClick() {
        setApplyButtonClicked(true);
        setClearFilterButtonClicked(false);
        applyFilterHandler(newFilters);
    }

    function handleClearFilterClick() {
        setClearFilterButtonClicked(true);
        setApplyButtonClicked(false);
        clearFilterHandler();
    }

    useEffect(() => {
        getFilters();
    }, [category]);

    useEffect(() => {
        if (clearFilterSignal > 0) setNewFilters({});
    }, [clearFilterSignal]);

    return (
        <div className="filter-tab-container">
            <div className="filter-header">
                <div className="filter-text-container">
                    <text className="filter-text">Filter</text>
                </div>

                <div className="clearButton-container">
                    <button className={`clear-filter-container ${clearFilterButtonClicked && isApplyButtonLoading ? 'disableButton' : ''}`} style={{ backgroundColor: Object.keys(appliedFilters).length > 0 ? '#FD9340' : '#E5E4E3' }}
                        onClick={handleClearFilterClick}>
                        <text className="clear-filter-text" >Clear Filter</text>
                    </button>

                </div>
            </div>
            <div>
                {parameters.map((parameter, index) => {
                    const isLastParameter = index === parameters.length - 1;
                    return (
                        <React.Fragment key={index}>
                            {parameter.input_type !== "entry" ? (
                                <>
                                    <DropDownFilter
                                        parameter={parameter}
                                        addFilterHandler={addFilterHandler}
                                        um={parameter.default_um}
                                        clearFilterSignal={clearFilterSignal}
                                        value={appliedFilters[parameter.name] ? appliedFilters[parameter.name] : null}
                                    />
                                </>
                            ) : (
                                <>
                                    <EntryFilter
                                        um={parameter.default_um}
                                        key={index}
                                        parameter={parameter}
                                        addFilterHandler={addFilterHandler}
                                        clearFilterSignal={clearFilterSignal}
                                        value={appliedFilters[parameter.name] ? appliedFilters[parameter.name] : null}
                                    />
                                </>
                            )}
                            {/* Add the div element after the filter if not the last parameter */}
                            {!isLastParameter && (
                                <div
                                    style={{
                                        height: '2px',
                                        backgroundColor: '#d9d9d9',
                                        marginTop: '19px',
                                    }}
                                ></div>
                            )}
                        </React.Fragment>
                    );
                })}
                <div className="apply-button-container">
                    <button className={`apply-button ${applyButtonClicked && isApplyButtonLoading ? 'disableButton' : ''}`} onClick={handleApplyFilterClick}>
                        {applyButtonClicked && isApplyButtonLoading ? (
                            <img src={LoadingWhite} alt="Loading" />
                        ) : (
                            <span>Apply Filter</span>
                        )}
                    </button>
                </div>
            </div>
            <div>
            </div>
        </div>);
}