import React from 'react';
import { Link } from 'react-router-dom';
import '../styling/TermsandConditions.css';
import { useSession } from '../contexts/SessionContext';
import { useNavigate } from 'react-router-dom';

function TermsAndConditions() {
  const navigate = useNavigate();
  const { screenSize } = useSession();
  const linkTarget = screenSize === 'small' ? '_self' : '_blank';

  const handleBackButtonClick = () => {
    navigate('/signup');
  };

  return (
    <div className="termsandconditions-root-container-first">
      <div className="termsandconditions-root-container-second"></div>
      <div className="termsandconditions-root-container-third">
        <div className="mobile-back-button-container">
          <button className="main-button-container" tabIndex="0" type="button" aria-label="back" onClick={handleBackButtonClick}>
            <span className="span-div-style">
              <svg focusable="false" width="20" height="20" viewBox="0 0 24 24" aria-hidden="true" data-testid="ChevronLeftIcon">
                <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
              </svg>
            </span>
            <p className="backpress-text-style">Back</p>
            <span className="span-container-css"></span>
          </button>
        </div>

        <div className="main-root-container-termsandconditionspage">
          <div className="root-container-termsandconditionspage">
            <div className="compalinace-page">
              <div className="compalinace-content">
                <div className="content-container">
                  <p className="content-head">Terms &amp; Conditions</p>
                  <div className="content-seprater"></div>
                  <p className="updated-date">Last updated on March 28rd 2024</p>
                  <p className="content-text">
                    The Website Owner, including subsidiaries and affiliates (“Website” or
                    “Website Owner” or “we” or “us” or “our”) provides the information contained
                    on the website or any of the pages comprising the website (“website”) to
                    visitors (“visitors”) (cumulatively referred to as “you” or “your”
                    hereinafter) subject to the terms and conditions set out in these website
                    terms and conditions, the privacy policy and any other relevant terms and
                    conditions, policies and notices which may be applicable to a specific
                    section or module of the website.
                  </p>
                  <p className="content-text">
                    Welcome to our website. If you continue to browse and use this website you
                    are agreeing to comply with and be bound by the following terms and
                    conditions of use, which together with our privacy policy govern
                    UEXCESS E COMMERCE PRIVATE LIMITED''s relationship with you in
                    relation to this website.
                  </p>
                  <p className="content-text">
                    The term 'UEXCESS E COMMERCE PRIVATE LIMITED' or 'us' or 'we' refers
                    to the owner of the website whose registered/operational office is
                    NO927 13THCROSS, 4TH BLOCK HMT LAYOUT, Vidyaranyapura, Bangalore North, Bangalore- 560097, Karnataka. 
                    The term 'you' refers to the user or viewer of our website.
                  </p>
                  <p className="content-text">
                    <strong>The use of this website is subject to the following terms of use:</strong>
                  </p>
                  <ul className="unorder-list">
                    <li className="list-item">
                      <p className="content-text list-text">
                        The content of the pages of this website is for your general information
                        and use only. It is subject to change without notice.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        Neither we nor any third parties provide any warranty or guarantee as to
                        the accuracy, timeliness, performance, completeness or suitability of
                        the information and materials found or offered on this website for any
                        particular purpose. You acknowledge that such information and materials
                        may contain inaccuracies or errors and we expressly exclude liability
                        for any such inaccuracies or errors to the fullest extent permitted by
                        law.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        Your use of any information or materials on this website is entirely at
                        your own risk, for which we shall not be liable. It shall be your own
                        responsibility to ensure that any products, services or information
                        available through this website meet your specific requirements.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        This website contains material which is owned by or licensed to us. This
                        material includes, but is not limited to, the design, layout, look,
                        appearance and graphics. Reproduction is prohibited other than in
                        accordance with the copyright notice, which forms part of these terms
                        and conditions.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        All trademarks reproduced in this website which are not the property of,
                        or licensed to, the operator are acknowledged on the website.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        Unauthorized use of this website may give rise to a claim for damages
                        and/or be a criminal offense.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        From time to time this website may also include links to other websites.
                        These links are provided for your convenience to provide further
                        information.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        You may not create a link to this website from another website or
                        document without UEXCESS E COMMERCE PRIVATE LIMITED’s prior
                        written consent.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        Your use of this website and any dispute arising out of such use of the
                        website is subject to the laws of India or other regulatory authority.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        No Return or Refund Policy: By using our website and subscribing to our app, 
                        you acknowledge that all payments made for subscriptions are non-refundable. Once a subscription is purchased, no returns or refunds will be provided.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        Payment Gateway: We utilize a secure payment gateway for all transactions. 
                        Your payment details are encrypted and processed securely. However, we recommend that you review the terms and conditions of the payment gateway provider as well.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                      Shipping: We are a 3rd party where we just provide a platform for buyers and sellers so shipping is not applicable for us.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        Return & Refund: We do not offer any return or refunds for any of its products or services. All sales are final.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        Transactions should be conducted only in INR (Indian Rupees).
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">
                        Cancellation of subscriptions will not be available. 
                        <Link to="/refund-policy" target={linkTarget} className="link-refund">
                          click here for refund policy.
                        </Link>
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="content-text list-text">      
                        Authorization Limits: As a merchant, we shall not be liable for any loss or damage 
                        arising directly or indirectly due to the decline of authorization for any transaction. 
                        This may occur if the cardholder exceeds the preset limit mutually agreed upon by us and our acquiring bank.
                      </p>
                    </li>
                  </ul>
                  <p className="content-text">
                    We as a merchant shall be under no liability whatsoever in respect of any
                    loss or damage arising directly or indirectly out of the decline of
                    authorization for any Transaction, on Account of the Cardholder having
                    exceeded the preset limit mutually agreed by us with our acquiring bank from
                    time to time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
