import { BASE_URL, DRAFT_PRODUCTS, SELLER_ITEMS, FETCH_NOTIFICATION, GET_BUYER_REQUESTS, GET_BUYER_REQUEST_ITEM } from "../config";
import { getSessionId } from "../session/SessionHandler";
import { handleResponse } from "./responseHandler";

export async function getItemForSeller(id) {
    const response = await fetch(BASE_URL + SELLER_ITEMS + `/${id}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        },
    })

    return handleResponse(response);
}

export async function getItemsForSeller(filter) {
    const response = await fetch(BASE_URL + SELLER_ITEMS+"?" + filter, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        },
    })

    return handleResponse(response);
}

export async function getBuyerRequestItems(filter) {
  const response = await fetch(BASE_URL + GET_BUYER_REQUESTS + filter, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": getSessionId(),
    },
  });

  return handleResponse(response);
}

export async function deleteMerchantItem(itemId) {
    const response = await fetch(BASE_URL +SELLER_ITEMS + `/${itemId}`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        },
    })

    return handleResponse(response);
}

export async function getDraftItemsForSeller() {
    const response = await fetch(BASE_URL + DRAFT_PRODUCTS , {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        },
    })

    return handleResponse(response);
}

export async function getNotificationDetails(id) {
    const response = await fetch( BASE_URL + FETCH_NOTIFICATION+ `/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-SESSION-ID': getSessionId(),  
        },
    })

    return handleResponse(response);
}

export async function MakeMerchantItemSold(itemId) {
    const response = await fetch(BASE_URL +SELLER_ITEMS + `/${itemId}`, {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        },
        body: JSON.stringify({'status' :'sold'})
    })

    return handleResponse(response);
}

export async function MakeMerchantItemPublish(itemId) {
    const response = await fetch(BASE_URL +SELLER_ITEMS + `/${itemId}`, {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
        },
        body: JSON.stringify({'status' :'active'})
    })

    return handleResponse(response);
}

export async function AddProduct(body) {
    const response = await fetch(BASE_URL + SELLER_ITEMS, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        "X-USER-SESSION-ID": getSessionId(),
    },
    body: body
    })

    return handleResponse(response);
}

export async function UpdateProduct(body, id) {
    const response = await fetch(BASE_URL + SELLER_ITEMS+ '/' + id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-USER-SESSION-ID": getSessionId(),
        },
        body: body
      })

    return handleResponse(response);
}

export async function getbuyerRequestItem(itemId) {
  const response = await fetch(
    BASE_URL + GET_BUYER_REQUEST_ITEM + `${itemId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-USER-SESSION-ID": getSessionId(),
      },
    }
  );

  return handleResponse(response);
}

