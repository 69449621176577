import { BASE_URL, LOGOUT } from "../config";
import { handleResponse } from "./responseHandler";

export async function logout(sessionId) {
    const response = await fetch(BASE_URL + LOGOUT, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID":  sessionId
        },
    })

    return handleResponse(response);
}