// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageLoaderContainer {
    width: 96px;
    height: 96px;
    background-color: #D9D9D9;
    margin-right: 6px;
}

.image-loading-container {
    width: 92px;
    height: 5px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #FFF;
    margin-inline: 2px;
    margin-top: 88px
}

.image-upload-progress-bar {
    border-radius: 25px;
    background: #FD9340;
    height: 5px;
}

.cancel-load-container {
    width: 100%;
    display: flex;
    float: right;
    justify-content: flex-end;
}

.remove-icon {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/image/ImageLoader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB;AACJ;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".imageLoaderContainer {\n    width: 96px;\n    height: 96px;\n    background-color: #D9D9D9;\n    margin-right: 6px;\n}\n\n.image-loading-container {\n    width: 92px;\n    height: 5px;\n    flex-shrink: 0;\n    border-radius: 25px;\n    background: #FFF;\n    margin-inline: 2px;\n    margin-top: 88px\n}\n\n.image-upload-progress-bar {\n    border-radius: 25px;\n    background: #FD9340;\n    height: 5px;\n}\n\n.cancel-load-container {\n    width: 100%;\n    display: flex;\n    float: right;\n    justify-content: flex-end;\n}\n\n.remove-icon {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
