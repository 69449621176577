import React from "react";
import { useState, useRef } from "react";
import "../../styling/WelcomeBack.css";
import Scrap from "../../images/Scrap.png";
import Logo from "../../images/logo/Logo.svg";
import StarGif from "../../images/StarGif.gif";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../contexts/SessionContext";
import { getSessionId, setSessionId } from "../../session/SessionHandler";
import { login } from "../../api/login";
import LoadingWhite from "../../images/LoadingWhite.gif";
import { BASE_URL } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function WelcomeBack() {
  const { updateSessionId } = useSession();
  const navigate = useNavigate();
  const passwordInputRef = useRef(null);
  const getStartedButtonRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logginError, setlogginError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleEmailKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      passwordInputRef.current.focus();
    }
  };

  const handlePasswordKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getStartedButtonRef.current.click();
    }
  };

  const isButtonActive = email.trim() !== "" && password.trim() !== "";

  const loginHandler = async () => {
    setIsLoading(true);

    const apiData = await login(email, password);
    const data = {
      ...apiData,
      is_first_login: false,
    };

    setIsLoading(false);

    if (data.error != null) {
      setlogginError(data.error.description);
      return;
    }

    await setSessionId(data.session_id);
    updateSessionId(data.session_id);

    if (data.is_first_login) {
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        handleLoginSuccess(data);
      }, 2000);
    } else {
      handleLoginSuccess(data);
    }
  };

  async function handleLoginSuccess(data) {
    if (
      data.merchant_status === "deactivated" ||
      data.merchant_status === "in_review" ||
      (data.merchant_status === "active" && data.billing_status === "expired")
    ) {
      navigate("/verification", { state: { merchantId: data.merchant_id } });
    } else if (data.merchant_status === "afa_pending") {
      await otpTriggerHandler();
    } else {
      navigate("/");
    }
  }

  async function otpTriggerHandler() {
    try {
      setIsLoading(true);
      const response = await fetch(
        BASE_URL + "api/v1/authentication/merchant/user/send_register_otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getSessionId(),
          },
        }
      );
      setIsLoading(false);

      if (response.ok) {
        const data = await response.json();
        navigate("/verifyMail", {
          state: {
            otp_id: data.otp_id,
            session_id: getSessionId(),
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {showSuccessModal ? (
        <div className="success-container">
          <div className="containerColor">
            <p className="sucessText">Welcome to</p>
            <img src={Logo} alt="Company logo" className="sucessLogo" />
            <p className="success-message">
              A platform to Buy/Sell your excess Raw material
            </p>
            <img src={StarGif} alt="Star" className="star" />
            <img src={StarGif} alt="Star" className="stars" />
          </div>
        </div>
      ) : (
        <div className="loginpage-container">
          <div className="login-content">
            <div className="image-container-new">
              <img
                src={Scrap}
                alt="Company logo"
                className="welcomeScrap-image"
              />
              <img
                src={Logo}
                alt="Company logo"
                className="welcomelogo-image"
              />
            </div>
            <div className="login-form-container">
              <div className="welcomeForm">
                <div className="welcommingText">
                  <h2 className="helloText">Hello,</h2>
                  <h2 className="welcomeText">Welcome Back!</h2>
                </div>
                <form className="loginpageformcontents">
                  <input
                    type="email"
                    placeholder="Email"
                    className="login-email-container"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyPress={handleEmailKeyPress}
                  />

                  <div
                    style={{ position: "relative" }}
                    className="login-password-container"
                  >
                    <input
                      type={passwordVisible ? "text" : "password"}
                      ref={passwordInputRef}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={handlePasswordKeyPress}
                    />
                    <FontAwesomeIcon
                      icon={!passwordVisible ? faEyeSlash : faEye}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className="loginpage-eye-icon"
                    />
                  </div>
                  <p className="forgot-password-text-style">
                    <Link
                      to="/forgotPW"
                      style={{ color: "#ff4d4f" }}
                      className="forgot-password"
                    >
                      Forgot Password?
                    </Link>
                  </p>
                  {logginError && (
                    <div className="logginError">{logginError}</div>
                  )}
                  <button
                    type="button"
                    className={`getStartedButton ${
                      isButtonActive ? "active" : ""
                    }`}
                    ref={getStartedButtonRef}
                    disabled={!isButtonActive}
                    onClick={loginHandler}
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      cursor: isButtonActive ? "pointer" : "default",
                    }}
                  >
                    {isLoading ? (
                      <>
                        <img
                          src={LoadingWhite}
                          alt="Loading"
                          className="getstarted-loading-inlogin"
                        />
                      </>
                    ) : (
                      "Get Started"
                    )}
                  </button>
                  <p className="create-account-text-login">
                    Don't have an account?{" "}
                    <Link to="/signup" className="create-account-link">
                      <span className="spantext">Create Account</span>
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
