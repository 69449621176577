import React, { useState } from 'react';
import '../styling/ProfilePage.css'
import EditProfileIcon from '../components/EditProfileIcon';
import { handleResponseError } from '../utils/handleError';
import { useEffect } from 'react';
import { getImageUrl } from '../utils/getImageUrl';
import CompanyLogoNew from "../images/logo/companylogo.png"
import { getMerchant, updateMerchant } from '../api/merchantApis';
import { getSessionId } from '../session/SessionHandler';
import { useSession } from "../contexts/SessionContext";
import LoadingSpinner from "../components/LoadingSpinner";
import EditProfileIconSmall from '../components/EditProfileIconSmall';
import LoadingWhite from "../images/LoadingWhite.gif";
import { uploadImage } from '../api/imageApis';

function ProfilePage() {
  const { screenSize } = useSession();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [merchantData, setMerchantData] = useState({});
  const [editMerchantData, setEditMerchantData] = useState({});
  const [ isLoading, setIsLoading ] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [onUpdateError, setOnUpdateError] = useState("");
  const { setMerchantLogo } = useSession();

  async function fetchDetails() {
    setIsLoading(true)
    const data = await getMerchant();

    if (data.error) {
      handleResponseError(data.error);
    } else {
      setMerchantData(data);
    }
    setIsLoading(false);
  }
 
  const updateDetails = async (details) => {
      const data = await updateMerchant(details);
      if (data.error) {
        handleResponseError(data.error);

        return data.error;
      }
      
      if (!data.error) {
        setMerchantData(data);

        return null;
      }
  };

  const saveBtnHandler = async () => {
    setIsButtonLoading(true);

    let details = {};
    editMerchantData.name && (details.name = editMerchantData.name);
    editMerchantData.address && (details.address = editMerchantData.address);

    editMerchantData.contact && (details.contact = editMerchantData.contact);
    editMerchantData.seller_profile_description && (details.seller_profile_description = editMerchantData.seller_profile_description);
      
    var updateVariable = await updateDetails(details);

    setIsButtonLoading(false);

    if (updateVariable === null) {
      setIsModalOpen(false);
    }
    else {
      setOnUpdateError(updateVariable.description);
    }
  };

  async function imagePickHandler(type) {
    try {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';

      fileInput.addEventListener('change', async (event) => {
        const file = event.target.files[0];
        if (file) {
          if (type === 'Banner') {
            const uploadResponse = await uploadImage(file, 'merchant_seller_background');
            if (uploadResponse.error) {
              return handleResponseError(uploadResponse.error);
            }

            const bgUrl = uploadResponse.image_url;

            await updateDetails({ seller_background_image_url: bgUrl });
          } else {
            const uploadResponse = await uploadImage(file, 'merchant_seller_logo');
            if (uploadResponse.error) {
              return handleResponseError(uploadResponse.error);
            }

            const profUrl = uploadResponse.image_url;

            await updateDetails({ seller_profile_image_url: profUrl });

            setMerchantLogo(profUrl);
          }
        }
      });

      fileInput.click();
    } catch (error) {
      console.error('Error selecting image:', error);
    }
  }

  function handleEdit() {
    setEditMerchantData(merchantData);
    setOnUpdateError("");
    setIsModalOpen(true);
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div  >
      {isLoading ?
        <div style={{ width: '100%' }}>
          <div style={{ paddingTop: '10%', paddingBottom: '10%' }}> <LoadingSpinner /> </div>
        </div> :
        <div>
          <div className="seller-profile-container" >
            <div className="seller-profile-container-content">
              <div className='profile-images-container'>
                <div className='profile-background-image-container'>
                  <img
                    className="profile-background-image"
                    src={merchantData.seller_background_image_url || CompanyLogoNew}
                    alt='background'
                  />
                  
                  <div className='edit-background-image-icon'>
                    <EditProfileIcon onClick={() => imagePickHandler('Banner')} />
                  </div>
                </div>

                <div className="seller-logo-image">
                  {merchantData.seller_profile_image_url ? (
                    <img
                      src={merchantData.seller_profile_image_url}
                      alt="logo"
                      className="logo-image"
                    />
                  ) : (
                    <div className="renderLogo-style"><div className='addlogo-text-style'></div></div>
                  )}
                </div>

                <div className='edit-profile-image'>
                  {screenSize === 'small' ? (
                    <EditProfileIconSmall onClick={() => imagePickHandler('profile')} />
                  ) : (
                    <EditProfileIcon onClick={() => imagePickHandler('profile')} />
                  )}
                </div>
              </div>

              <div className="company-detail-container">
                <div className="company-parameter-container">
                  <button className="svg-button-a1" onClick={() => handleEdit()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 37" fill="none" >
                      <path d="M30.3056 11.8146L12.8734 34.3577C12.0734 35.3814 10.8944 35.9573 9.63121 35.9786L2.6836 36.0639C2.30464 36.0639 1.98884 35.808 1.90463 35.4454L0.325623 28.5993C0.051929 27.341 0.325623 26.04 1.12565 25.0376L13.484 9.042C13.6945 8.78607 14.0735 8.74555 14.3261 8.93536L19.5263 13.0729C19.8632 13.3501 20.3263 13.4994 20.8106 13.4355C21.8422 13.3075 22.5369 12.3691 22.4317 11.3667C22.3685 10.8548 22.1159 10.4283 21.779 10.1084C21.6737 10.0231 16.7262 6.05616 16.7262 6.05616C16.4104 5.80023 16.3472 5.33102 16.5999 5.01324L18.5578 2.47314C20.3684 0.148449 23.5264 -0.0648259 26.0739 1.96128L29.0003 4.28598C30.2004 5.22439 31.0004 6.46138 31.2741 7.76235C31.5899 9.19343 31.253 10.5989 30.3056 11.8146Z" fill="#FD9340" />
                    </svg>
                  </button>
                  <div className="parameter-name">Company name</div>
                  <div className='parameter-value-container' id="parameter-company-name-container">
                    {merchantData.name || 'Company Name'}
                  </div>
                </div>

                <div className="company-parameter-container">
                  <div className="parameter-name">About company</div>
                  <div className='parameter-value-container' id="parameter-descrption-container">
                    {merchantData.seller_profile_description || 'About Company'}
                  </div>
                </div>

                <div className="company-parameter-container">
                  <div className="parameter-name">Location</div>
                  <div className='parameter-value-container' id="parameter-address-value-container">
                    {merchantData.address || 'Location'}
                  </div>
                </div>

                <div className="company-contact-parameter-container">
                  <div className="parameter-name parameter-name-contact">Contact No:</div>
                  <div className='parameter-value-container parameter-contact-value-container'>
                    {merchantData.contact || 'Contact No'}
                  </div>
                </div>

                <div className="company-contact-parameter-container">
                  <div className="parameter-name parameter-name-contact">Email:</div>
                  <div className='parameter-value-container parameter-contact-value-container'>
                    {merchantData.email || 'Email'}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isModalOpen && (

            <div className={`modal-background-a1 ${isButtonLoading ? 'unclickable' : ''}`} onClick={() => setIsModalOpen()}>
              <div className="modal-content-a1"  >
                <div className='main-container-a1' onClick={(e) => e.stopPropagation()}>
                  <div >
                    <div className="infoTitle-a1" id='company-name-title'>Company Name</div>
                  </div>
                  <input
                    value={editMerchantData.name}
                    className="infoText-a1"
                    id='company-name-value'
                    onChange={(e) => setEditMerchantData({ ...editMerchantData, name: e.target.value })}
                    style={{outline: "none"}}
                    onFocus={(e)=>e.target.style.outline="1px solid orange"}
                    onBlur={(e)=>e.target.style.outline="none"}
                  />
                  <div className="row-container-a1">
                    <div className="infoTitle-a1" id='about-company-title'>{'About Company'}</div>
                  </div>
                  <textarea
                    value={editMerchantData.seller_profile_description}
                    className="infoText-a1"
                    id='about-company-value'
                    onChange={(e) => setEditMerchantData({ ...editMerchantData, seller_profile_description: e.target.value })}
                    style={{outline: "none"}}
                    onFocus={(e)=>e.target.style.outline="1px solid orange"}
                    onBlur={(e)=>e.target.style.outline="none"}
                  />
                  <div className="infoTitle-a1" id='location-title'>Location</div>
                  <textarea
                    value={editMerchantData.address}
                    className="infoText-a1"
                    id='location-value'
                    onChange={(e) => setEditMerchantData({ ...editMerchantData, address: e.target.value })}
                    style={{outline: "none"}}
                    onFocus={(e)=>e.target.style.outline="1px solid orange"}
                    onBlur={(e)=>e.target.style.outline="none"}
                  />
                  <div className="newrow-container-a1">
                    <div className="infoTitle-a1" id='contact-no-title' >{'Contact No:'}</div>
                    <input
                      value={editMerchantData.contact}
                      className="infoText-a1"
                      onChange={(e) => setEditMerchantData({ ...editMerchantData, contact: e.target.value })}
                      id='contact-no-value'
                      style={{outline: "none"}}
                      onFocus={(e)=>e.target.style.outline="1px solid orange"}
                      onBlur={(e)=>e.target.style.outline="none"}
                    />
                  </div>
                  <div className="newrow-container-a1" >
                    <div className="infoTitle-a1" id='email-title'>{'Email:'}</div>
                    <input
                      value={editMerchantData.email}
                      className="infoText-a1"
                      id='email-value'
                    />
                  </div>
                  <div className='profile-update-button-container'>
                    <button
                      onClick={saveBtnHandler}
                      className={`save-button-a1 ${isButtonLoading ? 'loading' : ''}`}
                    >
                      {isButtonLoading ? (
                        <img src={LoadingWhite} alt="Loading" />
                          ) : (
                        <span className="infoTitleSave-a1" id='button-text'></span>
                      )}
                    </button>
                  </div>
                  {onUpdateError && (
                    <div className="updateError">{onUpdateError}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default ProfilePage;
