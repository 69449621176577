import { BASE_URL, MERCHANT_LOGIN, FORGOT_PASSWORD, UPDATE_PASSWORD} from "../config";
import { handleResponse } from "./responseHandler";
import { getSessionId } from "../session/SessionHandler";

export async function login(email, password) {
    const response = await fetch(BASE_URL + MERCHANT_LOGIN, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            password: password,
        }),
    });

    return handleResponse(response);
}

export async function forgotPassword(email) {
    const response = await fetch(BASE_URL + FORGOT_PASSWORD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
    });

    return handleResponse(response);
}

export async function resetPassword(newPassword, sessionId) {
    const response = await fetch(BASE_URL + UPDATE_PASSWORD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-USER-SESSION-ID": getSessionId(),
        },
        body: JSON.stringify({
          password: newPassword,
        }),
    });

    return handleResponse(response);
}