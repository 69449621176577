import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { BackPress } from '../components/BackPress';
import SliderImage from '../components/SliderImage';
import LoadingWhite from "../images/LoadingWhite.gif";
import LoadingSpinner from '../components/LoadingSpinner';
import { ReactComponent as EditButton } from "../images/edit.svg"
import FillBuyerRequestedItemPage from './FillBuyerRequestedItemPage';
import { ReactComponent as EditButtonSmall } from "../images/edit_small.svg"
import { useSession } from '../contexts/SessionContext';
import { isNumber } from '../utils/numberHelper';
import { parseImagesJson } from '../utils/imageHelper';
import { handleResponseError } from '../utils/handleError';
import { getbuyerRequestItem, MakeBuyerRequestSuccessfull, MakeBuyerRequestClosed } from "../api/buyerApis";
import "../styling/RequestedItemDetailPage.css";

const RequestedItemDetailPage = () => {
    const { itemId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isFirstRender = useRef(true);
    const { screenSize } = useSession();
    const [item, setItem] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [formdata, setFormData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCloseButtonLoading, setIsCloseButtonLoading] = useState(false);
    const [isFillProductVisible, setIsFillProductVisible] = useState(false);
    const [isSuccessButtonLoading, setIsSuccessButtonLoading] = useState(false);
    const category = location.state?.category || {};

    const goBack = () => {
        window.history.back();
        return;
    }

    const handleEditProduct = () => {
        setIsFillProductVisible(true);
        let parameters = {}
        if (item.parameters != null) {
            item.parameters.forEach((parameter, _) => {
                parameters[parameter.name] = parameter;
            });
        }

        setFormData({
            id: item.id,
            price: item.price,
            quantity: item.quantity,
            quantityUm: item.quantity_um,
            catogery_type: item.catogery_type,
            description: item.product_description,
            parameters: parameters,
            images: parseImagesJson(item.images),
            location: item.location
        });
        setIsEdit(true);
    };

    const handleFormUpdate = (newFormData) => {
        const updatedProduct = {
            ...item,
            price: newFormData.price,
            quantity: newFormData.quantity,
            quantityUm: newFormData.quantity_um,
            parameters: newFormData.parameters,
            images: newFormData.images,
            product_description: newFormData.product_description,
            location: newFormData.location,
            status: newFormData.status,
        };

        setItem(updatedProduct);
    };

    useEffect(() => {
        async function fetchAndStoreCategories() {
            const items = await getbuyerRequestItem(itemId);
            setItem(items);
            setIsLoading(false);
        }

        if (isLoading && isFirstRender.current) {
            fetchAndStoreCategories();
            isFirstRender.current = false;
        }
    }, [itemId]);

    const handleSuccessRequest = async () => {
        const requestId = item.id;
        setIsSuccessButtonLoading(true);
        const response = await MakeBuyerRequestSuccessfull(requestId);

        if (response.error) {
            setIsSuccessButtonLoading(false);
            return handleResponseError(response.error);
        }

        setItem(response);
        setIsSuccessButtonLoading(false);
        navigate("/buyer-request-form");
        return;
    };

    const handleClosedRequest = async () => {
        const requestId = item.id;
        setIsCloseButtonLoading(true);
        const response = await MakeBuyerRequestClosed(requestId);

        if (response.error) {
            setIsCloseButtonLoading(false);
            return handleResponseError(response.error);
        }

        setItem(response);
        setIsCloseButtonLoading(false);
        navigate("/buyer-request-form");
    };

    function handleEditBack() {
        setIsFillProductVisible(false);
    }

    function ActionContainer() {
        return (
            <div className='requested-item-feature-edit' onClick={() => handleEditProduct()}>
                {(item.status === 'active') && (
                    screenSize != 'small' ? <EditButton /> : <EditButtonSmall />)}
            </div>
        )
    }

    return (
        <div className='requested-item-page' >
            {isLoading ? (
                <div className='loading-spinner-container'>
                    {""}
                    <LoadingSpinner />{""}
                </div>
            ) : (
                <div className='requested-item-detail-container' >
                    <div className='requested-item-container-header'>
                        <div className="requested-item-detail-back">
                            <BackPress onClick={goBack} />
                        </div>
                        <div className='requested-item-category-title'>
                            {item.catogery_type}
                        </div>
                        {screenSize == 'small' ? <ActionContainer /> : null}
                    </div>
                    <div className='requested-item-detail-container-body'>
                        <div className='requested-item-detail-container-main'>
                            <div className='image-and-location-container'>
                                <div className='requested-item-container-main-left'>
                                    {item.images && <SliderImage data={item} />}
                                </div>
                            </div>
                            <div className="requested-item-feature-container">
                                <div className='category-type-and-edit-button-container'>
                                    <div className='requested-item-feature-header'>
                                        <div className="requested-item-feature-header-title">{item.catogery_type}</div>
                                    </div>
                                    {screenSize != 'small' ? <ActionContainer /> : null}
                                </div>
                                <div className={!Array.isArray(item.parameters) || (item.parameters.length == 0 && item.location === null) ?
                                    (item.quantity == null && item.price == null ? "requested-item-feature-description requested-item-feature-description-extra-long" : "requested-item-feature-description product-feature-description-long") :
                                    "requested-item-feature-description"}>
                                    {item.product_description}
                                </div>

                                {Array.isArray(item.parameters) && item.parameters.length > 0 || item.location ? (
                                    <div className={item.quantity == null && item.price == null ? "requested-item-feature-parameters requested-item-feature-parameters" : "requested-item-feature-parameters"}>
                                        {item.parameters.map((parameter, index) => (
                                            <div className="requested-item-feature-parameter">
                                                {parameter.name}: {parameter.value} {parameter.um}
                                            </div>
                                        ))}
                                        <div className='merchant-location-container'>
                                            {item.location && <span>Location: {item.location}</span>}
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )}
                                <div className="requested-item-feature-trade">
                                    {isNumber(item.quantity) ? (
                                        <div className="requested-item-feature-quantity-container">
                                            <div className="requested-item-feature-quantity">{item.quantity != null ? item.quantity + ' ' + item.quantity_um : ''}
                                            </div>
                                        </div>
                                    ) : null}
                                    {isNumber(item.price) ? (
                                        <div className="requested-item-feature-price-container">
                                            <div className="requested-item-feature-price">{item.price != null ? 'Rs ' + item.price : ''}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <h5 className="requirement-text">
                                    Seller is notified about your requirement
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className='request-item-button-container'>
                        {item.status === "active" &&
                            (<button className='sucess-button-style' onClick={handleSuccessRequest}>
                                {isSuccessButtonLoading ?
                                    (
                                        <img src={LoadingWhite} alt="Loading" />
                                    ) : (
                                        "Mark Successful "
                                    )
                                }
                            </button>)}
                        {item.status === "active" &&
                            (<button className='close-button-style' onClick={handleClosedRequest}>
                                {isCloseButtonLoading ?
                                    (
                                        <img src={LoadingWhite} alt="Loading" />
                                    ) : (
                                        "Close request"
                                    )
                                }
                            </button>)}
                    </div>
                </div>)}

            {isFillProductVisible && (
                <FillBuyerRequestedItemPage
                    category={category}
                    data={formdata}
                    isEdit={isEdit}
                    itemId={itemId}
                    handleBackPress={handleEditBack}
                    updateFormProductData={handleFormUpdate}
                />
            )}
        </div>
    );
}

export default RequestedItemDetailPage;