import React from "react";
import { useState, useRef } from "react";
import Scrap from "../../images/Scrap.png";
import Logo from "../../images/Logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../api/login";
import { handleResponseError } from "../../utils/handleError";
import LoadingWhite from "../../images/LoadingWhite.gif";
import "../../styling/ForgotPassword.css";

export default function ForgotPassword({ onCloseWelcomeBack }) {
  onCloseWelcomeBack();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isButtonActive = email.trim() !== "";
  const verifyButtonRef = useRef(null);

  const handleVerrifyButtonClick = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      verifyButtonRef.current.click();
    }
  };

  const nextHandler = async () => {
    setIsLoading(true);
    const data = await forgotPassword(email);
    setIsLoading(false);
    if (data.error) return handleResponseError(data.error);
    if (data) {
      navigate("/verifyEmail", {
        state: { otp_id: data.otp_id, email: email },
      });
    }
  };

  return (
    <div className="forgotmodal-container">
      <div className="forgotmodal-content">
        <div className="imagefw-container">
          <img
            src={Scrap}
            alt="Company logo"
            className="fpScrap-image-login "
          />
          <img src={Logo} alt="Company logo" className="logofw-image" />
        </div>
        <div className="form-container-forgotpw">
          <div className="fwForm">
            <h2 className="forgotPassword">Reset Password</h2>
            <form>
              <input
                className="emaininputsform"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={handleVerrifyButtonClick}
              />
              <button
                type="button"
                ref={verifyButtonRef}
                className={`verifyForgotButton ${
                  isButtonActive ? "active" : ""
                } ${
                  isButtonActive ? "customCursorPointer" : "customCursorDefault"
                }`}
                disabled={!isButtonActive}
                onClick={nextHandler}
              >
                {isLoading ? (
                  <img
                    src={LoadingWhite}
                    alt="Loading"
                    className="getstarted-loading-inforgotpw"
                  />
                ) : (
                  "Verify"
                )}
              </button>
              <p className="haveaccount-text">
                Have an account?{" "}
                <Link to="/login" className="haveaccount-link">
                  <span className="forgotpassword-loginlink">Login</span>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
