// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awesome-slider .awssld__bullets .awssld__bullets--active {

    background-color: #fd9340;
     /* Active color: Orange */
}

  .awesome-slider .awssld__bullets .awssld__bullets--normal {
    background-color:#D9D9D9; /* Normal color: Gray */
  }

`, "",{"version":3,"sources":["webpack://./src/styling/MySliderComponent.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;KACxB,yBAAyB;AAC9B;;EAEE;IACE,wBAAwB,EAAE,uBAAuB;EACnD","sourcesContent":[".awesome-slider .awssld__bullets .awssld__bullets--active {\n\n    background-color: #fd9340;\n     /* Active color: Orange */\n}\n\n  .awesome-slider .awssld__bullets .awssld__bullets--normal {\n    background-color:#D9D9D9; /* Normal color: Gray */\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
