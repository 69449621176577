import React, { useState } from 'react';
import "../styling/HelpPage.css";
import { BackPress } from '../components/BackPress';
import { useEffect } from 'react';
import { getAppSetting } from '../constants/getAppSetting';
import { handleResponseError } from '../utils/handleError';

const HelpPage = ({ onClose }) => {
    const [isProductsInfo, setProductsInfo] = useState([]);

    useEffect(() => {
        async function fetchTitleAndDescription() {
            try {
                const filters = await getAppSetting();
                const categories = Object.keys(filters.products);
                const productsData = filters.products;

                const productsInfo = categories.map(category => ({
                    title: productsData[category].title,
                    description: productsData[category].description
                }));
                setProductsInfo(productsInfo);
                localStorage.setItem("categories", JSON.stringify(categories));
                if(filters.error){
                    handleResponseError(filters.error);
                }
            } catch (error) {
                console.error("Error fetching app settings:", error);
            }
        }

        fetchTitleAndDescription();
    }, []);

    const goBack = (e) => {
        onClose();
    };

    return (
        <div className="helpPage-modal-background" onClick={onClose}>
            <div className='helpPage-container'>
                <div className='helpPage-heading-container'>
                    <div className='helpPage-top-container'>          
                    <div className="back-press-container">
                        <BackPress onClick={goBack} />
                    </div>
                    <p className="help-heading-text">Help</p>
                    </div>
                <p className='typeOf-category-description'>Type of category present</p>
                </div>
                <div className='scrollableContainer'>
                {isProductsInfo.map((product, index) => (
                    <div key={index} className="individual-category-container" >
                        <div className='categoryTitle'>{product.title}</div>
                        <div className='categoryOverView'>{product.description}</div>
                    </div>
                ))}
                </div>
                </div>
            </div>
    );
};

export default HelpPage;