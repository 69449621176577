import React, { useEffect } from "react";
import '../styling/FillProduct.css'
import { useState } from "react";
import ProductPreview from "./ProductPreview";
import ProductFillSlot from "../components/ProductFillSlot";
import DropDownOptionSet from "../components/DropDownOptionSet";
import { handleError, handleResponseError } from "../utils/handleError";
import { getAllCatogeryProperites, getDefaultsUrlsForCategory } from "../settings/SettingsHandler";
import { parseImagesJson } from "../utils/imageHelper";
import { BackPress } from "../components/BackPress";
import { displayToast } from "../utils/toastHelper";
import { uploadImage } from "../api/imageApis";
import { ImageLoader } from "../components/image/ImageLoader";
import { ImageView } from "../components/image/ImageView";

const FillProduct = ({ category, data = {}, handleBackPress, isEdit, onClose, updateFormData }) => {
  const [formData, setFormData] = useState(data);
  const [settings, setSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [previewFormData, setPreviewFormData] = useState({});
  const [productImages, setProductImages] = useState(data.images ? parseImagesJson(data.images) : []); // todo: fix this
  const [isFillProduct, setFillProduct] = useState(true);
  const [isEditOpen, setIsEditOpen] = useState(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [imageDisplayError, setImageDisplayError] = useState("");
  const [parameterError, setParameterError] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(false);

  function handleFormUpdate(parameterKey, value) {
    if (value === "" || value === null) {
      if (formData.hasOwnProperty(parameterKey)) setFormData((prevFormData) => {
        const updatedNewFormData = { ...prevFormData };
        delete updatedNewFormData[parameterKey];
        return updatedNewFormData;
      });
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [parameterKey]: value,
      }));
    }
  }

  function handleFormParameterUpdate(parameterKey, value, um = null) {
    if (value === "" || value === null) {
      if (formData.hasOwnProperty("parameters")) {
        const newFormParameters = { ...formData.parameters };
        delete newFormParameters[parameterKey];
        setFormData((prevFormData) => ({
                   ...prevFormData,
                   parameters: newFormParameters, 
                }));
              }
    } else {
      let newFormParameters = {}
      if (formData.hasOwnProperty("parameters")) newFormParameters = formData.parameters;

      newFormParameters[parameterKey] = {
        name: parameterKey,
        value: value,
        um: um
      };
      handleFormUpdate("parameters", newFormParameters);
    }
  }

  function handlePriceUpdate(parameterKey, value, um = "INR") {
    handleFormUpdate("price", value);
    handleFormUpdate("currency", um);
  }

  function handleQuantityUpdate(parameterKey, value, um = "kgs") {
    handleFormUpdate("quantity", value);
    handleFormUpdate("quantityUm", um);
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const imagePickHandler = async () => {
    if (isImageUploading) {
      displayToast("already another image upload is in progress. Please wait or cancel it.");
      return;
    }

    if (productImages.length === 4) {
      displayToast("you can upload only 4 images for a product.");
    } else {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      fileInput.onchange = async (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
          setIsImageUploading(true);
          const uploadResponse = await uploadImage(selectedImage, "item", setImageUploadProgress);
          setIsImageUploading(false);
          setImageUploadProgress(0);
          if (uploadResponse.error) {
            return handleResponseError(uploadResponse.error);
          }

          const imageUrl = uploadResponse.image_url;
          setProductImages((prevImages) => [...prevImages, imageUrl]);
        }
      };
      fileInput.click();
    }
    setImageDisplayError("");
  };

  const deleteImageHandler = (index) => {
    const updatedImages = productImages.filter((_, i) => i !== index);
    setProductImages(updatedImages);
  };

  useEffect(() => {
    (async () => {
      const globalSettings = await getAllCatogeryProperites();
      setSettings(globalSettings[category].add_product);

      setIsLoading(false);
    })();
  }, []);

  const goBack = () => {
    handleBackPress();
  };

  const previewClickHandler = async () => {
    if (!settings.parameters_fixed.price.is_optional && !formData.price) {
      setPriceError("price input is required"); return;
    }

    if (!settings.parameters_fixed.quantity.is_optional && !formData.quantity) {
      setQuantityError("quantity input is required"); return;
    }

    if (!settings.parameters_fixed.description.is_optional && !formData.description) {
      setDescriptionError("Description is required"); 
      return;
    }

    if (!settings.parameters_fixed.images.is_optional && productImages.length === 0) {
      setImageDisplayError("images are required"); return;
    }

    let hasFormError = false;
    const parameterErrorsCopy = {}; 
    settings.parameters_default.forEach((parameter) => {
      if (!parameter.optional) {
        const paramName = parameter.name;
        const paramValue = formData.parameters?.[paramName]?.value;

        if (!paramValue || paramValue.toString().trim() === '') {
          parameterErrorsCopy[paramName] = `${paramName} input is required`;
          hasFormError = true;
        }
      }
    });

    if (hasFormError) {
      setParameterError(parameterErrorsCopy); 
      return;
    } else {
      setParameterError({});
    }

    let formatedParameters = [];
    if (formData.parameters != null) {
      const paramererKeys = Object.keys(formData.parameters);
      paramererKeys.forEach((key) => {
        formatedParameters.push(formData.parameters[key]);
      });
    }

    let previewData = JSON.parse(JSON.stringify(formData));
    previewData.parameters = formatedParameters;

    previewData.images = productImages;

    if (!previewData.images || previewData.images.length == 0) {
      previewData.images = await getDefaultsUrlsForCategory(category);
      setProductImages(previewData.images);
    }

    setPreviewFormData(previewData);
    setIsEditOpen(false);
    setIsPreviewOpen(true);
  };

  function productPreviewBackHandler() {
    setIsEditOpen(true);
    setIsPreviewOpen(false);
  }

  const goBackToSeller = () => {
    handleBackPress();
  };

  const handleOnClose = () => {
    setIsEditOpen(false);
    onClose();
  };

  return (
    <div>
      {isFillProduct && (<div>
        {isLoading ? <></> :
          <div>
            {isEditOpen &&
              <div className="modal-background-fillproduct" onClick={goBackToSeller}   >
                <div className="fill-product-container" onClick={(e) => e.stopPropagation()}>
                  <div className="fill-product-content-container">
                    <div className="fill-product-content-container-data">
                      <div className="fill-product-header">
                        <BackPress onClick={goBack} />
                        <div>
                          <div className="catogery-heading-text">{category}</div>
                        </div>
                      </div>

                      <div className="fill-product-body">
                        <div className="parameters-container">
                          {settings.parameters_default.map((parameter, index) => {
                            const value = formData.parameters != null && formData.parameters[parameter.name] != null ? formData.parameters[parameter.name].value : '';
                            const defaultUm = formData.parameters != null && formData.parameters[parameter.name] != null ? formData.parameters[parameter.name].um : parameter.default_um;
                            return (
                              parameter.input_type !== 'drop_down' ? (
                                <ProductFillSlot
                                  defaultUm={defaultUm} dataType={parameter.data_type} value={value} supportedUm={parameter.supported_um} key={index} name={parameter.name} handleUpdate={handleFormParameterUpdate} isOptional={parameter.optional} error={parameterError[parameter.name] || ''} clearError={() => setParameterError((prevErrors) => ({ ...prevErrors, [parameter.name]: '' }))}
                                />
                              ) : (
                                <DropDownOptionSet key={index} value={value} parameter={parameter} handleUpdate={handleFormParameterUpdate} isOptional={parameter.optional} error={parameterError[parameter.name] || ''} clearError={() => setParameterError((prevErrors) => ({ ...prevErrors, [parameter.name]: '' }))} />
                              )
                            );
                          })}

                          {settings.parameters_fixed.price.visible ? <ProductFillSlot defaultUm={"INR"} name={"price"} supportedUm={["INR"]} handleUpdate={handlePriceUpdate} isOptional={settings.parameters_fixed.quantity.is_optional} value={formData.price != null ? formData.price + '' : ''} error={priceError} clearError={() => setPriceError('')} /> : null}
                          {settings.parameters_fixed.quantity.visible ? <ProductFillSlot defaultUm={formData.quantityUm != null ? formData.quantityUm :formData.categoryType === 'Sheet' ? 'kgs' : 'Nos'} name={"quantity"} supportedUm={["kgs", "Nos","meter"]} handleUpdate={handleQuantityUpdate} isOptional={settings.parameters_fixed.quantity.is_optional} value={formData.quantity != null ? formData.quantity + '' : ''} error={quantityError} clearError={() => setQuantityError('')} /> : null}

                          {settings.parameters_fixed.images.visible ? <div className="image-container-style">
                            <text className="props-text-style" style={{ paddingLeft: '3px' }} >Product images {settings.parameters_fixed.images.is_optional ? null : '*'} </text>
                            <div className="image-handler-container">
                              <div className="selected-images-row" >
                                {productImages.map((image, index) => (
                                  <ImageView url={image} removeHandler={() => deleteImageHandler(index)} />
                                ))}
                                {isImageUploading && <ImageLoader imageUploadProgress={imageUploadProgress} />}
                              </div>
                              <div className="gallery-style" onClick={imagePickHandler} style={{ cursor: 'pointer' }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.66618 20H14.3328C17.7231 20 20 17.6219 20 14.0833V5.91672C20 2.37811 17.7231 0 14.3338 0H5.66618C2.2769 0 0 2.37811 0 5.91672V14.0833C0 17.6219 2.2769 20 5.66618 20ZM6.49886 9C5.12021 9 4 7.87827 4 6.5C4 5.12173 5.12021 4 6.49886 4C7.8765 4 8.99772 5.12173 8.99772 6.5C8.99772 7.87827 7.8765 9 6.49886 9ZM17.8208 12.934C18.1557 13.7926 17.9817 14.8246 17.6237 15.6749C17.1994 16.6863 16.3869 17.452 15.3632 17.7864C14.9087 17.935 14.432 18 13.9564 18H5.52864C4.68999 18 3.94788 17.7988 3.3395 17.4241C2.95839 17.1889 2.89102 16.646 3.17358 16.2941C3.6462 15.7059 4.11279 15.1156 4.5834 14.5201C5.48038 13.3808 6.08473 13.0506 6.75645 13.3406C7.02896 13.4603 7.30248 13.6398 7.58404 13.8297C8.3342 14.3395 9.37699 15.0402 10.7506 14.2797C11.6906 13.7532 12.2358 12.8501 12.7106 12.0637L12.7185 12.0506C12.7522 11.9954 12.7855 11.9402 12.8187 11.8852C12.9783 11.6212 13.1357 11.3607 13.3138 11.1207C13.5371 10.8204 14.3646 9.88132 15.4366 10.5501C16.1194 10.9711 16.6936 11.5408 17.308 12.1507C17.5423 12.3839 17.7092 12.6491 17.8208 12.934Z"
                                    fill="#130F26"
                                  />
                                </svg>
                              </div>
                              {imageDisplayError && <div className="descrption-error-message">{imageDisplayError}</div>}
                            </div>
                          </div> : null}

                          {settings.parameters_fixed.description.visible ? (
                            <div className="propdescrption-style">
                              <div className="descrtiption-input-title">
                                <text className="props-text-style">
                                  Description{settings.parameters_fixed.description.is_optional ? null : '*'}
                                </text>
                              </div>
                              <div className="description-text-container">
                                <textarea
                                  type="text"
                                  placeholder=""
                                  className="containerdescrption-style"
                                  value={formData.description || ''}
                                  onChange={(event) => {
                                    handleFormUpdate('description', event.target.value);
                                    setDescriptionError(""); 
                                  }}
                                />
                                {descriptionError && <div className="descrption-error-message">{descriptionError}</div>}
                              </div> 
                           </div>
                          ) : null}

                          <div className="preview-button-container">
                            <button className="button-container-1" onClick={() => previewClickHandler()}>
                              <text className="button-text-style">Preview and Submit</text>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {isPreviewOpen && <ProductPreview formData={previewFormData} backHandler={productPreviewBackHandler} images={productImages} isEdit={isEdit} id={formData.id} onClose={handleOnClose} updateFormData={updateFormData} ></ProductPreview>
            }
          </div>
        }
      </div>)}
    </div>
  );
};

export default FillProduct;
