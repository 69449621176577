import React, { useState } from "react";
import Modal from "react-modal";
import { BackPress } from "../BackPress";
import SliderImage from "../SliderImage";
import { SellerProfile } from "../buyer/SellerProfile";
import { isNumber } from "../../utils/numberHelper";
import { useSession } from "../../contexts/SessionContext";
import "./ProductDetailedView.css";

export default function ProductDetailedView({
  item,
  SideActionContainer,
  MainActionContainer,
  backHandler = null,
  headingText = "",
}) {
  const { screenSize } = useSession();
  const [currentSellerMerchant, setCurrentSellerMerchant] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const goBack = () => {
    if (backHandler != null) return backHandler();

    window.history.back();
  };

  function SellerButton({ merchant }) {
    function handleSellerProfileLoadInternal() {
      setCurrentSellerMerchant(merchant);
      setIsModalOpen(true);
    }

    return (
      <div
        className="product-seller-button"
        onClick={handleSellerProfileLoadInternal}
      >
        <div className="product-seller-button-text">{merchant.name}</div>
      </div>
    );
  }

  function handleSellerProfileClose() {
    setIsModalOpen(false);
  }

  return (
    <div>
      <div className="product-detail-container">
        <div className="product-detail-container-header">
          <div className="product-detail-back">
            <BackPress onClick={goBack} />
          </div>
          <div className="product-detail-container-header-title">
            {headingText != "" ? headingText : item.catogery_type}
          </div>
          {screenSize != "small" ? <SideActionContainer /> : null}
        </div>

        <div className="product-detail-container-body">
          <div>
            <div className="product-detail-container-main">
              <div className="product-detail-container-main-left">
                <div className="product-images-container">
                  <SliderImage data={item} />
                </div>
                {screenSize == "small" ? <SideActionContainer /> : null}
              </div>
              <div className="product-features-container">
                <div className="product-feature-header">
                  <div className="product-feature-header-title">
                    {item.catogery_type}
                  </div>
                </div>
                <div
                  className={
                    !Array.isArray(item.parameters) ||
                    item.parameters.length == 0
                      ? item.quantity == null && item.price == null
                        ? "product-feature-description product-feature-description-extra-long"
                        : "product-feature-description product-feature-description-long"
                      : "product-feature-description"
                  }
                >
                  {item.product_description}
                </div>
                {Array.isArray(item.parameters) &&
                item.parameters.length > 0 ? (
                  <div
                    className={
                      item.quantity == null && item.price == null
                        ? "product-feature-parameters product-feature-parameters"
                        : "product-feature-parameters"
                    }
                  >
                    {item.parameters.map((parameter, index) => (
                      <div className="product-feature-parameter">
                        {parameter.name}: {parameter.value} {parameter.um}
                      </div>
                    ))}
                  </div>
                ) : null}

                <div className="product-feature-trade">
                  {isNumber(item.quantity) ? (
                    <div className="product-feature-quantity-container">
                      <div className="product-feature-quantity">
                        {item.quantity != null
                          ? item.quantity + " " + item.quantity_um
                          : ""}
                      </div>
                    </div>
                  ) : null}
                  {isNumber(item.price) ? (
                    <div className="product-feature-price-container">
                      <div className="product-feature-price">
                        {item.price != null ? "Rs " + item.price : ""}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <MainActionContainer />
          </div>

          {item.merchants_viewed && (
            <div className="product-activity-container-recent">
              <text className="product-activity-container-text">
                Recent companies that viewed this product
              </text>
              <div className="product-merchant-activity">
                {item.merchants_viewed.map((parameter, index) => (
                  <SellerButton merchant={parameter.buyer_merchant} />
                ))}
              </div>
            </div>
          )}
          {item.merchants_enquired && (
            <div className="product-activity-container-inquired">
              <text className="product-activity-container-text">
                Recent companies that inquired about this product
              </text>
              <div className="product-merchant-activity">
                {item.merchants_enquired.map((parameter, index) => (
                  <SellerButton merchant={parameter.buyer_merchant} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        contentLabel="Popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}
      >
        {" "}
        <SellerProfile
          handleBackPress={handleSellerProfileClose}
          merchant={currentSellerMerchant}
        />
      </Modal>
    </div>
  );
}
