import React, { useState, useEffect, useCallback } from "react";
import "../styling/favorite.css";
import FavoriteItemsCard from "../components/FavoriteItemsCard";
import { useSession } from "../contexts/SessionContext";
import Product from "./Product";
import { fetchFavorites } from "../api/merchantApis";
import { handleResponseError } from "../utils/handleError";
import { useNavigate } from "react-router-dom";
import { BackPress } from "../components/BackPress";
import LoadingSpinner from "../components/LoadingSpinner";

function Favorite({ }) {
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [displayProductView, setDisplayProductView] = useState(false);
  const { sessionId } = useSession();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const favoriteProducts = useCallback(async () => {
    const data = await fetchFavorites();
    if (data.error) return handleResponseError(data.error);
    setFavoriteItems(data.items);
    setIsLoading(false);
  }, [sessionId]);

  useEffect(() => {
    favoriteProducts();
  }, []);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleItemClick = (item) => {
    navigate(`/categories/${item.catogery_type}/${item.id}`);
  };

  return (
    <div>
      {displayProductView ? (
        <Product data={selectedProductId} />
      ) : (
        <div>
          <div className="go-backContainer">
            <div className="favorite-back-press">
              <BackPress onClick={handleGoBack}/>
            </div>
            <div className="favorite-header">Favorite items</div>
          </div>
          {isLoading ?
            <div style={{ width: '100%' }}>
              <div style={{ paddingTop: '10%', paddingBottom: '10%' }}> <LoadingSpinner /> </div>
            </div> :
            <div className="drafts">
              <div className="draft-container">
                <FavoriteItemsCard
                  data={favoriteItems}
                  handleItemClick={handleItemClick}
                />
              </div>
            </div>}
        </div>
      )}
    </div>
  );
}

export default Favorite;
