import React from "react";
import { useState, useEffect, useRef } from "react";
import Scrap from "../../images/Scrap.png";
import Logo from "../../images/Logo.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL, FORGOT_PASSWORD } from "../../config";
import LoadingWhite from "../../images/LoadingWhite.gif";
import { setSessionId } from "../../session/SessionHandler";
import "../../styling/VerifyMail.css";

export default function VerifyEmail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const { otp_id, email } = state || {};
  const [otpValue, setOtpValue] = useState(["", "", "", ""]);
  const [newOtp, setNewOtp] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [resendClicked, setResendClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const inputChangeHandler = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const updatedOtpValue = [...otpValue];
      updatedOtpValue[index] = value;
      setOtpValue(updatedOtpValue);
      if (value && index < 3) {
        const nextInput = document.getElementById(`otpInput-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
    setErrorMessage("");
  };

  const handleKeyDown = (index, e) => {
    if ((e.key === "Backspace" || e.key === "Delete") && index >= 0) {
      if (otpValue[index] === "") {
        const previousIndex = Math.max(index - 1, 0);
        const previousInput = inputRefs.current[previousIndex];
        if (previousInput) {
          previousInput.focus();
        }
      } else {
        otpValue[index] = "";
        setOtpValue([...otpValue]);
      }
    }
    setErrorMessage("");
  };

  useEffect(() => {
    const inputElement = document.getElementById("otpInput-0");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const isButtonActive = otpValue.every((value) => value !== "");

  const resendOTP = async () => {
    try {
      const response = await fetch(BASE_URL + FORGOT_PASSWORD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setNewEmail(email);
        setNewOtp(data.otp_id);
        await resendEmailHandler(data.otp_id, email);
        setResendClicked(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOTP = () => {
    resendOTP(email);
  };

  const continueButtonClickHandler = () => {
    setIsLoading(true);
    if (resendClicked) {
      resendEmailHandler();
    } else {
      verifyEmailHandler();
    }
  };

  const resendEmailHandler = async () => {
    try {
      const response = await fetch(
        BASE_URL +
          "api/v1/authentication/merchant/user/verify_forgot_password_otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: newEmail,
            otp_id: newOtp,
            otp: otpValue.join(""),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSessionId(data.session_id);
        navigate("/verify");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyEmailHandler = async () => {
    try {
      const response = await fetch(
        BASE_URL +
          "api/v1/authentication/merchant/user/verify_forgot_password_otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            otp_id: otp_id,
            otp: otpValue.join(""),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSessionId(data.session_id);
        navigate("/verify");
      } else {
        setVerificationStatus("failed");
        setErrorMessage("Incorrect OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setVerificationStatus("error");
      setErrorMessage("An error occurred while verifying OTP.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="verify-container">
      <div className="verify-content">
        <div className="veifyMailimage-container">
          <img src={Scrap} alt="Company logo" className="vmScrap-image" />
          <img src={Logo} alt="Company logo" className="verifyMaillogo-image" />
        </div>
        <div className="form-container-verifyMail">
          <div className="verifyForm">
            <h2 className="verifyAccount">Reset Password</h2>
            <form className="verifyAccount-form-container">
              <div className="otpInputContainer">
                {otpValue.map((value, index) => (
                  <input
                    key={index}
                    id={`otpInput-${index}`}
                    className="otpInput"
                    type="number"
                    maxLength="1"
                    value={value}
                    onChange={(e) => inputChangeHandler(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(input) => (inputRefs.current[index] = input)}
                  />
                ))}
              </div>

              <div className="messageContainer">
                <p className="message">
                  Please enter the 4-digit code you received on your email.
                </p>
              </div>
              {verificationStatus === "failed" && (
                <div className="error-message-container">{errorMessage}</div>
              )}
              <div className="resendOtptext-container">
                <p className="resendOtptext">
                  Did not Receive OTP?{" "}
                  <Link
                    to=" "
                    className="create-account-link"
                    onClick={handleResendOTP}
                  >
                    <span
                      style={{
                        color: "#fd9340",
                        fontWeight: 700,
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Resend OTP
                    </span>
                  </Link>
                </p>
              </div>
              <button
                type="button"
                className={`continueButton ${isButtonActive ? "active" : ""}`}
                disabled={!isButtonActive}
                onClick={continueButtonClickHandler}
                style={{ position: "relative", overflow: "hidden" }}
              >
                {isLoading ? (
                  <>
                    <img
                      src={LoadingWhite}
                      alt="Loading"
                      className="getstarted-loading-inlogin"
                    />
                  </>
                ) : (
                  "Reset Password"
                )}
              </button>
              <div className="have-accounttext-container">
                <p className="have-accounttext">
                  Have an account?{" "}
                  <Link to="/login" className="have-accountlink">
                    <span
                      style={{
                        color: "#fd9340",
                        fontWeight: 700,
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Login
                    </span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
