import { useState, useEffect } from "react";
import "../styling/feedback.css";
import { BackPress } from "../components/BackPress";
import FeedbackSuccessPage from "./FeedbackSuccessPage";
import { userFeedbackApi } from "../api/userFeedbackApi";
import LoadingWhite from "../images/LoadingWhite.gif";
import { handleResponseError } from "../utils/handleError";

function FeedbackPage({ handleFeedbackPageClose }) {
  const buttonName = "Send";
  const [feedback, setFeedback] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFeedBackSucess, setIsFeedBackSucess] = useState(false);

  const handleFeedbackChange = (event) => {
    if (event.target.value.length <= 5000) {
      setFeedback(event.target.value);
    }
  };

  const isButtonActive = feedback.trim().length > 0;

  const goBack = (e) => {
    handleFeedbackPageClose();
  };

  const onClose = () => {
    setIsFeedBackSucess(false);
    handleFeedbackPageClose();
  };

  const hideFeedbackSucess = () => {
    setIsFeedBackSucess(false);
  };

  const handleSubmit = async () => {
    const input = { feedback };
    try {
      setIsLoading(true);
      const response = await userFeedbackApi(input);
      if (response.error) {
        handleResponseError(response.error);
        return;
      }
      setIsFeedBackSucess(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBackToHomePage = () => {
    window.history.back();
  };

  return (
    <div>
      {isFeedBackSucess ? (
        <FeedbackSuccessPage
          handleBackPress={hideFeedbackSucess}
          onClose={onClose}
        />
      ) : (
        <div className="feedback-modal-container" onClick={goBack}>
          <div
            className="feedback-container"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="feedback-header">
              <div className="feedback-backpress-button">
                <BackPress onClick={handleGoBackToHomePage} />
              </div>
              <p className="feedback-heading">Give Us feedback</p>
            </div>
            <div className="feedback-content">
              <div className="feedback-content-text">
                Write your suggestion here
              </div>
              <textarea
                className="feedback-input"
                value={feedback}
                onChange={handleFeedbackChange}
                placeholder=""
              />
              <div
                className="feedback-input-limit"
                style={{ alignSelf: "flex-end" }}
              >
                5000 Chars
              </div>
            </div>
            <div className="feedback-button-container">
              <button
                type="button"
                className={`${
                  isButtonActive ? "feedback-button-active" : "feedback-button"
                }`}
                onClick={handleSubmit}
                disabled={!isButtonActive || isLoading}
              >
                {isLoading ? (
                  <img
                    src={LoadingWhite}
                    alt="Loading"
                    className="feedback-button-loading"
                  />
                ) : (
                  buttonName
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeedbackPage;
