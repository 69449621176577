import { BASE_URL } from "../config";

export async function deleteProduct(itemId, sessionId) {


    const response = await fetch(BASE_URL +'api/v1/merchant/seller/items' + `/${itemId}`, {

        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": sessionId
        },
    })
    const data = await response.json();
    if (response.ok) {
        window.alert('Item deleted successfully');
        console.log('connected')
    }
    if (data.error) {
        if (window.ToastAndroid) {
            // For Android
            window.ToastAndroid.show(data.error.description, window.ToastAndroid.LONG);
        } else {
            // For other platforms
            window.alert(data.error.description);
        }
    }

}