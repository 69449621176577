import React from 'react';

const EditProfileIconSmall = ({ onClick }) => {
    return (
        <div style={{ cursor: 'pointer' }} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                <circle cx="18.808" cy="18.808" r="18.808" fill="white" />
                <path d="M20.6816 27.6802H28.2598" stroke="#130F26" stroke-width="1.984" stroke-linecap="round" stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6702 10.2853C20.4807 9.31669 21.9376 9.17465 22.9263 9.96864C22.981 10.0117 24.7374 11.3762 24.7374 11.3762C25.8235 12.0328 26.161 13.4287 25.4896 14.4939C25.4539 14.551 15.524 26.9719 15.524 26.9719C15.1937 27.384 14.6922 27.6273 14.1562 27.6331L10.3535 27.6809L9.49666 24.0544C9.37663 23.5444 9.49666 23.0089 9.82702 22.5968L19.6702 10.2853Z" stroke="#130F26" stroke-width="1.984" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17.832 12.5901L23.529 16.9652" stroke="#130F26" stroke-width="1.984" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    );
};

export default EditProfileIconSmall;