// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
    width: 25px;
    height: 25px;
    border: 10px solid #fff;
    border-top: 10px solid #fd9340;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/styling/Spinner.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,8BAA8B;IAC9B,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loading-spinner {\n    width: 25px;\n    height: 25px;\n    border: 10px solid #fff;\n    border-top: 10px solid #fd9340;\n    border-radius: 50%;\n    animation: spinner 1.5s linear infinite;\n}\n\n.spinner-container {\n    display: flex;\n    justify-content: center;\n    height: 50px;\n    align-items: center;\n}\n\n@keyframes spinner {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
