import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { useParams, useNavigate } from "react-router-dom";
import FillProduct from "./FillProduct";
import DeleteHandler from "./DeleteHandler";
import LoadingSpinner from "../components/LoadingSpinner";
import ProductDetailedView from "../components/item/ProductDetailedView";
import commentIcon from "../images/comment.svg";
import postComment from "../images/post_comment.svg";
import LoadingWhite from "../images/LoadingWhite.gif";
import commentsDefaultProfile from "../images/comments_default_profile.svg";
import { parseImagesJson } from "../utils/imageHelper";
import { useSession } from "../contexts/SessionContext";
import { handleResponseError } from "../utils/handleError";
import {
  getItemForSeller,
  MakeMerchantItemSold,
  MakeMerchantItemPublish,
} from "../api/sellerApis";
import {
  comments,
  postComments,
  commentReplies,
  postReply,
} from "../api/commentApis";
import "../styling/SellerProductView.css";

function SellerProductView() {
  const { itemId } = useParams();
  const replyButtonRef = useRef(null);
  const [comment, setComment] = useState("");
  const [replies, setReplies] = useState({});
  const [product, setProduct] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [formdata, setFormData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [commentsData, setCommentsData] = useState(null);
  const [postingComment, setPostingComment] = useState("");
  const [loadingReplies, setLoadingReplies] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { screenSize, seller_page_item_toggle_state } = useSession();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [replyingToCommentId, setReplyingToCommentId] = useState(null);
  const [replyingToMerchantName, setReplyingToMerchantName] = useState("");
  const [isLoadingMoreComments, setIsLoadingMoreComments] = useState(false);
  const navigate = useNavigate();

  const handleCloseEditModal = () => {
    setIsEditOpen(false);
  };

  const backHandler = () => {
    navigate("/");
  };

  const handleEditProduct = () => {
    let parameters = {};
    if (product.parameters != null) {
      product.parameters.forEach((parameter, _) => {
        parameters[parameter.name] = parameter;
      });
    }

    setFormData({
      id: product.id,
      price: product.price,
      quantity: product.quantity,
      quantityUm: product.quantity_um,
      categoryType: product.catogery_type,
      description: product.product_description,
      parameters: parameters,
      images: parseImagesJson(product.images),
    });

    setIsEditOpen(true);
    setIsEdit(true);
  };

  const handleDeleteProduct = () => {
    setIsDeleteModalOpen(true);
  };

  const handleItemsSold = async () => {
    const productId = product.id;
    const response = await MakeMerchantItemSold(productId);
    if (response.error) return handleResponseError(response.error);
    setProduct(response);
    setProduct({ ...product, status: response.status });
  };

  const handleItemsPublish = async () => {
    setIsButtonLoading(true);

    const productId = product.id;
    const response = await MakeMerchantItemPublish(productId);

    if (response.error) {
      setIsButtonLoading(false);
      return handleResponseError(response.error);
    }

    setProduct(response);
    setProduct({ ...product, status: response.status });
    setIsButtonLoading(false);
  };

  useEffect(() => {
    (async () => {
      const data = await getItemForSeller(itemId);
      if (data.error) return handleResponseError(data.error);

      setProduct(data);
      setIsLoading(false);
    })();
  }, []);

  function handleEditBack() {
    setIsEditOpen(false);
  }

  function deleteBackHandler() {
    setIsDeleteModalOpen(false);
  }

  const formatDate = (date, context) => {
    const currentDate = new Date();
    const inputDate = new Date(date);

    if (inputDate.toDateString() === currentDate.toDateString()) {
      return context === "reply"
        ? inputDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        : "Today";
    }

    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    if (inputDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    }

    return inputDate.toLocaleDateString([], {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const handleInputChange = (event) => {
    setComment(event.target.value);
  };

  let lastDate = null;

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const commentText = e.target.value.trim();
      if (commentText !== "" && !postingComment) {
        if (replyingToCommentId) {
          postReplyHandler();
        } else {
          postCommentHandler();
        }
      }
    }
  };

  async function commentsHandler(page = 1) {
    if (page === 1) {
      setIsLoadingComments(true);
    }
    try {
      const data = await comments({ itemId, page });
      if (data.error) {
        setIsLoadingComments(false);
        handleResponseError(data.error);
      } else {
        setCommentsData((prevComments) =>
          page === 1 ? data.data : [...prevComments, ...data.data]
        );
        setTotalPages(Math.ceil(data.total / 10));
        setIsLoadingComments(false);
        setIsLoadingMoreComments(false);
      }
    } catch (error) {
      setIsLoadingComments(false);
      handleResponseError(error);
    }
  }

  const loadMoreComments = () => {
    if (currentPage < totalPages) {
      setIsLoadingMoreComments(true);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    commentsHandler(currentPage);
  }, [currentPage]);

  async function postCommentHandler() {
    setPostingComment(true);
    try {
      const data = await postComments({ message: comment, itemId });
      if (data.error) {
        handleResponseError(data.error);
      } else {
        setCommentsData((prevComments) => [data, ...prevComments]);
        setComment("");
      }
    } finally {
      setPostingComment(false);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        replyButtonRef.current &&
        !replyButtonRef.current.contains(event.target) &&
        !event.target.classList.contains("comment-input") &&
        !event.target.classList.contains("comment-submit-button") &&
        !event.target.classList.contains("comments-post-icon")
      ) {
        setReplyingToCommentId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [replyButtonRef, comment, replyingToCommentId]);

  const postReplyHandler = async () => {
    setPostingComment(true);
    try {
      const response = await postReply({
        itemId: replyingToCommentId,
        message: comment,
      });

      if (response.error) {
        setPostingComment(false);
        handleResponseError(response.error);
      } else {
        setPostingComment(false);
        setReplies((prevReplies) => ({
          ...prevReplies,
          [replyingToCommentId]: [
            ...(prevReplies[replyingToCommentId] || []),
            response,
          ],
        }));

        setCommentsData((prevComments) =>
          prevComments.map((prevComment) =>
            prevComment.id === replyingToCommentId
              ? {
                  ...prevComment,
                  total_replies: (prevComment.total_replies || 0) + 1,
                }
              : prevComment
          )
        );

        setComment("");
        setReplyingToCommentId(null);
      }
    } catch (error) {
      handleResponseError(error);
    }
  };

  const fetchMerchantReplies = async (commentId) => {
    try {
      setLoadingReplies(commentId);
      const response = await commentReplies({ itemId: commentId });

      if (response.error) {
        handleResponseError(response.error);
      } else {
        setReplies((prevReplies) => ({
          ...prevReplies,
          [commentId]: response.data,
        }));
      }
    } catch (error) {
      handleResponseError(error);
    } finally {
      setLoadingReplies(null);
    }
  };

  const handleHideReplies = (commentId) => {
    setReplies((prevReplies) => ({
      ...prevReplies,
      [commentId]: null,
    }));
  };

  const handleReplyButtonClick = (commentId, merchantName) => {
    setReplyingToCommentId(commentId);
    setReplyingToMerchantName(merchantName);
    setTimeout(() => {
      const inputField = document.querySelector(".comment-input");
      if (inputField) inputField.focus();
    }, 100);
  };

  const handleViewRepliesClick = async (commentId) => {
    if (!replies[commentId]) {
      await fetchMerchantReplies(commentId);
    }
  };

  const handleFormUpdate = (newFormData) => {
    setFormData({
      ...formdata,
      id: newFormData.id,
      price: newFormData.price,
      quantity: newFormData.quantity,
      quantityUm: newFormData.quantity_um,
      categoryType: newFormData.catogery_type,
      description: newFormData.product_description,
      parameters: newFormData.parameters,
      images: newFormData.images,
      status: newFormData.status,
    });

    const updatedProduct = {
      ...product,
      price: newFormData.price,
      quantity: newFormData.quantity,
      quantityUm: newFormData.quantity_um,
      parameters: newFormData.parameters,
      images: newFormData.images,
      product_description: newFormData.product_description,
      status: newFormData.status,
    };

    setProduct(updatedProduct);
  };

  function ActionContainer() {
    return (
      <div
        className={`seller-product-feature-edit-delete ${
          isButtonLoading ? "disable" : ""
        }`}
      >
        <div
          className="product-feature-edit"
          onClick={() => handleEditProduct(product)}
        >
          {(product.status === "active" || product.status === "draft") &&
            (screenSize != "small" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="36"
                viewBox="0 0 32 36"
                fill="none"
              >
                <path
                  d="M30.083 11.2402L12.6508 33.7834C11.8507 34.8071 10.6718 35.3829 9.40856 35.4042L2.46094 35.4896C2.08198 35.4896 1.76618 35.2336 1.68197 34.8711L0.102967 28.0249C-0.170727 26.7666 0.102967 25.4657 0.902995 24.4633L13.2613 8.46766C13.4719 8.21173 13.8508 8.17121 14.1035 8.36102L19.3036 12.4986C19.6405 12.7758 20.1037 12.9251 20.5879 12.8611C21.6195 12.7332 22.3143 11.7947 22.209 10.7924C22.1458 10.2805 21.8932 9.85395 21.5564 9.53403C21.4511 9.44872 16.5035 5.48182 16.5035 5.48182C16.1877 5.22589 16.1246 4.75668 16.3772 4.4389L18.3352 1.8988C20.1458 -0.425892 23.3038 -0.639167 25.8512 1.38694L28.7777 3.71164C29.9777 4.65004 30.7777 5.88704 31.0514 7.18801C31.3672 8.61909 31.0304 10.0246 30.083 11.2402Z"
                  fill="#FD9340"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="21"
                viewBox="0 0 32 36"
                fill="none"
              >
                <path
                  d="M30.083 11.2402L12.6508 33.7834C11.8507 34.8071 10.6718 35.3829 9.40856 35.4042L2.46094 35.4896C2.08198 35.4896 1.76618 35.2336 1.68197 34.8711L0.102967 28.0249C-0.170727 26.7666 0.102967 25.4657 0.902995 24.4633L13.2613 8.46766C13.4719 8.21173 13.8508 8.17121 14.1035 8.36102L19.3036 12.4986C19.6405 12.7758 20.1037 12.9251 20.5879 12.8611C21.6195 12.7332 22.3143 11.7947 22.209 10.7924C22.1458 10.2805 21.8932 9.85395 21.5564 9.53403C21.4511 9.44872 16.5035 5.48182 16.5035 5.48182C16.1877 5.22589 16.1246 4.75668 16.3772 4.4389L18.3352 1.8988C20.1458 -0.425892 23.3038 -0.639167 25.8512 1.38694L28.7777 3.71164C29.9777 4.65004 30.7777 5.88704 31.0514 7.18801C31.3672 8.61909 31.0304 10.0246 30.083 11.2402Z"
                  fill="#FD9340"
                />
              </svg>
            ))}
        </div>

        <div className="product-feature-delete" onClick={handleDeleteProduct}>
          {(product.status === "active" || product.status === "draft") &&
            (screenSize != "small" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="39"
                viewBox="0 0 35 39"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M33.0383 6.83056C33.7678 6.83056 34.375 7.43618 34.375 8.2068V8.91929C34.375 9.67116 33.7678 10.2955 33.0383 10.2955H1.96348C1.23224 10.2955 0.625 9.67116 0.625 8.91929V8.2068C0.625 7.43618 1.23224 6.83056 1.96348 6.83056H7.43044C8.54097 6.83056 9.50744 6.0412 9.75727 4.92746L10.0436 3.64872C10.4885 1.90686 11.9528 0.75 13.6286 0.75H21.3714C23.029 0.75 24.5097 1.90686 24.9382 3.55685L25.2446 4.92558C25.4926 6.0412 26.459 6.83056 27.5714 6.83056H33.0383ZM30.2609 32.8763C30.8317 27.557 31.831 14.9196 31.831 14.7921C31.8675 14.4059 31.7416 14.0402 31.4918 13.7459C31.2237 13.4702 30.8846 13.3071 30.5107 13.3071H4.50348C4.12783 13.3071 3.77042 13.4702 3.52242 13.7459C3.27077 14.0402 3.14677 14.4059 3.165 14.7921C3.16835 14.8155 3.20421 15.2607 3.26416 16.0049C3.53047 19.311 4.27219 28.5191 4.75148 32.8763C5.09066 36.0863 7.19684 38.1038 10.2476 38.1769C12.6018 38.2313 15.0271 38.25 17.5071 38.25C19.8431 38.25 22.2155 38.2313 24.6426 38.1769C27.7991 38.1225 29.9035 36.1406 30.2609 32.8763Z"
                  fill="#130F26"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 35 39"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M33.0383 6.83056C33.7678 6.83056 34.375 7.43618 34.375 8.2068V8.91929C34.375 9.67116 33.7678 10.2955 33.0383 10.2955H1.96348C1.23224 10.2955 0.625 9.67116 0.625 8.91929V8.2068C0.625 7.43618 1.23224 6.83056 1.96348 6.83056H7.43044C8.54097 6.83056 9.50744 6.0412 9.75727 4.92746L10.0436 3.64872C10.4885 1.90686 11.9528 0.75 13.6286 0.75H21.3714C23.029 0.75 24.5097 1.90686 24.9382 3.55685L25.2446 4.92558C25.4926 6.0412 26.459 6.83056 27.5714 6.83056H33.0383ZM30.2609 32.8763C30.8317 27.557 31.831 14.9196 31.831 14.7921C31.8675 14.4059 31.7416 14.0402 31.4918 13.7459C31.2237 13.4702 30.8846 13.3071 30.5107 13.3071H4.50348C4.12783 13.3071 3.77042 13.4702 3.52242 13.7459C3.27077 14.0402 3.14677 14.4059 3.165 14.7921C3.16835 14.8155 3.20421 15.2607 3.26416 16.0049C3.53047 19.311 4.27219 28.5191 4.75148 32.8763C5.09066 36.0863 7.19684 38.1038 10.2476 38.1769C12.6018 38.2313 15.0271 38.25 17.5071 38.25C19.8431 38.25 22.2155 38.2313 24.6426 38.1769C27.7991 38.1225 29.9035 36.1406 30.2609 32.8763Z"
                  fill="#130F26"
                />
              </svg>
            ))}
        </div>
      </div>
    );
  }

  function ProductMainAction() {
    return (
      <div className="product-main-action">
        <div className="main-action-container">
          <div className="seller-page-comments-wrapper">
            {seller_page_item_toggle_state === "onSale" && (
              <button
                className="add-comment-button"
                onClick={(event) => {
                  setIsCommentModalOpen(true);
                  if (event.target.className.includes("add-comment-button")) {
                    setTimeout(() => {
                      const inputField =
                        document.querySelector(".comment-input");
                      if (inputField) inputField.focus();
                    }, 100);
                  }
                }}
              >
                <img src={commentIcon} className="comment-icon" alt="comment" />
                Add a comment...
              </button>
            )}
            <button
              className={`seller-page-view-all-comments-button ${
                seller_page_item_toggle_state === "itemsSold" &&
                "view-all-comments-button-sold-items"
              }`}
              onClick={() => setIsCommentModalOpen(true)}
            >
              View all comments
            </button>
          </div>
          {product.status === "active" ? (
            <div className="product-mark-sold" onClick={handleItemsSold}>
              Mark as sold
            </div>
          ) : product.status === "draft" ? (
            <button className="product-publish" onClick={handleItemsPublish}>
              {isButtonLoading ? (
                <img src={LoadingWhite} alt="Loading" />
              ) : (
                <span>Publish</span>
              )}
            </button>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Modal
        isOpen={isEditOpen}
        contentLabel="Popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}
      >
        <FillProduct
          data={formdata}
          category={formdata.categoryType}
          handleBackPress={handleEditBack}
          isEdit={isEdit}
          onClose={handleCloseEditModal}
          updateFormData={handleFormUpdate}
        />
      </Modal>
      <Modal
        isOpen={isCommentModalOpen}
        onRequestClose={() => setIsCommentModalOpen(false)}
        contentLabel="Add Comment"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
          content: {
            top: "50%",
            left: "auto",
            right: "10%",
            bottom: "auto",
            transform: "translate(0%, -50%)",
            width: "70%",
            maxWidth: "650px",
            height: "75%",
            border: "none",
            background: "#F8F8F8",
            padding: "0px",
            borderRadius: "8px",
            boxShadow: "-5px -5px 10px rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <div className="comments-header">
          <hr className="comment-top-bar" />
          Comments
        </div>

        <div className="comments-section">
          {isLoadingComments ? (
            <div style={{ width: "100%" }}>
              <div style={{ paddingTop: "10%", paddingBottom: "10%" }}>
                <LoadingSpinner />
              </div>
            </div>
          ) : (
            commentsData.map((comment, index) => {
              const commentDate = formatDate(comment.created_at, "comment");
              const showDateDivider = commentDate !== lastDate;
              lastDate = commentDate;
              const repliesForComment = replies[comment.id];

              return (
                <React.Fragment key={comment.id}>
                  {showDateDivider && (
                    <div className="date-divider">
                      <h1 className="comment-date">{commentDate}</h1>
                    </div>
                  )}
                  <div className="comment-container">
                    <img
                      src={
                        comment.merchant.seller_profile_image_url
                          ? comment.merchant.seller_profile_image_url
                          : comment.merchant.buyer_profile_image_url
                          ? comment.merchant.buyer_profile_image_url
                          : commentsDefaultProfile
                      }
                      className="comment-profile-icon"
                      alt="comment"
                    />
                    <div className="comment-message-container">
                      <div className="commenter-name-time">
                        <h1 className="commenter-name">
                          {comment.merchant.name}
                        </h1>
                        <h6 className="comment-time">
                          {new Date(comment.created_at).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </h6>
                      </div>
                      <p className="comment">{comment.message}</p>
                      {loadingReplies === comment.id ? (
                        <button className="reply-loading">Loading...</button>
                      ) : (
                        <button
                          onClick={() =>
                            repliesForComment || comment.total_replies === 0
                              ? handleReplyButtonClick(
                                  comment.id,
                                  comment.merchant.name
                                )
                              : handleViewRepliesClick(comment.id)
                          }
                          className="view-replies-button"
                          ref={replyButtonRef}
                        >
                          {repliesForComment || comment.total_replies === 0
                            ? "Reply"
                            : comment.total_replies === 1
                            ? "View Reply"
                            : `View ${comment.total_replies} Replies`}
                        </button>
                      )}

                      {replies[comment.id] && (
                        <div className="replies">
                          {replies[comment.id].map((reply) => (
                            <div key={reply.id} className="reply-wrapper">
                              <img
                                src={
                                  reply?.merchant?.seller_profile_image_url
                                    ? reply?.merchant?.seller_profile_image_url
                                    : reply?.merchant?.buyer_profile_image_url
                                    ? reply?.merchant?.buyer_profile_image_url
                                    : commentsDefaultProfile
                                }
                                alt="User Profile"
                                className="reply-profile-pic"
                              />
                              <div className="comments-reply-container">
                                <div className="reply-name-time">
                                  <div className="reply-username">
                                    {reply?.merchant.name}
                                  </div>
                                  <div className="reply-time">
                                    {formatDate(reply?.created_at, "reply")}
                                  </div>
                                </div>
                                <div className="comment-reply">
                                  {reply?.message}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {replies[comment.id] &&
                        replies[comment.id].length > 0 && (
                          <button
                            className="view-replies-button"
                            onClick={() => handleHideReplies(comment.id)}
                          >
                            Hide
                          </button>
                        )}
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          )}
          {isLoadingMoreComments && (
            <div className="loading-wrapper">
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            </div>
          )}
          {currentPage < totalPages &&
            !isLoadingMoreComments &&
            commentsData !== null && (
              <button
                onClick={loadMoreComments}
                disabled={isLoadingMoreComments}
                className="load-more-comments-button"
              >
                Load More Comments
              </button>
            )}
        </div>

        {replyingToCommentId && (
          <div className="replieng-highlighter">
            You are replying to {replyingToMerchantName}
          </div>
        )}
        <div
          className="comment-input-container"
          style={{
            borderTop: !replyingToCommentId && "2px solid #B3B1B0",
          }}
        >
          <input
            className="comment-input"
            placeholder="Add a comment..."
            value={comment}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            disabled={postingComment}
          />
          <button
            className="comment-submit-button"
            onClick={() => {
              if (replyingToCommentId) {
                postReplyHandler();
              } else {
                postCommentHandler();
              }
            }}
            disabled={postingComment || comment === ""}
          >
            <img
              className="comments-post-icon"
              src={postComment}
              alt="post comment"
            />
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        contentLabel="Popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}
      >
        <DeleteHandler id={product.id} backHandler={deleteBackHandler} />
      </Modal>

      <div>
        {isLoading ? (
          <div style={{ width: "100%" }}>
            <div style={{ paddingTop: "10%", paddingBottom: "10%" }}>
              {" "}
              <LoadingSpinner />{" "}
            </div>
          </div>
        ) : (
          <ProductDetailedView
            SideActionContainer={ActionContainer}
            MainActionContainer={ProductMainAction}
            item={product}
            backHandler={backHandler}
          />
        )}
      </div>
    </div>
  );
}
export default SellerProductView;
