import { BASE_URL, GET_DETAILS } from '../config';
import { handleResponse } from './responseHandler';

export async function getMerchantDetails(sessionId) {
  const response = await fetch(BASE_URL + GET_DETAILS, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": sessionId
    }
  });

  return handleResponse(response);
}