import "../styling/ItemsCard.css";
import Item from "./item/Item";

const FavoriteItemsCard = ({ data, handleItemClick }) => {

  return (
    <div>
      <div className="cardsContainer">
        <div className="cards">
          {data.map((item) => (
            <Item item={item.inventory_item} handleItemClick={handleItemClick} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default FavoriteItemsCard;
