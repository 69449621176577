import React, { useState, useEffect, useRef } from "react";
import "../styling/BuyerRequests.css";

const BuyerRequests = ({ handleBuyerItemClick, request }) => {
  const descriptionRef = useRef(null);
  const [timeDiff, setTimeDiff] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const toggleDescription = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  useEffect(() => {
    const currentTime = new Date();
    const createdAt = new Date(request.created_at);
    const timeDifference = currentTime - createdAt;
  
    const minutes = Math.floor(timeDifference / 60000);
    const hours = Math.floor(timeDifference / 3600000);
    const days = Math.floor(timeDifference / 86400000);
    const months = Math.floor(timeDifference / (86400000 * 30)); 
    const years = Math.floor(timeDifference / (86400000 * 365)); 
  
    if (years > 0) {
      setTimeDiff(`${years} year${years > 1 ? "s" : ""} ago`);
    } else if (months > 0) {
      setTimeDiff(`${months} month${months > 1 ? "s" : ""} ago`);
    } else if (days > 0) {
      setTimeDiff(`${days} day${days > 1 ? "s" : ""} ago`);
    } else if (hours > 0) {
      setTimeDiff(`${hours} hour${hours > 1 ? "s" : ""} ago`);
    } else {
      setTimeDiff(`${minutes} minute${minutes > 1 ? "s" : ""} ago`);
    }
  
    const isTextOverflowing =
      descriptionRef.current.scrollWidth > descriptionRef.current.clientWidth;
    setIsOverflowing(isTextOverflowing);
  }, [request.created_at]);  

  return (
    <div
      key={request.id}
      className="buyer-req-item-wrapper"
      onClick={() => handleBuyerItemClick(request)}
    >
      <div className="buyer-req-item-container">
        <h6 className="buyer-req-time">Posted {timeDiff}</h6>
        <h2 className="buyer-req-item-name">{request.catogery_type}</h2>
        <div
          ref={descriptionRef}
          className={`buyer-req-description ${
            expanded ? "expanded" : "collapsed"
          }`}
        >
          {request.product_description}
        </div>
        {isOverflowing && (
          <button className="view-more-button" onClick={toggleDescription}>
            {expanded ? "less" : "more"}
          </button>
        )}
        {request.location !== null && (
          <h4 className="buyer-req-location">{request.location}</h4>
        )}
      </div>
    </div>
  );
};

export default BuyerRequests;
