import "./ImageView.css"
import DeleteIcon from '../../images/logo/delete.png';

export function ImageView({url, removeHandler}) {
    return <div className="image-view-container">
        <div className="image-remove-container">
            <img src={DeleteIcon} alt="remove" onClick={removeHandler} className="remove-icon"/>
        </div>
        <div className="image-file-container">
            <img src={url} alt='' className="image-view-image" />
        </div>
    </div>
}