import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import "../../styling/LoggedInDefaultLayout.css";

export const LoggedInDefaultLayout = ({ children }) => {
  return (
    <div className = 'logged-in-container'>
        <div className = "content-wrapper">
          <Header />
          <main className = "children-content">{children}</main>
        </div>
        <Footer />
    </div>
  );
};

