// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go-backSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 234px;
    margin-left: 6%;
    margin-top: 20px;
}

.arrow-container{
height: 50px;
width: 45px;
border-radius: 30%;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
display: flex;
justify-content: center;
align-items: center;
}

.arrow-img{
    height: 30px;
    width: 30px;
}

.goBackHeading{
    font-family: 'PoppinsBold';
    font-size: 29px;
}

.pagination {
  display: flex;
  font-family: 'Poppins';
  color: var(--greyscale-black-30, #B3B1B0);
  font-size: 24px;
  margin-top: -5px;
  margin-left: -7%;
}

.pagination > span,
.pagination > span.active {
  margin: 0 5px;
  display: inline;
  font-size: 24px;
  color: #B3B1B0;
  cursor: pointer;
  transition: color 0.2s;
}

.pagination > span.active {
  color: #FD9340;
}

.draft-item-list-container {
  display: flex;
  flex-wrap: wrap;
}

.draft-item-result-container {
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 2%
}
`, "",{"version":3,"sources":["webpack://./src/styling/Draft.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;AACA,YAAY;AACZ,WAAW;AACX,kBAAkB;AAClB,0CAA0C;AAC1C,aAAa;AACb,uBAAuB;AACvB,mBAAmB;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yCAAyC;EACzC,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,eAAe;EACf,eAAe;EACf,cAAc;EACd,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf;AACF","sourcesContent":[".go-backSection{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 234px;\n    margin-left: 6%;\n    margin-top: 20px;\n}\n\n.arrow-container{\nheight: 50px;\nwidth: 45px;\nborder-radius: 30%;\nbox-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\ndisplay: flex;\njustify-content: center;\nalign-items: center;\n}\n\n.arrow-img{\n    height: 30px;\n    width: 30px;\n}\n\n.goBackHeading{\n    font-family: 'PoppinsBold';\n    font-size: 29px;\n}\n\n.pagination {\n  display: flex;\n  font-family: 'Poppins';\n  color: var(--greyscale-black-30, #B3B1B0);\n  font-size: 24px;\n  margin-top: -5px;\n  margin-left: -7%;\n}\n\n.pagination > span,\n.pagination > span.active {\n  margin: 0 5px;\n  display: inline;\n  font-size: 24px;\n  color: #B3B1B0;\n  cursor: pointer;\n  transition: color 0.2s;\n}\n\n.pagination > span.active {\n  color: #FD9340;\n}\n\n.draft-item-list-container {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.draft-item-result-container {\n  display: flex;\n  justify-content: center;\n  padding-top: 3%;\n  padding-bottom: 2%\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
