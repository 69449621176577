import React from 'react';
import Footer from '../Footer';
import "../../styling/LoggedInDefaultLayout.css";

export const FooterDefaultLayout = ({ children }) => {
  return (
    <div className = 'logged-in-container'>
        <div className = "content-wrapper">
          <main className = "children-content">{children}</main>
        </div>
        <Footer />
    </div>
  );
};

