import React, { useState, useEffect } from "react";
import { getAllCatogeries } from "../../settings/SettingsHandler";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../contexts/SessionContext";
import Dropdown from "../../images/Dropdown.svg";
import "../../styling/NavBar.css";

export const BuyerNavBar = () => {
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  const { screenSize } = useSession();

  useEffect(() => {
    (async () => {
      setCategory(await getAllCatogeries());
    })();
  }, []);

  const handlePopupHover = () => {
    setIsCategoryDropdownOpen(true);
  };

  const handlePopupLeave = () => {
    setIsCategoryDropdownOpen(false);
  };

  const handleCatogerySelect = (category) => {
    setIsCategoryDropdownOpen(false);
    navigate("/categories/" + category);
  };

  return (
    <div className="navbar" style={{ position: "relative" }}>
      <div
        className={`${
          screenSize === "small" ? "mobilenavbaritems" : "navbarItems"
        }`}
      >
        <div className="category-dropdown">
          <div
            className={`${
              screenSize === "small"
                ? "mobile-category-buttons-container"
                : "category-buttons-container"
            }`}
          >
            <button
              className={`category ${
                isCategoryDropdownOpen ? "button-hovered" : ""
              }`}
              onMouseEnter={handlePopupHover}
              onMouseLeave={handlePopupLeave}
              onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
            >
              Find Material
              <img src={ isCategoryDropdownOpen ? Dropdown : Dropdown} alt="dropdown"  className={` ${isCategoryDropdownOpen ? 'find-material-open-dropdown-icon' : 'find-material-dropdown-icon'}`}  />
            </button>

            {isCategoryDropdownOpen && (
              <div
                className="category-list"
                onMouseEnter={handlePopupHover}
                onMouseLeave={handlePopupLeave}
              >
                <div className="category-dropdown-buttons">
                  {category.map((categoryItem, index) => (
                    <button
                      onClick={() => handleCatogerySelect(categoryItem)}
                      key={index}
                    >
                      {" "}
                      {categoryItem}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};