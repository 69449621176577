import React, { useState, useRef } from "react";
import Scrap from "../../images/Scrap.png";
import Logo from "../../images/Logo.png";
import { displayToast } from "../../utils/toastHelper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../api/login";
import LoadingWhite from "../../images/LoadingWhite.gif";
import { handleResponseError } from "../../utils/handleError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../../styling/ResetPassword.css";

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmedPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const confirmPasswordRef = useRef(null);
  const resetPasswordButtonRef = useRef(null);
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);

  let PasswordError;
  const passwordsMatch = newPassword === confirmPassword;
  const isButtonActive =
    newPassword.trim() !== "" &&
    confirmPassword.trim() !== "" &&
    passwordsMatch;

  if (!passwordsMatch) {
    PasswordError = "Password and confirm password does not match.";
  }

  const resetPasswordHandler = async () => {
    setIsLoading(true);
    const data = await resetPassword(newPassword);
    setIsLoading(false);
    if (data.success) {
      navigate("/login", { state: { passwordResetSuccess: true } });
      displayToast("Password Update Successful!", "success");
    }
    if (data.error) return handleResponseError(data.error);
  };

  const handleNewPasswordKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      confirmPasswordRef.current.focus();
    }
  };

  const handleConfirmPasswordKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      resetPasswordButtonRef.current.click();
    }
  };

  const confirmPasswordPlaceholder =
    window.innerWidth < 768 ? "Password" : "New Password";

  return (
    <div className="reset-container">
      <div className="reset-content">
        <div className="imagerp-container">
          <img
            src={Scrap}
            alt="Decorative scrap art"
            className="resetimg-image"
          />
          <img src={Logo} alt="Company logo" className="logorp-image" />
        </div>

        <div className="form-container-resetPasswordpage">
          <div className="rpForm">
            <h2 className="resetAccount">Reset Password</h2>
            <form className="forminputs">
              <div
                className="password-input-container"
                style={{ marginTop: "16%", marginBottom: "14px" }}
              >
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder={confirmPasswordPlaceholder}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onKeyPress={handleNewPasswordKeyPress}
                />
                <FontAwesomeIcon
                  icon={!passwordVisible ? faEyeSlash : faEye}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  className="resetpassword-eye-icon"
                />
              </div>

              <div
                className="password-input-container"
                id="reset-password-confirm-password-container"
              >
                <input
                  type={confirmpasswordVisible ? "text" : "password"}
                  ref={confirmPasswordRef}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmedPassword(e.target.value)}
                  onKeyPress={handleConfirmPasswordKeyPress}
                />
                <FontAwesomeIcon
                  icon={!confirmpasswordVisible ? faEyeSlash : faEye}
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmpasswordVisible)
                  }
                  className="resetpassword-eye-icon"
                />
              </div>
              {<div className="password-error-message">{PasswordError}</div>}

              <button
                type="button"
                className={`resetButton ${isButtonActive ? "active" : ""}`}
                disabled={!isButtonActive}
                onClick={resetPasswordHandler}
                ref={resetPasswordButtonRef}
                style={{ position: "relative", overflow: "hidden" }}
              >
                {isLoading ? (
                  <>
                    <img
                      src={LoadingWhite}
                      alt="Loading"
                      className="getstarted-loading-inlogin"
                    />
                  </>
                ) : (
                  "Reset Password"
                )}
              </button>
              <p className="haveanaccount-text">
                Have an account?{" "}
                <Link to="/login" className="haveanaccount-link">
                  <span
                    style={{
                      color: "#fd9340",
                      fontWeight: 700,
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    Login
                  </span>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
