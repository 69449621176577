import React from 'react';
import { LoggedInDefaultLayout } from './LoggedInDefaultLayout';
import { BuyerNavBar } from '../buyer/BuyerNavBar';

export const BuyerLoggedInLayout = ({ children }) => {
  return (
    <LoggedInDefaultLayout>
        <BuyerNavBar />
        {children}
    </LoggedInDefaultLayout>
  );
};

