// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go-backContainer{
    display: flex;
    align-items: center;
    margin-left: 6%;
    margin-top: 20px;
}

.arrow-container{
}

.arrow-img{
    height: 30px;
    width: 30px;
}

.favorite-header{
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px; /* 200% */
    margin-left: 20px;
}

.drafts{
display: flex;
justify-content: center;
width: 100%;
margin-bottom: 15px;
flex-direction: column;
align-items: center;
}

.draft-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
    .favorite-header{
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 147%; /* 29.4px */
        margin-left: 22px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styling/favorite.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;AACA;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;IAC5B,iBAAiB;AACrB;;AAEA;AACA,aAAa;AACb,uBAAuB;AACvB,WAAW;AACX,mBAAmB;AACnB,sBAAsB;AACtB,mBAAmB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;IACI;QACI,WAAW;QACX,kBAAkB;QAClB,oBAAoB;QACpB,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,iBAAiB,EAAE,WAAW;QAC9B,iBAAiB;IACrB;AACJ","sourcesContent":[".go-backContainer{\n    display: flex;\n    align-items: center;\n    margin-left: 6%;\n    margin-top: 20px;\n}\n\n.arrow-container{\n}\n\n.arrow-img{\n    height: 30px;\n    width: 30px;\n}\n\n.favorite-header{\n    color: #000;\n    font-family: Poppins;\n    font-size: 32px;\n    font-style: normal;\n    font-weight: 800;\n    line-height: 64px; /* 200% */\n    margin-left: 20px;\n}\n\n.drafts{\ndisplay: flex;\njustify-content: center;\nwidth: 100%;\nmargin-bottom: 15px;\nflex-direction: column;\nalign-items: center;\n}\n\n.draft-container{\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media (max-width: 768px) {\n    .favorite-header{\n        color: #000;\n        text-align: center;\n        font-family: Poppins;\n        font-size: 20px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 147%; /* 29.4px */\n        margin-left: 22px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
