import React, { createContext, useContext, useState, useEffect } from "react";
import { MODE_BUYER } from "../ConstantNew";
import { getSessionId, getSessionMerchantDetails } from "../session/SessionHandler";

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [mode, setUserMode] = useState(localStorage.getItem("userMode") || MODE_BUYER);
  const [merchantLogo, setMerchantLogo] = useState('');
  const [screenSize, setScreenSize] = useState(window.innerWidth <= 768 ? 'small' : (window.innerWidth <= 1024 ?'medium' : 'large'));
  const [merchantStatus, setMerchantStatus] = useState('');
  const [billingStatus, setBillingStatus] = useState('');
  const [notificationCount, setNotificationCount] = useState(0);
  const [seller_page_item_toggle_state, setSeller_page_item_toggle_state] =
  useState("buyerRequest");

  useEffect(() => {
    const storedSessionId = getSessionId();
    updateSessionId(storedSessionId);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 768) {
        setScreenSize('small');
      } else if (screenWidth <= 1024) {
        setScreenSize('medium');
      } else {
        setScreenSize('large');
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial screen size detection
    handleResize();

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateSessionId = (newSessionId) => {
    setSessionId(newSessionId);

    const merchantDetails = getSessionMerchantDetails();
    setMerchantId(merchantDetails ? merchantDetails.merchant_id : '');
    setMerchantLogo(merchantDetails ? merchantDetails.merchant_logo : '');
    setMerchantStatus(merchantDetails ? merchantDetails.merchant_status : '');
    setBillingStatus(merchantDetails ? merchantDetails.billing_status : '');
    setNotificationCount(merchantDetails ? merchantDetails.unseen_notifications_count : 0);
  };

  const setMode = (mode) => {
    localStorage.setItem("userMode", mode);
    setUserMode(mode);
  }

  return (
    <SessionContext.Provider value={{ 
        sessionId, updateSessionId, 
        mode, setMode, merchantLogo, 
        setMerchantLogo, merchantStatus,setMerchantStatus,
        billingStatus,setBillingStatus,
        screenSize, 
        merchantId,setMerchantId,
        notificationCount, setNotificationCount ,
        seller_page_item_toggle_state, setSeller_page_item_toggle_state
      }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};
