import React, { useState, useRef, useEffect } from 'react';
import '../styling/DropDownOptionSet.css';
import DropDownIcon from '../images/arrow_down.svg';

const DropDownOptionSet = ({ value = null, parameter, handleUpdate, isOptional = false, error = '', clearError }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const [hovered, setHovered] = useState(null);
  let data = [];
  parameter.options.forEach((option) => {
    data.push({
      label: option,
      value: option,
    });
  });
  const handleOptionSelect = (option) => {
    if (selected === option) {
      setSelected(null);
    } else {
      setSelected(option);
      setDropdownOpen(!dropdownOpen);
      if (clearError) {
        clearError();
      }
    }
    handleUpdate(parameter.name, option);
  };
  
  const handleInputChange = (event) => {
    setSelected(event.target.value);
  };

  const handleImageClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  return (
    <div className="dropdown-header" ref={dropdownRef}  >
      <text className="parameterparams-text-m1" >{parameter.name}{!isOptional ? '*' : ''}</text>
      <div className='main-dropdown-container'>
        <div className='dropdown-input-container'>
          <input
            type="text"
            placeholder={selected}
            onClick={handleImageClick}
            className="containerdropdownparam-style"
            value={selected}
            onChange={handleInputChange}
            readOnly
          />
          <img src={DropDownIcon} alt="Dropdown"
            onClick={handleImageClick} className='dropdown-icon' />
        </div>
        {dropdownOpen && (
          <div className="dropdownparam-options"  >
            {parameter.options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionSelect(option)}
                onMouseEnter={() => setHovered(option)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    backgroundColor: hovered === option ? '#FFF5EC' : ''
                  }}
                >
                  <div className='dropdown-option-parameter'>
                    <div
                      className="squaredrop"
                      style={{ backgroundColor: hovered === option ? '#FFA500' : 'white' }}
                    />
                    <div className="dropdown-textnew-style"> {option} </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {error && <div className="descrption-error-message">{error}</div>}
      </div>
    </div>
  );
};

export default DropDownOptionSet;
