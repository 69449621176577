import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import AddProduct from "./AddProduct";
import Item from "../components/item/Item";
import BuyerRequests from "../components/BuyerRequests";
import LoadingSpinner from "../components/LoadingSpinner";
import { getAllCatogeries } from "../settings/SettingsHandler";
import { handleResponseError } from "../utils/handleError";
import { getItemsForSeller } from "../api/sellerApis";
import "../../src/styling/sellerPage.css";
import { getBuyerRequestItems } from "../api/sellerApis";
import { useSession } from "../contexts/SessionContext";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SellerPage() {
  const navigate = useNavigate();
  const productsPerPage = 12;

  const [pageNumber] = useState(1);
  const [category, setCategory] = useState([]);
  const [soldItems, setSoldItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSliding, setIsSliding] = useState(false);
  const [onSaleItems, setOnSaleItems] = useState([]);
  const [buyerRequests, setBuyerRequests] = useState([]);
  const [onSalePageCount, setOnSalePageCount] = useState(0);
  const [onSoldPageCount, setOnSoldPageCount] = useState(0);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [buyerRequestPageCount, setBuyerRequestPageCount] = useState(0);
  const [isBuyerRequestLoading, setIsBuyerRequestLoading] = useState(false);
  const { seller_page_item_toggle_state, setSeller_page_item_toggle_state } =
    useSession();
  async function fetchOnSaleProductDetails() {
    setIsLoading(true);
    try {
      setOnSaleItems([]);
      const url = `status=active&count=${productsPerPage}&page=${currentPage}`;
      const data = await getItemsForSeller(url);
      if (data.error) {
        handleResponseError(data.error);
      } else {
        setOnSaleItems(data.items);
        setOnSalePageCount(Math.ceil(data.total / productsPerPage));
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchBuyerRequestDetails(url) {
    setIsBuyerRequestLoading(true);
    try {
      setBuyerRequests([]);
      const data = await getBuyerRequestItems(url);
      if (data.error) {
        handleResponseError(data.error);
      } else {
        setBuyerRequests(data.data);
        setBuyerRequestPageCount(Math.ceil(data.total / productsPerPage));
      }
    } catch (error) {
      console.error("Error fetching buyer requests:", error);
    } finally {
      setIsBuyerRequestLoading(false);
    }
  }

  async function fetchSoldProductDetails() {
    setIsLoading(true);
    try {
      setSoldItems([]);
      const url = `status=sold&count=${productsPerPage}&page=${currentPage}`;
      const data = await getItemsForSeller(url);
      if (data.error) return handleResponseError(data.error);
      setSoldItems(data.items);
      setOnSoldPageCount(Math.ceil(data.total / productsPerPage));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product details:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (seller_page_item_toggle_state === "onSale") {
      fetchOnSaleProductDetails();
    } else if (seller_page_item_toggle_state === "itemsSold") {
      fetchSoldProductDetails();
    } else {
      fetchBuyerRequestDetails(
        `request_items?&page=${currentPage}&count=${productsPerPage}`
      );
    }
  }, [currentPage, seller_page_item_toggle_state]);

  const handlePageClick = (data) => {
    setCurrentPage(parseInt(data.selected) + 1);
  };

  const itemsSoldButtonClass =
    "item-status-tab-button-items item-status-tab-button " +
    (seller_page_item_toggle_state === "itemsSold"
      ? "item-status-tab-button-selected"
      : "");

  const onSaleButtonClass =
    "item-status-tab-button-items item-status-tab-button " +
    (seller_page_item_toggle_state === "onSale"
      ? "item-status-tab-button-selected"
      : "");

  const buyerRequestClass =
    "item-status-tab-button-items item-status-tab-button " +
    (seller_page_item_toggle_state === "buyerRequest"
      ? "item-status-tab-button-selected"
      : "");

  const handleAddProductClick = () => {
    setIsAddingProduct(true);
  };

  const handleDraftsClick = () => {
    navigate("/draft");
  };

  const handleItemClick = (item) => {
    navigate("/seller/items/" + item.id);
  };

  const handleBuyerItemClick = (request) => {
    navigate("/buyer-request/" + request.id);
  };

  function handleAddProductClose() {
    setIsAddingProduct(false);
  }

  const handleAddNewProduct = (newProduct) => {
    setOnSaleItems([newProduct, ...onSaleItems]);
  };

  const handleOnSaleClick = async () => {
    setSeller_page_item_toggle_state("onSale");
    setCurrentPage(pageNumber);
    setSelectedCategory(null);
    await fetchOnSaleProductDetails("active", 1);
  };

  const handleItemsSoldClick = async () => {
    setSeller_page_item_toggle_state("itemsSold");
    setCurrentPage(pageNumber);
    setSelectedCategory(null);
    await fetchSoldProductDetails("sold", 1);
  };

  const handleBuyerRequestClick = async () => {
    setSeller_page_item_toggle_state("buyerRequest");
    setCurrentPage(pageNumber);
    setSelectedCategory(null);
    await fetchBuyerRequestDetails(
      `request_items?&page=${currentPage}&count=${productsPerPage}`
    );
  };

  useEffect(() => {
    (async () => {
      setCategory(await getAllCatogeries());
    })();
  }, []);

  const handleCatogerySelect = (category) => {
    setSelectedCategory(category);
    let url = `request_items?&page=${currentPage}&count=${productsPerPage}`;
    if (category !== null) {
      url += `&catogery_type=${category}`;
    }
    fetchBuyerRequestDetails(url);
  };

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    touchMove: true,
    touchThreshold: 100,
    speed: 300,
    variableWidth: true,
    beforeChange: () => {
      setIsSliding(true);
    },
    afterChange: () => {
      setIsSliding(false);
    },
  };

  return (
    <div>
      <div>
        <div className="product-publish-container">
          <div className="product-publish-header">Add a product to sell</div>
          <div className="product-publish-action-container">
            <button
              className="add-product-button"
              onClick={handleAddProductClick}
            >
              Add Products
            </button>
            <button className="draft-button" onClick={handleDraftsClick}>
              Drafts
            </button>
          </div>
        </div>
        <div className="item-status-tab-container">
          <button
            className={buyerRequestClass}
            onClick={handleBuyerRequestClick}
          >
            Order Enquiry
          </button>
          <button className={onSaleButtonClass} onClick={handleOnSaleClick}>
            On sale
          </button>
          <button
            className={itemsSoldButtonClass}
            onClick={handleItemsSoldClick}
          >
            Items sold
          </button>
        </div>

        {seller_page_item_toggle_state === "buyerRequest" && (
          <div className="category-wrapper">
            <div className="category-item-buttons">
              <Slider {...settings}>
                <div key="all">
                  <button
                    onClick={() => handleCatogerySelect(null)}
                    className={`category-item-button ${
                      selectedCategory === null
                        ? "category-item-button-active"
                        : ""
                    }`}
                    disabled={isSliding}
                  >
                    All
                  </button>
                </div>
                {category.map((categoryItem, index) => (
                  <div key={index}>
                    <button
                      onClick={() => handleCatogerySelect(categoryItem)}
                      className={`category-item-button ${
                        selectedCategory === categoryItem
                          ? "category-item-button-active"
                          : ""
                      }`}
                      disabled={isSliding}
                    >
                      {categoryItem}
                    </button>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}

        {isLoading &&
        (seller_page_item_toggle_state === "onSale" ||
          seller_page_item_toggle_state === "itemsSold") ? (
          <div style={{ width: "100%" }}>
            <div style={{ paddingTop: "5%", paddingBottom: "10%" }}>
              <LoadingSpinner />
            </div>
          </div>
        ) : (
          <div className="item-list-container">
            {seller_page_item_toggle_state === "onSale" &&
              onSaleItems &&
              onSaleItems.length > 0 &&
              onSaleItems.map((item) => (
                <Item
                  item={item}
                  handleItemClick={handleItemClick}
                  key={item.id}
                />
              ))}

            {seller_page_item_toggle_state === "itemsSold" &&
              soldItems &&
              soldItems.length > 0 &&
              soldItems.map((item) => (
                <Item
                  item={item}
                  handleItemClick={handleItemClick}
                  key={item.id}
                />
              ))}

            {seller_page_item_toggle_state === "buyerRequest" &&
            isBuyerRequestLoading ? (
              <div style={{ width: "100%" }}>
                <div style={{ paddingTop: "5%", paddingBottom: "10%" }}>
                  <LoadingSpinner />
                </div>
              </div>
            ) : seller_page_item_toggle_state === "buyerRequest" &&
              buyerRequests &&
              buyerRequests.length > 0 ? (
              <div className="category-wrapper">
                <div className="category-item-contents">
                  {buyerRequests
                    .filter((request) => request.status !== "closed")
                    .map((request) => (
                      <BuyerRequests
                        request={request}
                        handleBuyerItemClick={handleBuyerItemClick}
                        key={request.id}
                      />
                    ))}
                </div>
              </div>
            ) : seller_page_item_toggle_state === "buyerRequest" &&
              buyerRequests &&
              buyerRequests.length === 0 ? (
              <div className="buyer-req-empty-message">
                No buyer requests available
              </div>
            ) : null}
          </div>
        )}

        <div className="filter-pagination-for-seller-page">
          <ReactPaginate
            pageCount={
              seller_page_item_toggle_state === "buyerRequest"
                ? buyerRequestPageCount
                : seller_page_item_toggle_state === "itemsSold"
                ? onSoldPageCount
                : onSalePageCount
            }
            onPageChange={handlePageClick}
            containerClassName={"pagination10"}
            activeClassName={"active"}
            previousClassName={"previous"}
            nextClassName={"next"}
            previousLabel={"<"}
            nextLabel={">"}
            forcePage={currentPage - 1}
            pageRangeDisplayed={2}
          />
        </div>
      </div>
      <Modal
        isOpen={isAddingProduct}
        contentLabel="Popup"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            border: "none",
            background: "none",
            padding: 0,
            margin: 0,
          },
        }}
      >
        <AddProduct
          handleAddProductClose={handleAddProductClose}
          newProduct={handleAddNewProduct}
        />
      </Modal>
    </div>
  );
}

export default SellerPage;
