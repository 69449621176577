import React, { useState } from 'react';
import '../styling/DeleteHandler.css';
import { useContext } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';
import { useNavigate } from "react-router-dom";
import { deleteMerchantItem } from '../api/sellerApis';
import LoadingWhite from "../images/LoadingWhite.gif";
import { handleResponseError } from '../utils/handleError';

function DeleteHandler({ backHandler, id = null, onClose = null }) {
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [ isButtonLoading, setIsButtonLoading ] = useState(false);
  const navigate = useNavigate();
  
  const handleDelete = async () => {
    
    if (id !== null) {

      setIsButtonLoading(true);
      // setIsLoading(true);

      const data = await deleteMerchantItem(id);

      if (data.error) { 
        setIsButtonLoading(false);
        setIsLoading(false);
        return handleResponseError(data.error);
      }

      // setIsLoading(false);
      setIsButtonLoading(false);

      navigate('/');
    }
    
    if (id === null) {
      onClose();
    }
  };

  const handleBackClick = () => {
    backHandler();
  };
 
  const handleModalClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      backHandler();
    }
  };

  return (
    <div className={`modal-overlay ${isButtonLoading ? 'unclickable' : ''}`} id = 'modal-overlay' onClick={handleModalClick} >
      <div className="modalNew-content">
        <p className='para-style'>Are you sure you want to delete?</p>
        <button
          onClick={() => {
            setDeleteInProgress(true);
            handleDelete();
          }}
          className={`buttonfirst-style`}
        >
        {isButtonLoading ?
           (
            <img src={LoadingWhite} alt="Loading" />
                ) : (
            <span>Delete</span>
            )
          }
        </button>
        <button
          onClick={handleBackClick}
          className={`buttonsecond-style `}
        >
          No
        </button>
      </div>
    </div>
  )
}

export default DeleteHandler;
