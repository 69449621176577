// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entry-container {
  /* margin-block: 21px; */
  margin-left: 17px;
  margin-top: 9px;
}

.entry-header {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.entry-value-container {
  display: flex;
  padding-top: 7px;
}

.value-text{
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  flex-shrink: 0;
}

.entry-container-input {
  border-radius: 5px;
  background: #FFF;
  outline:none;
  border:none;
  outline: none;
  width: 64.52px;
  height: 19px;
  flex-shrink: 0;
  margin-left: 15px;
}

.entry-filter-content {
  display: flex;
  align-items: center; 
}
.entry-filter-name {
  flex: 0 0 3%;
  margin-right: 10px; 
}
.entry-filter-input {
  display: flex; 
  align-items: center; 
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: Poppins;
}`, "",{"version":3,"sources":["webpack://./src/styling/EntryFilter.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,cAAc;EACd,YAAY;EACZ,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".entry-container {\n  /* margin-block: 21px; */\n  margin-left: 17px;\n  margin-top: 9px;\n}\n\n.entry-header {\n  color: #000;\n  font-family: Poppins;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 160%;\n}\n\n.entry-value-container {\n  display: flex;\n  padding-top: 7px;\n}\n\n.value-text{\n  color: #000;\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 160%;\n  flex-shrink: 0;\n}\n\n.entry-container-input {\n  border-radius: 5px;\n  background: #FFF;\n  outline:none;\n  border:none;\n  outline: none;\n  width: 64.52px;\n  height: 19px;\n  flex-shrink: 0;\n  margin-left: 15px;\n}\n\n.entry-filter-content {\n  display: flex;\n  align-items: center; \n}\n.entry-filter-name {\n  flex: 0 0 3%;\n  margin-right: 10px; \n}\n.entry-filter-input {\n  display: flex; \n  align-items: center; \n}\n\n.error-message {\n  color: red;\n  font-size: 12px;\n  margin-top: 5px;\n  font-family: Poppins;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
