import React from 'react';
import '../styling/buyerPage.css';
import BuerPageItems from '../components/BuyerPageItems';

function  BuyerPage() {

return (
  <div>
    <div className='buyerContainer'>
  
      <BuerPageItems />
    </div>
  </div>
);
}

export default BuyerPage

